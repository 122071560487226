import React from 'react'
import {connect, useDispatch} from "react-redux";
import Translate from "../../../utilities/etc/Translate";
import Select, {components} from 'react-select';
import PaymentAction from "../../../stores/api/payment/PaymentAction";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
    paymentMethods: state.payment.paymentMethods.methods,
    customerPaymentData: state.payment.paymentCustomerData,
    usePaymentMethod: state.payment.paymentMethods.useMethod,
    paymentProvider: state.payment.paymentMethods.provider,
});


const Option = (props) => {
    return (
        <div>
            <components.Option {...props} />
        </div>
    );
};

const PaymentOptions = (props) => {
    const dispatch = useDispatch()


    const handlePaymentMethodChange = (selectedOption) => {
        dispatch(PaymentAction.setPaymentMethod(selectedOption.code))
    };

    console.log(props.paymentProvider, props.usePaymentMethod)

    return (

        <>
            <div className="c-order-overview__box">
                <header>
                    <h3> {Translate('Betaalmethode', props.language)}</h3>
                </header>
                <div className="c-payment-methods__container">
                    <div className="c-payment-methods__selection">
                        <Select
                            placeholder={Translate('Selecteer betaalmethode', 'en')}
                            options={props.paymentMethods ?? []}
                            components={{Option: Option}}
                            onChange={handlePaymentMethodChange}
                            value={props.paymentMethods.find(m => m.code === props.usePaymentMethod)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    fontSize: '1.6rem',
                                    color: '#000',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '1.6rem',
                                    backgroundColor: state.isSelected ? "white" : "white",
                                    color: '#000',
                                }),
                            }}
                        />
                    </div>
                    {props.paymentProvider === 'pay' && props.usePaymentMethod?.toLowerCase() === 'incasso' &&
                        <div className="c-payment-methods__customer-info">
                            <h5>{Translate('Vul a.u.b. uw gegevens in', props.language)}</h5>
                            <span> {Translate('Naam', props.language)} </span>
                            <label htmlFor="name">
                                <input
                                    type="text"
                                    id="name"
                                    name={'type'}
                                    onChange={(e) => {
                                        dispatch(PaymentAction.setPaymentCustomerData({name: e.target.value}))
                                    }}
                                    value={props.customerPaymentData.name}
                                    required
                                />
                            </label>

                            <span> {Translate('Rekeningnummer', props.language)} </span>
                            <label htmlFor="account_number">
                                <input
                                    type="text"
                                    id="account_number"
                                    name={'type'}
                                    onChange={(e) => {
                                        let target = e.target, position = target.selectionEnd, length = target.value.length;
                                        e.target.value = target.value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                        e.target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);
                                        dispatch(PaymentAction.setPaymentCustomerData({accountNumber: e.target.value}))
                                    }}
                                    required
                                />
                            </label>
                        </div>
                    }

                </div>
            </div>
        </>
    )

}

export default connect(mapStateToProps)(PaymentOptions);
