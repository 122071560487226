import React from 'react'
import AppAction from "../../stores/app/AppAction";
import RouteEnum from "../../constants/RouteEnum";
import Translate from "../../utilities/etc/Translate";

export const LandingsPage = (props) => {

    return (
        <section className="o-section" >
            <div className="o-container o-container--primary">

                <div dangerouslySetInnerHTML={{__html: props.app.webshop.landingspage_content}} style={{fontSize: '1.5rem', marginBottom: '2rem'}} />

                <button
                    style={props.app.webshop.styles.dark} className="btn btn--rounded" onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Period))}>
                    <span>{Translate('Start', props.app.language)}</span>
                </button>
            </div>
        </section>

    )

}