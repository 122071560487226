import AddressAction from './AddressAction'

export default class AddressReducer {

    static initialState = {
        addressErrors: {
            list: false
        },
        addressMessages: {
            list: false
        },
        addressPending: {
            list: false
        },
        addressData: {
            list: false
        }
    };

    static reducer(state = AddressReducer.initialState, action) {
        switch (action.type) {

            case AddressAction.REQUEST_lIST:

                return {
                    ...state,
                    addressErrors: {
                        ...state.addressErrors,
                        list: false
                    },
                    addressMessages: {
                        ...state.addressMessages,
                        list: false
                    },
                    addressPending: {
                        ...state.addressPending,
                        list: true
                    },
                    addressData: {
                        ...state.addressData,
                        list: false
                    },
                };

            case AddressAction.REQUEST_lIST + '_FINISHED':

                return {
                    ...state,
                    addressErrors: {
                        ...state.addressErrors,
                        list: action.payload.error
                    },
                    addressMessages: {
                        ...state.addressMessages,
                        list: action.payload.message
                    },
                    addressPending: {
                        ...state.addressPending,
                        list: false
                    },
                    addressData: {
                        ...state.addressData,
                        list: action.payload.response
                    },
                };

            default:
                return state;
        }
    }
}

