import moment from 'moment'
import CalculateDaysBetween from "../../utilities/etc/CalculateDaysBetween";


const CheckDeliverableStatus = (props) => {

    const {shoppingCart, availability, period, webshop} = props
    const deliverable = availability.deliveryByAddressValidation.valid

    const daysBetweenNowAndStartDate = moment(props.period.startDate).diff(moment().startOf('day'), "days")

    // Return false iw webshop type is registration
    if (props.webshop.type.code === 'registration') {
        return false
    }

    // Checking if the shopping cart items are deliverable
    if (shoppingCart.bicycles.some(item => item.delivery === 0) || shoppingCart.accessoires.some(item => item.delivery === 0)) {
        return false
    }

    // Check if days between now and start date are smaller then the required amount of days before delivery is possible
    if (daysBetweenNowAndStartDate < webshop.days_before_delivery) {
        return false
    }

    // if the min rent length delivery is set and it's higher than the days between
    if ((!!webshop.min_rent_length_delivery) && webshop.min_rent_length_delivery > period.days) {
        return false
    }

    // if option specify holiday address or custom address is selected
    if (props.delivery.deliveryPickupLocation?.value === 'custom' || props.delivery.deliveryPickupLocation?.value === 'none') {
        return false
    }

    return deliverable;

}

export default CheckDeliverableStatus