import ApiEnum from '../../../constants/ApiEnum'

import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";

export default class PaymentEffect {

    static async requestHandle(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.post(ApiEnum.ApiWebshopPayment + '/handle' + getParameters, {
            ...values,
        }, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {
            const {paymentData} = response.data.items

            if (paymentData.resource === 'free_payment' || values.deferred_payment || (paymentData.paymentMethodCode?.toLowerCase() === 'incasso' && values.recurring_enabled)) {
                window.location = paymentData.redirectUrl
            } else {
                window.location = paymentData['_links'].checkout.href
            }

        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestTransactionStatus(values) {
        values.rentalUuid = values.uuid
        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopPayment + '/transaction-status' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then(async (response) => {

            if (values.paymentProviderCode === 'pay' && values.externalStatusId === '-90') {

                response.data.items.transactions[response.data.items.transactions.length - 1].status = 'cancelled'

                return await AppAxios.post(ApiEnum.ApiWebshopPayment + '/payment-aborted' + getParameters, {}, {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('accessToken')
                    }
                }).then(() => {
                    return {
                        error: false,
                        response: response.data.items
                    }
                })

            }

            return {
                error: false,
                response: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestPaymentMethods(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopPaymentMethods + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                error: false,
                response: response.data ?? []
            }

        }).catch((error) => {
            console.log(error)
        })
    }

    static async setPaymentMethod(values) {
        return values
    }

    static async setPaymentCustomerData(values) {
        return values
    }

}

