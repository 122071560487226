import React from 'react'
import HolidayAccommodation from "./_components/HolidayAccommodation";
import DeliveryOptions from "./_components/DeliveryOptions/DeliveryOptions";
import RouteEnum from "../../constants/RouteEnum";
import {connect} from "react-redux";
import AppAction from "../../stores/app/AppAction";
import LocationAction from "../../stores/api/location/LocationAction";
import Translate from "../../utilities/etc/Translate";

const mapStateToProps = (state, ownProps) => ({
    ...state.app,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation,
    availabilityLocations: state.availability.availabilityData.perCategory.availability
});


class Accommodation extends React.Component {


    componentDidMount() {
        this.props.scroll()

        const recurringEnabled = this.props.webshop.recurring_enabled ?? 0;
        
        this.props.dispatch(AppAction.switchStep(2))
        this.props.dispatch(AppAction.setSummary({
            period: true,
            bicycles: true,
            accessoires: true,
            accommodation: false,
            delivery: !!recurringEnabled
        }))

        this.props.dispatch(LocationAction.list({
            webshopId: this.props.webshop.id
        })).then(() => {
            this.props.dispatch(AppAction.formatDeliveryLocations())
        })

        if (recurringEnabled) {
            const partnerLocationId = this.props.delivery.partnerLocationId
            this.props.dispatch(AppAction.setDeliveryType('delivery'));
            this.props.dispatch(AppAction.setPartnerLocation(partnerLocationId));
            this.props.dispatch(AppAction.switchAppStep(RouteEnum.Form))
        }
    }

    render() {
        if (this.props.bicycles) {

            let deliveryClass = ''

            const recurringEnabled = !!this.props.webshop?.recurring_enabled
            const hideDropOffDeliveryOptions = this.props.webshop.hide_drop_off_delivery_options ?? false;
            const partnerLocation = this.props.availabilityLocations && Object.keys(this.props.availabilityLocations).length === 1 ? Object.keys(this.props.availabilityLocations)[0] : Object.values(this.props.locations)[0].id;
            const partnerLocationId = hideDropOffDeliveryOptions ? partnerLocation : this.props.delivery.partnerLocationId;
            const deliveryType = hideDropOffDeliveryOptions ? 'pickup' : this.props.delivery.type;

            if (this.props.webshop.type.code === 'registration' || hideDropOffDeliveryOptions || recurringEnabled) {
                deliveryClass = 'delivery-options is-hidden'
            }

            return (
                <div className="o-container o-container--primary">
                    <div className={'accessoire-view'}>

                        {!recurringEnabled &&
                            <HolidayAccommodation/>
                        }
                        <>
                            <div className={deliveryClass}>
                                <DeliveryOptions/>
                            </div>

                            {partnerLocationId && deliveryType && ((this.props.delivery.deliveryPickupLocation && !this.props.subDeliveryLocations) || (this.props.delivery.deliveryPickupLocation && this.props.subDeliveryLocations && this.props.delivery.subLocationNumber && this.props.delivery.deliveryPickupSubLocation)) &&
                                <div className="default-button-container">
                                    <button onClick={() => this._clickNext()} style={this.props.webshop.styles.dark}
                                            className="btn btn--rounded btn--primary">
                                        <span>{Translate('Vul uw gegevens in', this.props.language)}</span></button>
                                </div>
                            }

                        </>
                    </div>
                </div>
            )
        } else return null
    }


    _clickNext() {
        this._setPartnerLocation();
        this.props.dispatch(AppAction.switchAppStep(RouteEnum.Form))
    }

    _setPartnerLocation() {

        if (this.props.webshop.hide_drop_off_delivery_options) {
            const partnerLocation = Object.keys(this.props.availabilityLocations).length === 1 ? Object.keys(this.props.availabilityLocations)[0] : Object.values(this.props.locations)[0].id;
            this.props.dispatch(AppAction.setPartnerLocation(partnerLocation))
        }
    }
}

export default connect(mapStateToProps)(Accommodation);
