import React from 'react';
import '../styles/main.scss';
import {connect} from 'react-redux';

import ApplicationWRapper from "./_wrappers/ApplicationWrapper";
import Header from "./_components/Header";

import {Route, Switch, BrowserRouter} from 'react-router-dom'
import ViewRouter from "./ViewRouter";

import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/it'
import 'moment/locale/pt'

import AppAction from "../stores/app/AppAction";
import {Helmet} from "react-helmet";

const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.app,
    webshopData: state.info.infoData.detail
});

class App extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this.props.dispatch(AppAction.setDefaultLanguage())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.period.startDate !== this.props.period.startDate) {
            this.props.dispatch(AppAction.checkPeriodDiscount())
        }

    }

    render() {

        let siteTitle = false
        let faviconUrl = false

        if (this.props.webshopData && this.props.webshopData.webshops && this.props.webshopData.webshops.partner) {
            siteTitle = this.props.webshopData.webshops.partner.company_name
        }

        if (this.props.webshopData && this.props.webshopData.webshops && this.props.webshopData.webshops.webshop_favicon_image) {
            faviconUrl = this.props.webshopData.webshops.webshop_favicon_image
        }

        if (this.props.webshop.name) {
            siteTitle = this.props.webshop.name
        }

        moment.locale(this.props.language)

        return (
            <BrowserRouter basename={'/'}>


                <Helmet htmlAttributes={{lang: this.props.language}}>
                    <meta charSet="utf-8"/>
                    {siteTitle &&
                    <title>{siteTitle}</title>
                    }
                    <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16"/>
                </Helmet>

                <div className="ref" ref={this.myRef}>
                    <Header history={this.props.history}/>
                    <ApplicationWRapper>
                        <Switch>
                            <Route
                                path={'/:shopId/:shopSlug?'}
                                history={this.props.history}
                                render={(props) => (
                                    <ViewRouter {...props} myRef={this.myRef}/>
                                )}
                            />
                        </Switch>
                    </ApplicationWRapper>
                </div>
            </BrowserRouter>
        )
    }
}

export default connect(mapStateToProps)(App);
