import PaymentAction from './PaymentAction'

export default class PaymentReducer {

    static initialState = {
        paymentErrors: {
            handle: false,
            transactionStatus: false
        },
        paymentMessages: {
            handle: false,
            transactionStatus: false
        },
        paymentPending: {
            handle: false,
            transactionStatus: false
        },
        paymentData: {
            handle: false,
            transactionStatus: false
        },
        paymentMethods: {
            handle: false,
            provider: '',
            methods: [],
            useMethod: ''
        },
        paymentCustomerData: {
            name: '',
            accountNumber: '',
        },
        freeConfirmed: false
    };

    static reducer(state = PaymentReducer.initialState, action) {
        switch (action.type) {

            case PaymentAction.REQUEST_HANDLE:
                return {
                    ...state,
                    paymentErrors: {
                        ...state.paymentErrors,
                        handle: false
                    },
                    paymentMessages: {
                        ...state.paymentMessages,
                        handle: false
                    },
                    paymentPending: {
                        ...state.paymentPending,
                        handle: true
                    },
                    paymentData: {
                        ...state.paymentData,
                        handle: false
                    },
                };
            case PaymentAction.REQUEST_HANDLE + '_FINISHED':
                if (action.payload?.freeConfirmed) {
                    return {
                        ...state,
                        freeConfirmed: true
                    };
                } else return {
                    ...state
                }

            case PaymentAction.REQUEST_TRANSACTION_STATUS:
                return {
                    ...state,
                    paymentErrors: {
                        ...state.paymentErrors,
                        transactionStatus: false
                    },
                    paymentMessages: {
                        ...state.paymentMessages,
                        transactionStatus: false
                    },
                    paymentPending: {
                        ...state.paymentPending,
                        transactionStatus: true
                    },
                    paymentData: {
                        ...state.paymentData,
                        transactionStatus: false
                    },
                };
            case PaymentAction.REQUEST_TRANSACTION_STATUS + '_FINISHED':
                return {
                    ...state,
                    paymentErrors: {
                        ...state.paymentErrors,
                        transactionStatus: action.payload.error
                    },
                    paymentMessages: {
                        ...state.paymentMessages,
                    },
                    paymentPending: {
                        ...state.paymentPending,
                        transactionStatus: false
                    },
                    paymentData: {
                        ...state.paymentData,
                        transactionStatus: action.payload.response
                    },
                };
            case PaymentAction.REQUEST_PAYMENT_METHODS:
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        methods: []
                    },
                };
            case PaymentAction.REQUEST_PAYMENT_METHODS + '_FINISHED':
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        provider: action.payload?.response?.items?.provider ?? '',
                        methods: action.payload?.response?.items?.methods ?? []
                    },
                };
            case PaymentAction.SET_PAYMENT_METHODS:
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        useMethod: ''                    },
                };
            case PaymentAction.SET_PAYMENT_METHODS + '_FINISHED':
                return {
                    ...state,
                    paymentMethods: {
                        ...state.paymentMethods,
                        useMethod: action.payload
                    },
                };
            case PaymentAction.SET_PAYMENT_CUSTOMER_DATA + '_FINISHED':
                return {
                    ...state,
                    paymentCustomerData: {
                        ...state.paymentCustomerData,
                        name: action.payload.name ?? state.paymentCustomerData.name,
                        accountNumber: action.payload.accountNumber ?? state.paymentCustomerData.accountNumber,
                    },
                };

            default:
                return state;
        }
    }
}