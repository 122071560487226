import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import ShoppingCartAction from "../../../stores/shoppingCart/ShoppingCartAction";
import RouteEnum from "../../../constants/RouteEnum";
import Translate from "../../../utilities/etc/Translate";


const mapStateToProps = (state) => ({
    ...state.app
});

class ValidationPopup extends React.Component {

    render() {


        if (!this.props.validation.bicycles) {

            // Check if the user is past the bicycles
            if (this.props.step > RouteEnum.Bicycles) {
                return (
                    <div className="c-modal">
                        <div className="c-modal__bg"/>
                        <div className="c-modal__content c-modal__content--text">
                            <div className="c-modal__header">
                                <h2><i
                                    className="icon icon-info"/>{Translate('Excuus, uw gekozen *item_plural* zijn niet meer beschikbaar', this.props.language)}
                                </h2>
                            </div>
                            <div className="c-modal__body">
                                <p>


                                    {Translate('Iemand anders is u voor geweest waardoor het aantal *item_plural* die eerder gekozen zijn niet meer in die aantal beschikbaar is. Hierdoor zult u een nieuwe selectie moeten maken. Excuses voor het ongemak.', this.props.language)}
                                </p>
                                <button onClick={() => this._onClickClosePopupBicycle()}
                                        className="btn btn--rounded btn--primary"
                                        style={{fontSize: '18px'}}>{Translate('Selecteer *item_plural*', this.props.language)}

                                </button>
                            </div>
                        </div>
                    </div>
                )
            } else return null
        }

        if (!this.props.validation.accessoires) {

            // Check if the user is past the accessoires
            if (this.props.step > RouteEnum.Accessoires) {
                return (
                    <div className="c-modal">
                        <div className="c-modal__bg"/>
                        <div className="c-modal__content c-modal__content--text">
                            <div className="c-modal__header">
                                <h2><i
                                    className="icon icon-info"/>{Translate('Excuus, uw gekozen accesoires zijn niet meer beschikbaar', this.props.language)}
                                </h2>
                            </div>
                            <div className="c-modal__body">
                                <p>
                                    {Translate('Iemand anders is u voor geweest waardoor het aantal accessoires die eerder gekozen zijn niet meer in die aantal beschikbaar is. Hierdoor zult u een nieuwe selectie moeten maken. Excuses voor het ongemak.', this.props.language)}
                                </p>
                                <button onClick={() => this._onClickClosePopupAccessoire()} className="btn btn--rounded btn--primary" style={{fontSize: '18px'}}>{Translate('Selecteer accessoires', this.props.language)}</button>
                            </div>
                        </div>
                    </div>
                )
            } else return null
        }
    }

    _onClickClosePopupBicycle() {

        // Switching the view to bicycle
        this.props.dispatch(AppAction.switchAppStep(2)).then(() => {

            // Emptying the shoppingCart
            this.props.dispatch(ShoppingCartAction.empty()).then(() => {

                // Validating again for removing the popup
                this.props.dispatch(AppAction.validateShoppingCart())
            })
        })
    }

    _onClickClosePopupAccessoire() {

        // Switching the view to accessoires
        this.props.dispatch(AppAction.switchAppStep(3)).then(() => {

            // Emptying the shoppingCart
            this.props.dispatch(ShoppingCartAction.empty('accessoires')).then(() => {

                // Validating again for removing the popup
                this.props.dispatch(AppAction.validateShoppingCart())
            })
        })
    }

}

export default connect(mapStateToProps)(ValidationPopup);