import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import './index.css';
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import {createMemoryHistory} from 'history';
import rootStore from './stores/rootStore';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {injectTranslateStore} from "./utilities/etc/Translate";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://a983365064fc428882e0e76d9f0e00e4@o425936.ingest.sentry.io/5449018",
    autoSessionTracking: true,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["https://go.tilia.app", "https://www.go.tilia.app"],
        }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});


(async () => {
    const initialState = {};
    const history = createMemoryHistory();
    const store = rootStore(initialState, history);
    injectTranslateStore(store)
    const rootEl = document.getElementById('root');
    const render = (Component, el) => {
        ReactDOM.render(
            <Provider store={store}>
                <Component history={history} dispatch={store.dispatch}/>
            </Provider>,
            el
        );
    };

    render(App, rootEl);
})(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
