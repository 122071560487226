import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import Translate from "../../../utilities/etc/Translate";


const mapStateToProps = (state) => ({
    ...state.app
});

class AccessoireConditionPopup extends React.Component {

    render() {

        const popup = this.props.popup.accessoireCondition
        const data = this.props.accessoires[popup.id]

        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">
                    <button className="c-modal__close" onClick={() => this._onClickClosePopup()}>
                        <i className="icon icon-cross" />
                    </button>
                    <div className="c-modal__header">
                        <h2><i className="icon icon-info"/> {Translate('Maximaal aantal', this.props.language)} {DisplayItemName(data)} {Translate('bereikt', this.props.language)}.</h2>
                    </div>
                    <div className="c-modal__body">
                        <p>
                            {Translate('Het is niet mogelijk om meer van de volgende accessoires te bestellen dan dat u *item_plural* heeft geselecteerd:', this.props.language)} {DisplayItemName(data).toLowerCase()} <br />
                            {Translate('U kunt van dit type accessoire geen bestelling maken voor uw eigen (meegenomen) *item_plural*', this.props.language)}.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    _onClickClosePopup() {
        this.props.dispatch(AppAction.changeAccessoireConditionPopup({
            show: false,
            id: false
        }))
    }

}

export default connect(mapStateToProps)(AccessoireConditionPopup);