import {useAppSelector} from "../../../stores/hooks";
import moment from "moment";
import DateRange from "react-date-range/dist/components/DateRange";
import Translate from "../../../utilities/etc/Translate";
import React from 'react'
import * as rdrLocales from "date-fns/esm/locale";
import AppAction from "../../../stores/app/AppAction";
import ShoppingCartAction from "../../../stores/shoppingCart/ShoppingCartAction";
import {useDispatch} from "react-redux";


export const OldPeriodDisplay = (props) => {
    const app = useAppSelector(state => state.app)
    const dispatch = useDispatch()
    const periodRange = app.period

    let ranges = [periodRange]

    const changePeriod = (values) => {

        if (app.webshop.type.code === 'registration') {
            values.startDate = moment().startOf('day').toDate();
        }

        dispatch(AppAction.setPeriodOld(values)).then(() => {
            dispatch(ShoppingCartAction.empty())
            dispatch(AppAction.setOpenedLocations())
        })
    }


    return (
        <div className="o-container o-container--primary">
            <div className="c-date-picker c-date-picker--old" style={{width: '100%'}} key={'period-view'}>

                <div className="c-date-picker__info">
                    <h5>{Translate('Selecteer start- en einddatum', app.language)}</h5>

                    <div>
                        {periodRange?.startDate &&

                            <div className="btn btn--pill" style={app.webshop.styles.dark}>
                                <span>{moment(periodRange?.startDate).format('dddd D MMMM YYYY')}</span></div>

                        }

                        {periodRange?.endDate && periodRange?.endDate !== periodRange?.startDate &&

                            <>
                                <small>{Translate('t/m', app.language)}</small>

                                <div className="btn btn--pill" style={app.webshop.styles.dark}>
                                    <span>{moment(periodRange?.endDate).format('dddd D MMMM YYYY')}</span>
                                </div>

                                <div className="btn btn--pill h-days-amount" style={app.webshop.styles.dark}>
                                    <span>{periodRange?.days} {Translate('dag(en)', app.language)}</span>
                                </div>

                            </>

                        }
                    </div>
                </div>
                <DateRange
                    locale={rdrLocales[app.language]}
                    editableDateInputs={true}
                    onChange={item => changePeriod(item.selection)}
                    moveRangeOnFirstSelection={false}
                    ranges={ranges}
                    months={2}
                    direction="horizontal"
                    minDate={props.minDate}
                    showPreview={false}
                    weekStartsOn={1}
                    isOutsideRange={() => false}
                    disabled={[new Date('23-09-2021')]}
                />

                {app.period.endDate && periodRange.days < app.webshop.min_rent_days &&
                    <div className="c-notification c-notification--error">
                        <i className="icon icon-info"/>
                        <span>{Translate('Selecteer minimaal', app.language)} {app.webshop.min_rent_days} {Translate('dagen', app.language)}</span>
                    </div>
                }

                {app.webshop.max_rent_days && app.period.endDate && periodRange.days > app.webshop.max_rent_days &&
                    <div className="c-notification c-notification--error">
                        <i className="icon icon-info"/>
                        <span>{Translate('Selecteer maximaal', app.language)} {app.webshop.max_rent_days} {Translate('dagen', app.language)}</span>
                    </div>
                }

                {app.period.startDate && !app.openedLocations.length &&
                    <div className="c-notification c-notification--error">
                        <i className="icon icon-info"/>
                        <span>{Translate('Er zijn geen locaties geopend op de geselecteerde startdatum', app.language)}</span>
                    </div>
                }

                {(periodRange.days > 0 &&
                        app.period.endDate &&
                        periodRange.days >= app.webshop.min_rent_days &&
                        ((app.webshop.max_rent_days && periodRange.days <= app.webshop.max_rent_days) || !app.webshop.max_rent_days)
                        && !!app.openedLocations.length
                    ) &&

                    <>
                        {app.webshop.type.code !== 'registration' &&
                            <button onClick={() => props.clickBicycleSelect()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('Fietsen selecteren', app.language)}</span>
                            </button>
                        }

                        {app.webshop.type.code === 'registration' &&
                            <button onClick={() => props.clickAccommodation()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('Doorgaan', app.language)}</span>
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    )


}



