import CategoryAction from './CategoryAction'

export default class CategoryReducer {

    static initialState = {
        categoryErros: {
            list: false
        },
        categoryMessages: {
            list: false
        },
        categoryPending: {
            list: false
        },
        categoryData: {
            list: false
        }
    };

    static reducer(state = CategoryReducer.initialState, action) {
        switch (action.type) {

            case CategoryAction.REQUEST_lIST:
                return {
                    ...state,
                    categoryErrors: {
                        ...state.categoryErrors,
                        list: false
                    },
                    categoryMessages: {
                        ...state.categoryMessages,
                        list: false
                    },
                    categoryPending: {
                        ...state.categoryPending,
                        list: true
                    },
                    categoryData: {
                        ...state.categoryData,
                        list: false
                    },
                };
            case CategoryAction.REQUEST_lIST + '_FINISHED':
                
                return {
                    ...state,
                    categoryErrors: {
                        ...state.categoryErrors,
                        list: action.payload.error
                    },
                    categoryMessages: {
                        ...state.categoryMessages,
                        list: action.payload.message
                    },
                    categoryPending: {
                        ...state.categoryPending,
                        list: false
                    },
                    categoryData: {
                        ...state.categoryData,
                        list: action.payload
                    },
                };

            default:
                return state;
        }
    }
}