import React from 'react'

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'rc-time-picker/assets/index.css';

import {useAppSelector} from "../../../stores/hooks";
import Translate from "../../../utilities/etc/Translate";
import {Calendar} from 'react-date-range';
import * as rdrLocales from "date-fns/esm/locale";

export const RecurringPeriodDisplay = (props) => {

    const app = useAppSelector(state => state.app)

    const {
        chosenUnit,
        periodRange,
    } = useAppSelector(state => state.period);


    return (
        <div className="o-container o-container--primary">


            {!!chosenUnit &&
                <>
                    <div className="s-date-time-picker__singleDate">
                        <h5>{Translate('Selecteer gewenste startdatum', app.language)}</h5>
                    </div>
                    <div>
                        <Calendar onChange={item =>  props.changePeriod({startDate: item})}
                                  date={periodRange.startDate}
                                  minDate={props.minDate}
                                  locale={rdrLocales[app.language]}
                                  color={'#555555'}
                        />
                    </div>
                    <>
                        {app.webshop.type.code !== 'registration' &&
                            <button onClick={() => props.clickBicycleSelect()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('Volgende stap', app.language)}</span>
                            </button>
                        }

                        {app.webshop.type.code === 'registration' &&
                            <button onClick={() => props.clickAccommodation()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('Volgende stap', app.language)}</span>
                            </button>
                        }
                    </>
                </>
            }

        </div>
    )

}


export default RecurringPeriodDisplay;
