import moment from "moment";

const GetMinDate = (webshop) => {

    if (webshop.type.code !== 'registration') {


        let startDate = moment('01-01-2021', 'DD-MM-YYYY').toDate();

        let minDate;
        let minWebshopDate = moment()

        if (webshop.min_days_before_rent_start) {
            minWebshopDate = moment().add(parseInt(webshop.min_days_before_rent_start), 'days')
        }

        if (webshop.lock_time && webshop.lock_time !== '' && moment().format("HH:mm") > webshop.lock_time) {
            minWebshopDate = minWebshopDate.add(1, 'days')
        }

        minWebshopDate = minWebshopDate.toDate()

        if (minWebshopDate > startDate) {
            minDate = minWebshopDate;
        } else {
            minDate = startDate
        }

        return minDate
    } else return moment().toDate()
}

export default GetMinDate