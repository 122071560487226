import AppAction from "../../stores/app/AppAction";
import ShoppingCartAction from "../../stores/shoppingCart/ShoppingCartAction";

const ChangeBicycleNumber = (data, direction, dispatch) => {

    const values = {
        selected: data.selected + direction,
        id: data.id,
        itemLocations: data.locations,
    }

    // Mutating the number
    dispatch(AppAction.mutateBicycleSelectionNumber(values)).then(() => {

        // Setting the location availability
        dispatch(AppAction.setLocationAvailability()).then(() => {

            // Setting the bicycle availability
            dispatch(AppAction.setBicycleAvailability())

            // Updating the cart
            dispatch(ShoppingCartAction.update())

            // Removing the accessoires from the cart
            dispatch(ShoppingCartAction.empty('accessoires'))
        })
    })
}

export default ChangeBicycleNumber
