const FormatCartToRetailRequest = (shoppingcart) => {

    const categories = []

    if (shoppingcart.bicycles.length) {

        shoppingcart.bicycles.forEach((bicycle) => {
            const bicycleCategory = {}
            bicycleCategory.category_id = bicycle.id
            bicycleCategory.quantity = bicycle.selected
            categories.push(bicycleCategory)
        })

        shoppingcart.accessoires.forEach((accessoire) => {
            const accessoireCategory = {}
            accessoireCategory.category_id = accessoire.id
            accessoireCategory.quantity = accessoire.selected
            categories.push(accessoireCategory)
        })
    }

    return categories

}

export default FormatCartToRetailRequest