import React from 'react'
import Loader from 'react-loader-spinner'

const Loading = ({width = "80", height = "80", removeText = false}) => (
    <div className="load-wrapper">
        <div className="c-custom-loader-overlay">
            <div className="c-custom-loader">
                <Loader
                    width={width}
                    height={height}
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                    type={'Bars'}
                />
                {!removeText &&
                <small>Loading...</small>
                }
            </div>
        </div>

    </div>
)

export default Loading;
