import ActionUtility from '../../../utilities/etc/ActionUtility'
import InfoEffect from "./InfoEffect";

export default class InfoAction {
    static REQUEST_DETAIL = 'InfoAction.REQUEST_DETAIL';
    static REQUEST_PREFERENCES = 'InfoAction.REQUEST_PREFERENCES';

    static detail() {
        return async (dispatch, getState) => {

            let siteOrigin = window.location.origin;

            if (getState().app.slug) {
                siteOrigin += getState().app.slug
            }

            const values = {
                url: siteOrigin,
                language: getState().app.language
            }


            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, InfoAction.REQUEST_DETAIL, InfoEffect.requestDetail, values);
        };
    }

    static preferences(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, InfoAction.REQUEST_PREFERENCES, InfoEffect.requestPreferences, values);
        };
    }

}