import LocationAction from './LocationAction'

export default class LocationReducer {

    static initialState = {
        locationErrors: {
            list: false,
            detail: false
        },
        locationMessages: {
            list: false,
            detail: false
        },
        locationPending: {
            list: false,
            detail: false
        },
        locationData: {
            list: false,
            detail: false
        }
    };

    static reducer(state = LocationReducer.initialState, action) {
        switch (action.type) {

            /** REQUEST LIST **/

            case LocationAction.REQUEST_lIST:
                return {
                    ...state,
                    locationErrors: {
                        ...state.locationErrors,
                        list: false
                    },
                    locationMessages: {
                        ...state.locationMessages,
                        list: false
                    },
                    locationPending: {
                        ...state.locationPending,
                        list: true
                    },
                    locationData: {
                        ...state.locationData,
                        list: false
                    },
                };
            case LocationAction.REQUEST_lIST + '_FINISHED':
                return {
                    ...state,
                    locationErrors: {
                        ...state.locationErrors,
                        list: action.payload.error
                    },
                    locationMessages: {
                        ...state.locationMessages,
                        list: action.payload.message
                    },
                    locationPending: {
                        ...state.locationPending,
                        list: false
                    },
                    locationData: {
                        ...state.locationData,
                        list: action.payload.response
                    },
                };

            /** REQUEST DETAIL **/

            case LocationAction.REQUEST_DETAIL:
                return {
                    ...state,
                    locationErrors: {
                        ...state.locationErrors,
                        detail: false
                    },
                    locationMessages: {
                        ...state.locationMessages,
                        detail: false
                    },
                    locationPending: {
                        ...state.locationPending,
                        detail: true
                    },
                    locationData: {
                        ...state.locationData,
                        detail: false
                    },
                };
            case LocationAction.REQUEST_DETAIL + '_FINISHED':
                return {
                    ...state,
                    locationErrors: {
                        ...state.locationErrors,
                        detail: action.payload.error
                    },
                    locationMessages: {
                        ...state.locationMessages,
                        detail: action.payload.message
                    },
                    locationPending: {
                        ...state.locationPending,
                        detail: false
                    },
                    locationData: {
                        ...state.locationData,
                        detail: action.payload.response
                    },
                };


            default:
                return state;
        }
    }
}