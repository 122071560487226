import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import moment from 'moment'
import DisplayItemName from "../../utilities/displayers/DisplayItemName";
import CalculateDaysBetween from "../../utilities/etc/CalculateDaysBetween";
import DisplayPrice from "../../utilities/displayers/DIsplayPrice";
import Translate from "../../utilities/etc/Translate";
import DisplayDecimal from "../../utilities/displayers/DisplayDecimal";
import ThanksRecurring from "./ThanksRecurring";

const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.app,
    rental: state.rental,
    categories: state.category,
    info: state.info
});

class Thanks extends React.Component {

    componentDidMount() {
        this.props.dispatch(AppAction.switchAppStep(7))
    }

    render() {
        const insuranceIcon = '/images/insurance-icon.svg'

        const rentalData = this.props.rental.rentalData.detail
        const hideDropOffAndDelivery = !!this.props.info.infoData.detail.webshops?.webshop?.hide_drop_off_delivery_options ?? false;
        const deferredPaymentEnabled = !!this.props.webshop.deferred_payment_enabled;
        const webshopIsRecurring = !!this.props.webshop.recurring_enabled
        let discount = false;

        console.log(rentalData)

        if (rentalData && rentalData.totalDiscount) {
            discount = parseFloat((parseFloat(rentalData.totalDiscount) / parseFloat(rentalData.subTotalPrice))) * 100
        }

        if (rentalData && !webshopIsRecurring) {

            const privatePerson = rentalData.privatePerson
            const id = rentalData.rental.id;
            let salutation = Translate('meneer/mevrouw', this.props.language)


            if (!!privatePerson) {

                switch (privatePerson.gender_id) {
                    case(1):
                        salutation = Translate('Meneer', this.props.language)
                        break;
                    case(2):
                        salutation = Translate('Mevrouw', this.props.language)
                        break;
                    case(3):
                        salutation = Translate('Persoon', this.props.language)
                        break;
                    default:
                        salutation = Translate('meneer/mevrouw', this.props.language)
                        break;
                }
            }

            salutation = salutation.toLowerCase() + ' ' + privatePerson.last_name

            return (

                <div className={'o-container o-container--primary'} style={{padding: '4rem 0'}}>

                    <p>

                        <h1>{Translate('Bedankt, uw reservering is succesvol afgerond', this.props.language)}</h1>

                        <p>{Translate('Geachte', this.props.language)} {salutation}</p>

                        <p>{Translate('Bedankt voor uw reservering! Wij gaan ervoor zorgen dat uw *item_single*avontuur onvergetelijk wordt.', this.props.language)}</p>

                        <hr/>

                        <h3 style={{marginTop: '3rem'}}>{Translate('Uw reservering', this.props.language)}:</h3>

                        <p>{Translate('Bestelnummer', this.props.language)}: {id}</p>

                        {!!rentalData.time_unit.time_required ? (
                            <p>{Translate('Aantal dagen', this.props.language)}: {moment(rentalData.rental.date_rent_start).format('dddd D MMMM HH:mm')} {Translate('t/m', this.props.language)} {moment(rentalData.rental.date_rent_end).format('dddd D MMMM HH:mm')}. {CalculateDaysBetween(rentalData.rental.date_rent_start, rentalData.rental.date_rent_end)} {Translate('dag(en)', this.props.language)}</p>
                        ) : (
                            <p>{Translate('Aantal dagen', this.props.language)}: {moment(rentalData.rental.date_rent_start).format('dddd D MMMM')} {Translate('t/m', this.props.language)} {moment(rentalData.rental.date_rent_end).format('dddd D MMMM')}. {CalculateDaysBetween(rentalData.rental.date_rent_start, rentalData.rental.date_rent_end)} {Translate('dag(en)', this.props.language)}</p>
                        )}


                        {!hideDropOffAndDelivery && !!rentalData.rental.delivery &&
                            <p>{Translate('Adres', this.props.language)}: {rentalData.delivery_address.name}, {rentalData.delivery_address.street} {rentalData.delivery_address.number}<br/>
                                {Translate('Bezorgen op vakantieadres (tussen 08:00 - 10:00)', this.props.language)}
                            </p>
                        }

                        {!hideDropOffAndDelivery && !rentalData.rental.delivery &&
                            <p>{Translate('U kunt uw *item_plural* afhalen op ', this.props.language)} {moment(rentalData.rental.date_rent_start).format('DD-MM-YYYY')} {Translate('Bij:', this.props.language)}
                                <br/>{rentalData.partner_location.name}, {rentalData.partner_location.address} {rentalData.partner_location.number}
                            </p>
                        }
                        <hr/>

                        <div className="c-order-overview__products__container">

                            {rentalData.rentalItems.map((item) => {
                                if (item.type === 'category' || item.type === 'insurance') {
                                    const categoryData = item.category
                                    let itemImage = null;
                                    if (item.type === 'category') {
                                        itemImage = categoryData.image
                                    } else if (item.type === 'insurance') {
                                        itemImage = insuranceIcon
                                    }
                                    return (
                                        <div className="c-order-overview__products__single">
                                            <div className="c-order-overview__products__single__container">
                                                <div className="c-order-overview__products__single__media">
                                                    <img src={itemImage} alt=""/>
                                                </div>
                                                <span
                                                    className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                                <span
                                                    className="c-order-overview__products__single__description">{DisplayItemName(categoryData)}</span>
                                            </div>
                                            <span
                                                className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}

                            <div style={{borderTop: '1px solid #DEE5F0', margin: '2rem 0'}}/>

                            {rentalData.rentalItems.map((item) => {
                                if (item.type === 'deposit') {
                                    return (
                                        <div className="c-order-overview__products__single">
                                            <div className="c-order-overview__products__single__container">

                                        <span
                                            className="c-order-overview__products__single__amount">{item.quantity}x </span>
                                                <span
                                                    className="c-order-overview__products__single__description">{item.description}</span>
                                            </div>
                                            <span
                                                className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}

                            {!deferredPaymentEnabled &&
                            <div className="c-order-overview__total">
                                <span>{Translate('Totaalbedrag (incl. 21% BTW)', this.props.language)}</span>
                                <span>{DisplayPrice(rentalData.subTotalPrice)}</span>
                            </div>
                            }
                            {!deferredPaymentEnabled && !!rentalData.totalDiscount !== false &&
                                <div style={{paddingTop: 0}} className="c-order-overview__total">
                                    <span>{DisplayDecimal(discount)}% {Translate('Korting', this.props.language)}</span>
                                    <span>{DisplayPrice(rentalData.totalDiscount)}</span>
                                </div>
                            }
                            {!deferredPaymentEnabled &&
                                <div style={{paddingTop: 0}} className="c-order-overview__total">
                                <span>{Translate('Totaal betaald', this.props.language)}</span>
                                <span>{DisplayPrice(rentalData.totalPrice)}</span>
                            </div>
                            }


                        </div>

                        <hr/>

                        <p>{Translate('Wij wensen u alvast veel *item_single*plezier! Het team *item_plural_cap* op Texel.', this.props.language)}</p>

                    </p>

                </div>
            )

        } else if (rentalData && webshopIsRecurring){
            return <ThanksRecurring/>
        }
        else return null

    }

}

export default connect(mapStateToProps)(Thanks);
