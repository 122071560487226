import moment from 'moment'

const CalculateDaysBetweenOld = (startDate, endDate) => {

    // Adding 1 day because 1 selected day counts as 0
    return moment(endDate).diff(moment(startDate), "days") + 1

}

export default CalculateDaysBetweenOld
