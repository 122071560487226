import ActionUtility from '../../../utilities/etc/ActionUtility'
import AddressEffect from "./AddressEffect";

export default class AddressAction {
    static REQUEST_lIST = 'AddressAction.REQUEST_LIST';


    static list(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, AddressAction.REQUEST_lIST, AddressEffect.requestList, values);
        };
    }

}