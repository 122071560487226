import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";
import {connect} from "react-redux";
import StickyString from "../../../utilities/etc/StickyString";
import AppAction from "../../../stores/app/AppAction";
import {CSSTransition} from "react-transition-group";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
});


class StickyBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidUpdate() {

        if (this.props.shoppingCart.bicycles.length > 0 && this.state.loaded === false) {
            this.setState({
                loaded: true
            })
        }

        if (this.props.shoppingCart.bicycles.length === 0 && this.state.loaded === true) {
            this.setState({
                loaded: false
            })
        }
    }

    render() {

        const props = this.props
        const cart = props.shoppingCart
        const bicycleString = StickyString(cart.bicycles)


        const _clickNext = () => {

            props.dispatch(AppAction.switchAppStep(RouteEnum.Accessoires))

        }

        if (cart.bicycles.length > 0) {

            return (
                <CSSTransition
                    in={this.state.loaded}

                    timeout={500}
                    classNames="bottom"
                >



                    <footer className="c-sticky-footer" key={'sticky-footer'}>
                        <div className="c-sticky-footer__container">

                            <div className="c-sticky-footer__selection">
                                <span><strong>{Translate('Uw selectie', this.props.language)}</strong></span>
                                <span>{bicycleString}</span>
                            </div>

                            <div className="c-sticky-footer__totals">
                                <span><strong>{Translate('Totaal', this.props.language)} {DisplayPrice(cart.bicyclePrice)}</strong></span>
                                <button style={props.webshop.styles.dark} onClick={() => _clickNext()}
                                        className="btn btn--rounded"><span>{Translate('Doorgaan', this.props.language)}</span></button>
                            </div>

                        </div>
                    </footer>
                </CSSTransition>
            )
        } else {
            return null
        }

    }

}

export default connect(mapStateToProps)(StickyBar);
