import React from 'react'
import {connect} from "react-redux";
import Translate from "../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app

});

class SubjectBar extends React.Component {

    render() {


        let subjectBarData = {
            1: Translate('Periode en *item_plural* selecteren', this.props.language),
            2: Translate('Verblijf en levering', this.props.language),
            3: Translate('Gegevens en betalen', this.props.language),
        }

        if (this.props.webshop && (this.props.webshop.type.code === 'registration' || (!!this.props.webshop.delivery_disabled && !this.props.webshop.recurring_enabled))) {
            subjectBarData = {
                1: Translate('Periode', this.props.language),
                2: Translate('Verblijfadres', this.props.language),
                3: Translate('Gegevens', this.props.language),
            }
        }

        if (this.props.webshop && !!this.props.webshop.recurring_enabled) {
            subjectBarData = {
                1: Translate('Periode', this.props.language),
                2: Translate('Overzicht', this.props.language),
                3: Translate('Gegevens', this.props.language),
            }
        }


        if (this.props.webshop) {
            return (
                <div className="o-container o-container--primary">
                    <div className="c-subject-bar">

                        {Object.keys(subjectBarData).map((key, i) => {
                            const text = subjectBarData[key]
                            let className = 'c-subject-bar__single'
                            let style = this.props.webshop.styles.light

                            // Check if is active. Add active class

                            if (this.props.wizard === parseInt(key)) {
                                className += ' c-subject-bar__single--active'
                                style = this.props.webshop.styles.dark
                            }

                            return (
                                <div className={className} style={style} key={i + '-subject'}>
                                    <div className="c-subject-bar__single__number">
                                        <small>{key}</small>
                                    </div>
                                    <h2>{text}</h2>
                                </div>
                            )
                        })}

                    </div>
                </div>
            )
        } else return null
    }


}

export default connect(mapStateToProps)(SubjectBar);
