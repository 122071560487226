import Translate from "../../../utilities/etc/Translate";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import React from "react";
import DisplayDecimal from "../../../utilities/displayers/DisplayDecimal";

// todo: This page needs a clean up after lots of adjustments

const Discount = (props) => {

    if (props.percentage) {

        const saleString = Translate('Korting', props.language);

        return (
            <div className="c-summary__subject c-summary__totals">
                <h5>{DisplayDecimal(props.percentage)}% {saleString}</h5>
                <span>{DisplayPrice(props.cutPrice)}</span>
            </div>
        )
    } else {
        return null
    }
}

const TotalPrice = (props) => {

    const accessoires = props.shoppingCart.accessoires
    let discount = false;

    if (props.webshop.default_discount) {
            discount = props.webshop.discount_percentage
    } else {
        if (props.delivery.deliveryPickupLocation && props.delivery.deliveryPickupLocation.address && props.delivery.deliveryPickupLocation.address.discount_percentage) {
            discount = parseFloat(props.delivery.deliveryPickupLocation.address.discount_percentage);
        }
    }


    if (props.summary.accessoires && accessoires.length > 0) {

        let cutPrice = 0

        if (discount) {
            cutPrice = (discount / 100) * parseFloat(props.shoppingCart.totalPrice)
        }

        return (

            <>
                <Discount percentage={discount} cutPrice={cutPrice} language={props.language}/>
                <div className="c-summary__subject c-summary__totals">
                    <h5>{Translate('Totaal', props.language)}</h5>
                    <span>{DisplayPrice(parseFloat(props.shoppingCart.totalPrice) - cutPrice)}</span>
                </div>
            </>
        )
    } else {
        let cutPrice = 0

        if (discount) {
            cutPrice = (discount / 100) * parseFloat(props.shoppingCart.bicyclePrice)
        }

        return (
            <>
                <Discount percentage={discount} cutPrice={cutPrice} language={props.language}/>
                <div className="c-summary__subject c-summary__totals">
                    <h5>{Translate('Totaal', props.language)}</h5>
                    <span>{DisplayPrice(props.shoppingCart.bicyclePrice - cutPrice)}</span>
                </div>

            </>
        )
    }
}

export default TotalPrice