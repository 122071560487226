export default class AppEffect {

    static update(values) {

        const shoppingCart = {
            bicycles: [],
            accessoires: [],
            totalPrice: 0,
            bicyclePrice: 0,
            accessoirePrice: 0
        }

        if(values.bicycles) {
            values.bicycles = Object.keys(values.bicycles).forEach((key) => {
                if (values.bicycles[key].selected > 0) {
                    shoppingCart.bicycles.push(values.bicycles[key])
                    shoppingCart.totalPrice += values.bicycles[key].selected * values.bicycles[key].price
                    shoppingCart.bicyclePrice += values.bicycles[key].selected * values.bicycles[key].price

                }
            });
        }

        if(values.accessoires){
            values.accessoires = Object.keys(values.accessoires).forEach((key) => {
                if(values.accessoires[key].selected > 0){
                    shoppingCart.accessoires.push(values.accessoires[key])
                    shoppingCart.totalPrice += values.accessoires[key].selected * values.accessoires[key].price
                    shoppingCart.accessoirePrice += values.accessoires[key].selected * values.accessoires[key].price
                }
            });
        }


        return shoppingCart
    }

    static empty(type) {
        return type
    }

}


