import InfoAction from './InfoAction'

export default class InfoReducer {

    static initialState = {
        infoErrors: {
            detail: false,
            preferences: false
        },
        infoMessages: {
            detail: false,
            preferences: false
        },
        infoPending: {
            detail: false,
            preferences: false
        },
        infoData: {
            detail: false,
            preferences: false
        }
    };

    static reducer(state = InfoReducer.initialState, action) {
        switch (action.type) {

            case InfoAction.REQUEST_DETAIL:
                return {
                    ...state,
                    infoErrors: {
                        ...state.infoErrors,
                        detail: false
                    },
                    infoMessages: {
                        ...state.infoMessages,
                        detail: false
                    },
                    infoPending: {
                        ...state.infoPending,
                        detail: true
                    },
                    infoData: {
                        ...state.infoData,
                        detail: false
                    },
                };
            case InfoAction.REQUEST_DETAIL + '_FINISHED':
                return {
                    ...state,
                    infoErrors: {
                        ...state.infoErrors,
                        detail: action.payload.error
                    },
                    infoMessages: {
                        ...state.infoMessages,
                        detail: action.payload.message
                    },
                    infoPending: {
                        ...state.infoPending,
                        detail: false
                    },
                    infoData: {
                        ...state.infoData,
                        detail: action.payload.response
                    },
                };

            case InfoAction.REQUEST_PREFERENCES:
                return {
                    ...state,
                    infoErrors: {
                        ...state.infoErrors,
                        preferences: false
                    },
                    infoMessages: {
                        ...state.infoMessages,
                        preferences: false
                    },
                    infoPending: {
                        ...state.infoPending,
                        preferences: true
                    },
                    infoData: {
                        ...state.infoData,
                        preferences: false
                    },
                };
            case InfoAction.REQUEST_PREFERENCES + '_FINISHED':
                return {
                    ...state,
                    infoErrors: {
                        ...state.infoErrors,
                        preferences: action.payload.error
                    },
                    infoMessages: {
                        ...state.infoMessages,
                        preferences: action.payload.message
                    },
                    infoPending: {
                        ...state.infoPending,
                        preferences: false
                    },
                    infoData: {
                        ...state.infoData,
                        preferences: action.payload.response
                    },
                };

            default:
                return state;
        }
    }
}