export const FormatDayNumberToName = (dayNumber) => {

    switch (dayNumber) {
        case (1):
            return 'Maandag'
        case (2):
            return 'Dinsdag'
        case (3):
            return 'Woensdag'
        case (4):
            return 'Donderdag'
        case (5):
            return 'Vrijdag'
        case (6):
            return 'Zaterdag'
        case (7):
            return 'Zondag'
        default: {
            return false
        }
    }

}