import ActionUtility from '../../../utilities/etc/ActionUtility'
import LocationEffect from "./LocationEffect";

export default class LocationAction {
    static REQUEST_lIST = 'LocationAction.REQUEST_LIST';
    static REQUEST_DETAIL = 'LocationAction.REQUEST_DETAIl';

    static list(values) {
        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, LocationAction.REQUEST_lIST, LocationEffect.requestList, values);
        };
    }

    static detail(values) {
        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, LocationAction.REQUEST_DETAIL, LocationEffect.requestDetail, values);
        };
    }

}