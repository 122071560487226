import RentalAction from './RentalAction'

export default class RentalReducer {

    static initialState = {
        rentalId: false,
        rentalErrors: {
            list: false,
            update: false,
            create: false,
            detail: false,
            delete: false
        },
        rentalMessages: {
            list: false,
            update: false,
            create: false,
            detail: false,
            delete: false
        },
        rentalPending: {
            list: false,
            update: false,
            create: false,
            detail: false,
            delete: false
        },
        rentalData: {
            list: false,
            update: false,
            create: false,
            detail: false,
            delete: false
        }
    };

    static reducer(state = RentalReducer.initialState, action) {
        switch (action.type) {

            /** REQUEST LIST **/

            case RentalAction.REQUEST_lIST:
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        list: false
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        list: false
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        list: true
                    },
                    rentalData: {
                        ...state.rentalData,
                        list: false
                    },
                };
            case RentalAction.REQUEST_lIST + '_FINISHED':
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        list: action.payload.error
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        list: action.payload.message
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        list: false
                    },
                    rentalData: {
                        ...state.rentalData,
                        list: action.payload.response
                    },
                };

            /** REQUEST CREATE **/

            case RentalAction.REQUEST_CREATE:
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        create: false
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        create: false
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        create: true
                    },
                    rentalData: {
                        ...state.rentalData,
                        create: false
                    },
                };
            case RentalAction.REQUEST_CREATE + '_FINISHED':

                return {
                    ...state,
                    rentalId: action?.payload?.response?.rental?.uuid,
                    rentalErrors: {
                        ...state.rentalErrors,
                        create: action.payload?.error,

                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        create: action.payload?.message
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        create: false
                    },
                    rentalData: {
                        ...state.rentalData,
                        create: action.payload?.response,
                        update: action.payload?.response
                    },
                };

            /** REQUEST UPDATE **/

            case RentalAction.REQUEST_UPDATE:
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        update: false
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        update: false
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        update: true
                    },
                    rentalData: {
                        ...state.rentalData,
                        update: false
                    },
                };
            case RentalAction.REQUEST_UPDATE + '_FINISHED':
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        update: action.payload?.error
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        update: action.payload?.message
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        update: false
                    },
                    rentalData: {
                        ...state.rentalData,
                        update: action.payload?.response
                    },
                };

            /** REQUEST UPDATE **/

            case RentalAction.REQUEST_DETAIL:
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        detail: false
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        detail: false
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        detail: true
                    },
                    rentalData: {
                        ...state.rentalData,
                        detail: false
                    },
                };
            case RentalAction.REQUEST_DETAIL + '_FINISHED':
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        detail: action.payload.error
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        detail: action.payload.message
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        detail: false
                    },
                    rentalData: {
                        ...state.rentalData,
                        detail: action.payload.response
                    },
                };

            /** REQUEST DELETE **/

            case RentalAction.REQUEST_DELETE:
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        delete: false
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        delete: false
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        delete: true
                    },
                    rentalData: {
                        ...state.rentalData,
                        delete: false
                    },
                };
            case RentalAction.REQUEST_DELETE + '_FINISHED':
                return {
                    ...state,
                    rentalErrors: {
                        ...state.rentalErrors,
                        delete: action.payload.error
                    },
                    rentalMessages: {
                        ...state.rentalMessages,
                        delete: action.payload.message
                    },
                    rentalPending: {
                        ...state.rentalPending,
                        delete: false
                    },
                    rentalData: {
                        ...state.rentalData,
                        delete: action.payload.response
                    },
                };

            default:
                return state;
        }
    }
}