const PartnerDeliveryLocationIsAvailable = (defaultLocation, availableLocations) => {

    let partnerDeliveryLocation = false


    availableLocations.forEach((location) => {
        if (location.id === defaultLocation) {
            partnerDeliveryLocation = location;
        }
    })

    return partnerDeliveryLocation.id

}

export default PartnerDeliveryLocationIsAvailable