import React from 'react'
import {connect} from "react-redux";
import Select, {components} from 'react-select';
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";
import CreatableSelect from 'react-select/creatable';
import Loading from "../../_components/Loading";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
    generalApi: state.generalApi,
    apiLocations: state.location,
});

const Option = props => {
    return (
        <div style={{color: props.data.color, backgroundColor: props.data.backgroundColour}}>
            <components.Option {...props} />
        </div>
    );
};

class HolidayAccommodation extends React.Component {

    constructor(props) {
        super(props)

        this._changeCustomDeliveryLocation = this._changeCustomDeliveryLocation.bind(this);

        this.state = {
            disableDeliverySelector: false,
            defaultLoadedDeliveryPickupLocation: false,
            defaultLoadedDeliveryPickupSubLocation: false
        }
    }


    componentDidUpdate() {

        if (!this.props.delivery.deliveryPickupLocation || !this.props.delivery.deliveryPickupSubLocation) {

            if (!this.state.defaultLoadedDeliveryPickupLocation) {
                const deliveryAddresses = this.props.deliveryLocations
                    ? Object.values(this.props.deliveryLocations)
                    : []
                if (deliveryAddresses?.length === 1) {
                    const deliveryAddress = deliveryAddresses[0]
                    this.setState({
                        disableDeliverySelector: !!this.props.webshop.delivery_address_insertion_disabled
                    })
                    this._changeDeliveryLocation(deliveryAddress)
                    this.setState({defaultLoadedDeliveryPickupLocation: true})
                }
            }

            if (!this.state.defaultLoadedDeliveryPickupSubLocation) {
                const deliveryPickupSubLocations = this.props.subDeliveryLocations
                    ? Object.values(this.props.subDeliveryLocations)
                    : []
                if (deliveryPickupSubLocations?.length === 1) {
                    const deliveryPickupSubLocation = deliveryPickupSubLocations[0]
                    this._changeSubDeliveryLocation(deliveryPickupSubLocation)
                    this.setState({defaultLoadedDeliveryPickupSubLocation: true})
                }

            }
        }
    }

    render() {

        const deliveryAddresses = this.props.deliveryLocations
        const cities = this.props.generalApi.cityData.list

        if (deliveryAddresses) {
            const deliveryLocation = this.props.delivery.deliveryPickupLocation
            let deliverySubLocations = this.props.subDeliveryLocations

            if (deliverySubLocations) {
                deliverySubLocations = deliverySubLocations.sort((a, b) => a.label.localeCompare(b.label))
            }

            let customButtonClass = 'c-custom-button btn btn--rounded';

            if (deliveryLocation.value !== 'custom') {
                customButtonClass += ' inactive';
            }

            const customData = {
                value: 'custom',
                label: Translate('Vul een ander adres in (bezorging niet mogelijk)', this.props.language),
                color: '#FF7700',
                backgroundColour: '#f3f6fa'
            }


            const filterOptions = (candidate, input) => {


                if (candidate.data.searchValue && input !== '') {

                    const searchValuesSplitted = candidate.data.searchValue.split(" ")
                    const inputSplitted = input.toLowerCase().split(" ")
                    let result = true

                    inputSplitted.forEach(input => {
                        const exist = searchValuesSplitted.some(searchValue => (searchValue.includes(input)))

                        if (!exist) {
                            result = false
                        }
                    })

                    return result

                } else {
                    return true;
                }

            };


            let options = Object.keys(deliveryAddresses).map((itemId) => {

                const item = deliveryAddresses[itemId]
                item.value = itemId

                if (item.address.name) {
                    item.label = item.address.name.trim()

                    if (item.address.street) {
                        item.label += ' - ' + item.address.street.trim()

                        if (item.address.number) {
                            item.label += ' ' + item.address.number.trim()

                        }
                    }

                } else {
                    item.label = item.address.street + ' ' + item.address.number
                }

                item.searchValue = ''

                if (item.address.name) {
                    item.searchValue += ' ' + item.address.name.trim()
                }

                if (item.address.street) {
                    item.searchValue += ' ' + item.address.street.trim()
                }

                if (item.address.number) {
                    item.searchValue += ' ' + item.address.number.trim()
                }

                if (item.address.zipcode) {
                    item.searchValue += ' ' + item.address.zipcode.trim()
                }

                if (item.label) {
                    item.searchValue += ' ' + item.label.trim()
                }

                if (item.items.length) {
                    item.items.forEach((subItem) => {


                        if (subItem.name) {
                            item.searchValue += ' ' + subItem.name.trim()
                        }

                        if (subItem.street || subItem.number) {
                            if (subItem.street && subItem.number) {
                                item.searchValue += ' ' + subItem.street.trim() + ' ' + subItem.number.trim()
                            } else if (subItem.street) {
                                item.searchValue += ' ' + subItem.street.trim()
                            } else {
                                item.searchValue += ' ' + subItem.number.trim()
                            }
                        }

                        if (subItem.type === 'range') {
                            const start = parseInt(subItem.range_start)
                            const end = parseInt(subItem.range_end)
                            let increaseNumber

                            if (subItem.range_side === 'ascending') {
                                increaseNumber = 1
                            } else {
                                increaseNumber = 2
                            }

                            for (let i = start; i < end; i += increaseNumber) {
                                item.searchValue += ' ' + i
                            }
                        }


                    })
                }

                item.searchValue = item.searchValue.trim().toLowerCase()

                return item

            })

            options = options.sort((a, b) => a.label.localeCompare(b.label))

            options.push(
                {
                    value: 'none',
                    label: Translate('Geen vakantieadres opgeven (bezorging niet mogelijk)', this.props.language),
                    color: '#FF7700',
                    backgroundColour: '#f3f6fa'
                },
            )


            if (!this.props.webshop.delivery_address_insertion_disabled) {
                options.push(customData)
            }


            return (
                <>


                    <header className="c-subject__header ">
                        <h2>{Translate('Selecteer uw vakantieverblijf', this.props.language)}</h2>

                        {this.props.webshop.type.code !== 'registration' && <>

                            {!!this.props.webshop.delivery_disabled &&
                                <p>
                                    {Translate('Met deze informatie kunnen we nauwkeurig uw dichtstbijzijnde ophaallocatie identificeren voor uw maximale gemak.', this.props.language)}
                                </p>
                            }

                            {!this.props.webshop.delivery_disabled &&
                            <p>
                                {Translate('Met deze informatie kunnen we nauwkeurig uw dichtstbijzijnde ophaallocatie identificeren voor uw maximale gemak.', this.props.language)}
                            </p>
                            }
                        </>
                        }

                    </header>


                    <div className="c-select-search">


                        <Select
                            placeholder={Translate('Zoek naar adressen', this.props.language)}
                            options={options}
                            components={{Option}}
                            onChange={(value) => this._changeDeliveryLocation(value)}
                            value={this.props.delivery.deliveryPickupLocation}
                            isDisabled={this.state.disableDeliverySelector}
                            filterOption={filterOptions}
                        />


                        {deliverySubLocations &&
                            <>
                                <div style={{marginTop: '2rem'}}>
                                    <Select
                                        placeholder={Translate('Selecteer specifiek adres', this.props.language)}
                                        options={deliverySubLocations}
                                        onChange={(value) => this._changeSubDeliveryLocation(value)}
                                        value={this.props.delivery.deliveryPickupSubLocation}
                                    />
                                </div>

                                {this.props.delivery.deliveryPickupSubLocation?.numbers?.length !== 1 &&
                                    <div style={{marginTop: '2rem'}}>
                                        <Select
                                            placeholder={Translate('Selecteer uw nummer', this.props.language)}
                                            options={this.props.delivery.deliveryPickupSubLocation.numbers}
                                            onChange={(value) => this._changeSubLocationNumber(value)}
                                            value={this.props.delivery.subLocationNumber}
                                        />
                                    </div>
                                }
                            </>
                        }


                        {this.props.delivery.deliveryPickupLocation?.value === 'custom' &&

                            <>

                                <div className="custom-row custom-row-1">
                                    <input type={'text'} className={'custom-location'}
                                           value={this.props.delivery.custom.street}
                                           onChange={(event) => this.props.dispatch(AppAction.setCustomLocationValue({street: event.target.value}))}
                                           placeholder={Translate('Straatnaam', this.props.language)}/>

                                    <input type={'text'} className={'custom-location'}
                                           value={this.props.delivery.custom.number}
                                           onChange={(event) => this.props.dispatch(AppAction.setCustomLocationValue({number: event.target.value}))}
                                           placeholder={Translate('Huisnr. + Toevoeging', this.props.language)}/>
                                </div>

                                <div className="custom-row custom-row-2">

                                    <input type={'text'} className={'custom-location'}
                                           value={this.props.delivery.custom.zip_code}
                                           onChange={(event) => this.props.dispatch(AppAction.setCustomLocationValue({zip_code: event.target.value}))}
                                           placeholder={Translate('Postcode', this.props.language)}/>

                                    <CreatableSelect
                                        isClearable
                                        placeholder={Translate('Selecteer plaats', this.props.language)}
                                        value={this.props.delivery.custom.city}
                                        formatCreateLabel={() => Translate('Voeg eigen plaats toe (druk enter)', this.props.language)}
                                        className={'custom-location custom-location-select'}
                                        onChange={(value) => this.props.dispatch(AppAction.setCustomLocationValue({city: value}))}
                                        options={cities}
                                    />

                                </div>
                            </>

                        }


                    </div>
                </>
            )
        } else {
            return (
                <div className={'c-loader'}>
                    <Loading/>
                </div>
            )
        }
    }

    _changeDeliveryLocation(location) {
        this.props.dispatch(AppAction.setDeliveryLocation(location)).then(() => {

            const selectedLocationData = this.props.deliveryLocations[this.props.delivery.deliveryPickupLocation.value];
            if (selectedLocationData && selectedLocationData.items.length) {
                this.props.dispatch(AppAction.formatSubDeliveryLocations(selectedLocationData.items))
            }
        })
    }

    _changeSubDeliveryLocation(location) {
        this.props.dispatch(AppAction.setSubDeliveryLocation(location)).then(() => {
            if (this.props.delivery.deliveryPickupSubLocation.numbers.length === 1) {

                this._changeSubLocationNumber(this.props.delivery.deliveryPickupSubLocation.numbers[0].value)
            }
        })
    }

    _changeSubLocationNumber(number) {
        this.props.dispatch(AppAction.setSubLocationNumber(number))
    }

    _changeCustomDeliveryLocation(event) {

        const deliveryLocation = this.props.delivery.deliveryPickupLocation
        deliveryLocation.customLocationInput = event.target.value
    }

}

export default connect(mapStateToProps)

(
    HolidayAccommodation
)
;