import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import RouteEnum from "../../constants/RouteEnum";
import Translate from "../../utilities/etc/Translate";
import InfoAction from "../../stores/api/info/InfoAction";
import GeneralAction from "../../stores/api/general/GeneralAction";
import CategoryAction from "../../stores/api/category/CategoryAction";
import SetAndValidateItems from "../../utilities/dispatchers/SetAndValidateItems";
import SkipAccessoireSelection from "../../utilities/etc/SkipAccessoireSelection";
import ShoppingCartAction from "../../stores/shoppingCart/ShoppingCartAction";

const mapStateToProps = (state) => ({
    ...state.app,
    rentalNotation: state.info.infoData.detail?.webshops?.rental_notation

});


class Header extends React.Component {

    render() {
        const usps = this.props.webshop.usps
        const image = this.props.webshop.image

        let flagCode = this.props.language
        if (this.props.language === 'en') {
            flagCode = 'gb'
        }

        const countryFlagSrc = '/images/' + flagCode + '.png'

        const languages = [
            {
                key: 'nl',
                value: 'Nederlands'
            },
            {
                key: 'de',
                value: 'Deutsch'
            },
            {
                key: 'en',
                value: 'English'
            },
            {
                key: 'fr',
                value: 'français'
            },
            {
                key: 'es',
                value: 'Español'
            },
            {
                key: 'it',
                value: 'Italiano'
            },
            {
                key: 'pt',
                value: 'Português'
            },
        ]

        if (this.props.webshop) {


            let selectClass = 'c-lang-selector'
            if (!this.props.setAndValidationComplete) {
                selectClass += ' is-disabled'
            }

            return (
                <>
                    <style dangerouslySetInnerHTML={{
                        __html: '.rdrNextButton { background: ' + this.props.webshop.styles.dark.background + ' !important}' +
                            '.rdrPprevButton { background: ' + this.props.webshop.styles.dark.background + ' !important}' +
                            '.rdrMonthAndYearWrapper {border-color: ' + this.props.webshop.styles.dark.background + ' !important}' +
                            '.rdrMonth  { background: ' + this.props.webshop.styles.light.background + ' !important}' +
                            '.c-time-picker__content  { background: ' + this.props.webshop.styles.light.background + ' !important}' +
                            '.c-time-picker__content__amount__picker button {background: ' + this.props.webshop.styles.dark.background + ' !important}' +
                            '.rdrCalendarWrapper  { background: ' + this.props.webshop.styles.light.background + ' !important}' +
                            '.rdrEndEdge, .rdrInRange, .rdrStartEdge {color: ' + this.props.webshop.styles.dark.background + ' !important} ' +
                            '.c-sticky-footer .btn {background: ' + this.props.webshop.primary_button_color + ' !important}'
                    }}/>

                    <header className="c-header">
                        <nav className="c-header__primary">

                            {this.props.step !== this.props.firstStep && this.props.step !== RouteEnum.Thanks && !this.props.registerSubmitted &&
                            <button onClick={() => this._clickBack()}
                                    className="btn btn--icon btn--outline btn--rounded">
                                <i className="icon icon-arrow-right"/>
                                <span>{Translate('Terug', this.props.language)}</span>
                            </button>
                            }

                            <img className="c-header__logo" src={image} alt={'logo'}/>


                            <div className={selectClass}>
                                <img src={countryFlagSrc} style={{height: '2rem'}} alt={'country flag'}/>
                                <i className="icon icon-arrow-down"/>
                                <select onChange={(e) => this._changeLanguage(e)}>
                                    {languages.map((language) => {

                                        const selected = this.props.language === language.key

                                        return (
                                            <option selected={selected} key={'language-' + language.key}
                                                    value={language.key}
                                            >{language.value}</option>
                                        )
                                    })}
                                </select>
                            </div>

                        </nav>

                        {this.props.webshop.type.code !== 'registration' &&
                        <div className="c-header__usp" style={this.props.webshop.styles.dark}>
                            {usps &&
                            <ul className="u-reset-ul">
                                {usps.map((usp, i) => (
                                    <li key={i + '-usp'}>
                                        <img alt='usp-check-icon' src='/images/check-icon-circle.svg'/>
                                        <span>{usp.content}</span>
                                    </li>
                                ))}
                            </ul>
                            }
                        </div>
                        }

                        {this.props.webshop.type.code === 'registration' && this.props.webshop.name &&
                        <div className="c-header__usp" style={this.props.webshop.styles.dark}>
                            <p>{Translate('Voorregistratie', this.props.language)} {this.props.webshop.name}</p>
                        </div>
                        }

                    </header>
                </>
            )
        } else return null
    }

    _changeLanguage(event) {

        this.props.dispatch(AppAction.changeLanguage(event.target.value)).then(() => {

                //SetAndValidateItems(this.props)

                this.props.dispatch(InfoAction.detail()).then(() => {
                    this.props.dispatch(GeneralAction.languageList())

                    this.props.dispatch(AppAction.formatWebshop()).then(() => {

                        this.props.dispatch(CategoryAction.list({
                            partnerId: this.props.webshop.partner_id,
                            type: 'accessoire',
                            language: this.props.language
                        }))

                    })
                })

                ShoppingCartAction.update()
            }
        )
    }

    _clickBack() {

        let minusStep = 1

        if (SkipAccessoireSelection(this.props) && this.props.step === 4) {
            minusStep = 2
        }

        if (this.props.webshop.type.code === 'registration' && this.props.step === 4) {
            minusStep = 3
        }

        this.props.dispatch(AppAction.switchAppStep(this.props.step - minusStep))
    }
}

export default connect(mapStateToProps)(Header);
