import ActionUtility from '../../../utilities/etc/ActionUtility'
import AvailabilityEffect from "./AvailabilityEffect";
import moment from "moment";

export default class AvailabilityAction {
    static REQUEST_PER_CATEGORY = 'AvailabilityAction.REQUEST_PER_CATEGORY';
    static REQUEST_PER_DAY = 'AvailabilityAction.REQUEST_PER_DAY';
    static VALIDATE_DELIVERY_BY_ADDRESS = 'AvailabilityAction.VALIDATE_DELIVERY_BY_ADDRESS';

    static perCategory(values) {
        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, AvailabilityAction.REQUEST_PER_CATEGORY, AvailabilityEffect.requestPerCategory, values);
        };
    }

    static perDay(values) {
        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, AvailabilityAction.REQUEST_PER_DAY, AvailabilityEffect.requestPerDay, values);
        };
    }

    static validateDeliveryByAddress() {
        return async (dispatch, getState) => {

            const categories = [...getState().shoppingCart.bicycles, ...getState().shoppingCart.accessoires]

            const mappedCategories = categories.map((category) => {
                return {
                    category_id: category.id,
                    quantity: category.selected
                }
            })

            const values = {
                deliveryAddressId: getState().app.delivery?.deliveryPickupLocation?.address?.id,
                startDate: moment(getState().app.period.startDate).format('YYYY-MM-DD') + ' ' + getState().app.startTime,
                endDate: moment(getState().app.period.endDate).format('YYYY-MM-DD') + ' ' + getState().app.endTime,
                categories: mappedCategories
            }

            await ActionUtility.createThunkEffect(dispatch, AvailabilityAction.VALIDATE_DELIVERY_BY_ADDRESS, AvailabilityEffect.validateDeliveryByAddress, values);
        };
    }


}

