import ApiEnum from '../../../constants/ApiEnum'

import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";
import AppAction from "../../app/AppAction";

export default class RentalEffect {

    static async requestList(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopRental + '/list' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                response: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestUpdate(args) {

        return await AppAxios.post(ApiEnum.ApiWebshopRental + '/update?uuid=' + args.values.id, {
            ...args.values
        }, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            if (response.data.status.code !== 400) {
                return {
                    response: response.data.items
                }
            } else {
                args.dispatch(AppAction.reset({
                    message: 'Het is niet gelukt een boeking aan te maken. Probeer het opnieuw.'
                }))
            }


        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestCreate(args) {

        return await AppAxios.post(ApiEnum.ApiWebshopRental + '/create', {
            ...args.values
        }, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            if (response.data.status.code !== 400) {
                return {
                    response: response.data.items
                }
            }

            if (response.data.status.code === 400) {
                args.dispatch(AppAction.reset({
                    message: 'Het is niet gelukt een boeking aan te maken. Probeer het opnieuw.'
                }))
            }


        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestDetail(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopRental + '/detail/' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                response: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }


    static async requestDelete(values) {

        return await AppAxios.get(ApiEnum.ApiWebshopRental + '/delete/' + values.id, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                response: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }

}

