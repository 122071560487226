import ApiEnum from '../../../constants/ApiEnum'

import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";

export default class CategoryEffect {

    static async requestList(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopCategory + '/list/' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {
                return {
                    items: response.data.items
                }
            }).catch((error) => {
                console.log(error)
            })

    }

}

