import AuthAction from './AuthAction'

export default class AuthReducer {

    static initialState = {
        accessToken: false,
        authData: false,
        authError: false,
        authmessage: false,
        authPending: false,
    };

    static reducer(state = AuthReducer.initialState, action) {


        switch (action.type) {
            case AuthAction.REQUEST_CONNECT:
                return {
                    ...state,
                    authData: false,
                    authError: false,
                    authmessage: false,
                    authPending: true
                };
            case AuthAction.REQUEST_CONNECT + '_FINISHED':
                return {
                    ...state,
                    accessToken: action.payload.response.accessToken.access_token,
                    authData: action.payload.response,
                    authError: action.payload.error,
                    authmessage: action.payload.message,
                    authPending: false
                };

            case AuthAction.REQUEST_REFRESH:
                return {
                    ...state,
                    authData: false,
                    authError: false,
                    authmessage: false,
                    authPending: true
                };
            case AuthAction.REQUEST_REFRESH + '_FINISHED':
                return {
                    ...state,
                    authData: action.payload.response,
                    authError: action.payload.error,
                    authmessage: action.payload.message,
                    authPending: false
                };
            default:
                return state;
        }
    }
}
