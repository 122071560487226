const FormatCartToInsuranceRequest = (shoppingcart) => {

    const categories = []

    if (shoppingcart.bicycles.length) {

        shoppingcart.bicycles.forEach((bicycle) => {
            const bicycleCategory = {id: bicycle.id, quantity: bicycle.selected}
            categories.push(bicycleCategory)
        })

        shoppingcart.accessoires.forEach((accessoire) => {
            const accessoireCategory = {id: accessoire.id, quantity: accessoire.selected}
            categories.push(accessoireCategory)
        })
    }

    return categories

}

export default FormatCartToInsuranceRequest
