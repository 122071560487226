const AccessoireConditions = (itemId, categories, shoppingCart) => {
    console.log(itemId)

    const data = {}
    data.formatAccessoire = true



    const categoryCondition = Object.values(categories).find((category) => {
        return category.info.id === parseInt(itemId)
    })


    if (categories && categoryCondition && categoryCondition.conditions && categoryCondition.conditions.condition_type && categoryCondition.conditions.condition_type.code === 'one_on_one') {


        const conditionItems = Object.values(categoryCondition.conditions.condition_items)

        if (conditionItems.length) {

            // Accessoire by default should not be added to the formatter in case its one on one
            data.maxConditionSelection = 0
            data.formatAccessoire = false

            conditionItems.forEach((conditionItem) => {
                const bicycle = shoppingCart.bicycles.filter((bicycle) => {
                    return bicycle.id === conditionItem.id
                })[0]

                if (bicycle) {

                    // If a single bicycle is found the accessoire can be added to the formatter
                    data.formatAccessoire = true
                    data.maxConditionSelection += bicycle.selected


                }

            })

        } else {

            // Accessoire by default should not be added to the formatter in case its one on one
            data.maxConditionSelection = 0
            data.formatAccessoire = false

            shoppingCart.bicycles.forEach((bicycle) => {
                data.formatAccessoire = true
                data.maxConditionSelection += bicycle.selected
            })

        }

    }

    return data

}

export default AccessoireConditions