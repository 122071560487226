import ApiEnum from '../../../constants/ApiEnum'

import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";

export default class CategoryEffect {

    static async requestCountryList(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiGeneral + '/country/list' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {
                return {
                    response: response.data.items
                }
            }).catch((error) => {
                console.log(error)
            })

    }

    static async requestCityList(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiGeneral + '/city/list' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {

                const items = response.data.items.cities.map((city) => {
                    city.value = city.id
                    city.label = city.name
                    return city
                })

                return {
                    response: items
                }
            }).catch((error) => {
                console.log(error)
            })

    }

}


