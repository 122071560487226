const RouteEnum = {
    Landing: 0,
    Period: 1,
    Bicycles: 2,
    Accessoires: 3,
    Accommodation: 4,
    Form: 5,
    PaymentOverview: 6,
    Thanks: 7
}

export default RouteEnum;