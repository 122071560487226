import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import AuthReducer from './api/auth/AuthReducer'
import AddressReducer from './api/address/AddressReducer'
import AvailabilityReducer from "./api/availability/AvailabilityReducer";
import CategoryReducer from "./api/category/CategoryReducer";
import InfoReducer from "./api/info/InfoReducer";
import LocationReducer from "./api/location/LocationReducer";
import PartnerReducer from "./api/partner/PartnerReducer";
import PaymentReducer from "./api/payment/PaymentReducer";
import RentalReducer from "./api/rental/RentalReducer";
import AppReducer from "./app/AppReducer";
import ShoppingCartReducer from "./shoppingCart/ShoppingCartReducer";
import GeneralReducer from "./api/general/GeneralReducer";
import InsuranceReducer from "./api/insurance/InsuranceReducer";
import PeriodSlice, {periodReducer} from "../views/Period/PeriodSlice";

export default (history) => {

    const reducerMap = {
        app: AppReducer.reducer,
        shoppingCart: ShoppingCartReducer.reducer,
        router: connectRouter(history),
        auth: AuthReducer.reducer,
        address: AddressReducer.reducer,
        availability: AvailabilityReducer.reducer,
        category: CategoryReducer.reducer,
        info: InfoReducer.reducer,
        insurance: InsuranceReducer.reducer,
        location: LocationReducer.reducer,
        partner: PartnerReducer.reducer,
        payment: PaymentReducer.reducer,
        rental: RentalReducer.reducer,
        generalApi: GeneralReducer.reducer,
        period: periodReducer
    };

    return combineReducers(reducerMap);
};
