import React, {useEffect, useState} from 'react'

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRange} from 'react-date-range';
import * as rdrLocales from "date-fns/esm/locale";
import {useDispatch} from "react-redux";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import {useAppSelector} from "../../../stores/hooks";
import Translate from "../../../utilities/etc/Translate";
import {
    addTimeUnit,
    editStartTime,
} from "../PeriodSlice";



export const Period = (props) => {

    const dispatch = useDispatch()
    const app = useAppSelector(state => state.app)

    const {
        timeUnits,
        chosenUnit,
        timeAmount,
        numberAmount,
        stepAmount,
        periodRange,
        startTime,
        calculation
    } = useAppSelector(state => state.period);




    if (timeUnits && timeUnits.length > 0) {

        return (
            <div className="o-container o-container--primary">


                {!!chosenUnit &&
                <>
                    <div className="s-date-time-picker">

                        <div className="c-date-picker" key={'period-view'}>

                            <div className="c-date-picker__info">
                                <h5>{Translate('Selecteer start- en einddatum', app.language)}</h5>
                            </div>

                            <DateRange
                                locale={rdrLocales[app.language]}
                                editableDateInputs={true}
                                onChange={item => props.changePeriod(item.selection)}
                                ranges={[periodRange]}
                                direction="horizontal"
                                dragSelectionEnabled={false}
                                showPreview={false}
                                weekStartsOn={1}
                                isOutsideRange={() => false}
                                minDate={props.minDate}
                                minimumNights={0}
                            />
                        </div>


                        <div className="c-time-picker">

                            <div className="c-time-picker__info">
                                <h5>{Translate('Kies de hoeveelheid tijd en starttijd', app.language)}</h5>
                            </div>

                            <div className="c-time-picker__content" style={app.webshop.light}>

                                <div className="c-time-picker__content__start-date">
                                    <h5>{Translate('Start datum', app.language)}</h5>
                                    <span>{moment(periodRange?.startDate).format('dddd D MMMM')}</span>
                                </div>

                                <div className="c-time-picker__content__amount">
                                    <h5>{Translate('Hoelang', app.language)}
                                        <span>({Translate('minimum van', app.language)} {chosenUnit.min} {Translate(chosenUnit.type.name, app.language)})</span>
                                    </h5>
                                    <div className="c-time-picker__content__amount__picker">
                                        {chosenUnit.type.code === 'quarters' ? (
                                            <>
                                                {stepAmount > chosenUnit.min_amount &&
                                                <button className="min" onClick={() => props.timeStepDown()}>-</button>
                                                }
                                                <input type="time" id={'time-input'} step={props.quarterSteps}
                                                       value={timeAmount}/>
                                                {stepAmount < chosenUnit.max_amount &&
                                                <button className="plus" onClick={() => props.timeStepUp()}
                                                        disabled={props.amountButtonDisabled}>+</button>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {stepAmount > chosenUnit.min_amount &&
                                                <button className="min"
                                                        onClick={() => props.numberStepDown()}>-</button>
                                                }
                                                <input type="number" id={'number-input'} step={1 * chosenUnit.amount}
                                                       value={numberAmount}/>
                                                {stepAmount < chosenUnit.max_amount &&
                                                <button className="plus" onClick={() => props.numberStepUp()}
                                                        disabled={props.amountButtonDisabled}>+</button>
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>

                                {!!chosenUnit.time_required &&
                                <div className="c-time-picker__content__start-time">
                                    <h5>{Translate('Starttijd ', app.language)}
                                        <span>{Translate('Selecteer jouw starttijd', app.language)}</span></h5>
                                    <div className="c-time-picker__content__start-time__picker">
                                        <TimePicker
                                            value={moment('01-01-2020 ' + startTime, 'DD-MM-YYYY HH:mm')}
                                            disabled={app.bookingPhase === 'note'}
                                            disabledHours={() => props.disabledHours}
                                            disabledMinutes={() => props.disabledQuarters}
                                            allowEmpty={false}
                                            minimumDate={new Date()}
                                            onChange={value => {
                                                dispatch(editStartTime(value.format("HH:mm")))
                                            }}
                                            showSecond={false} minuteStep={15}
                                        />
                                    </div>
                                </div>
                                }

                                {!!chosenUnit.time_required ? (
                                    <div className="c-time-picker__content__end-time">
                                        <h5>{Translate('Eindtijd', app.language)}</h5>
                                        {!!calculation &&
                                        <span>{moment(calculation.end_date).format('dddd DD MMMM')} - {moment('01-01-2020 ' + calculation.end_time, 'DD-MM-YYYY HH:mm').format('HH:mm')}</span>
                                        }
                                    </div>
                                ) : (
                                    <div className="c-time-picker__content__end-time">
                                        <h5>{Translate('Einddatum', app.language)}</h5>
                                        {!!calculation &&
                                        <span>{moment(calculation.end_date).format('dddd DD MMMM')}</span>
                                        }
                                    </div>
                                )}

                            </div>

                        </div>

                    </div>


                    <div className="c-date-picker" key={'period-view'}>

                        {periodRange.startDate && periodRange.endDate && periodRange.days > 0 && startTime && calculation && calculation.end_date && (!app.openedLocations || app.openedLocations.length === 0) &&
                        <div className="c-notification c-notification--error">
                            <i className="icon icon-info"/>
                            <span>{Translate('Er zijn geen locaties geopend op de geselecteerde startdatum', app.language)}</span>
                        </div>
                        }

                        {(periodRange.days > 0 && periodRange.endDate && !!app.openedLocations.length && props.showNextButton) &&
                        <>
                            {app.webshop.type.code !== 'registration' &&
                            <button onClick={() => props.clickBicycleSelect()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('*item_plural_cap* selecteren', app.language)}</span>
                            </button>
                            }

                            {app.webshop.type.code === 'registration' &&
                            <button onClick={() => props.clickAccommodation()} style={app.webshop.styles.dark}
                                    className="c-date-picker__button btn btn--rounded">
                                <span>{Translate('Doorgaan', app.language)}</span>
                            </button>
                            }
                        </>
                        }
                    </div>
                </>
                }

            </div>
        )
    } else {
        return (
            <div className="o-container o-container--primary">
                <h5 style={{margin: '4rem 0'}}>{Translate('Er zijn geen verhuur periode beschikbaar.', app.language)}</h5>
            </div>
        )
    }

}


export default Period;
