import ApiEnum from "../../../constants/ApiEnum";

export default class InsurancesEffect {

    static setInsurance(value) {
        return parseInt(value)
    }

    static async list(values) {

        return await fetch(ApiEnum.ApiWebshopInsurance, {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
            body: JSON.stringify({...values})
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200) {
                return {
                    response: response.items,
                }
            } else {

                return {
                    error: true,
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })

    }

}
