import React from 'react'
import {connect} from "react-redux";
import ReactMapboxGl, {Marker} from 'react-mapbox-gl';
import AppAction from "../../../../../stores/app/AppAction";
import CalculateMapBounds from "../../../../../utilities/etc/CalculateMapBounds";

const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiaWRvdHdlYmVuZ2luZWVycyIsImEiOiJja2Zucnp2Z2Yxbm93MzBxaDNrNDh1cGZ0In0.sUN7_6eNtALA2jGJ6PgpBQ'
});

const mapStateToProps = (state) => ({
    ...state.app
});

class DeliveryMap extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hideLocationSelector: false
        }
    }

    componentDidMount() {

        if (this.props.deliveryComponent) {
            this.setState({
                hideLocationSelector: true
            })
        }

    }

    render() {

        const locations = Object.values(this.props.locations)


        if (!this.state.hideLocationSelector) {


            return (
                <div className="c-delivery-options__store__map">

                    <Map
                        // eslint-disable-next-line
                        style="mapbox://styles/idotwebengineers/ckfnsaisf3jtt19l20ndd0bmh"
                        fitBounds={CalculateMapBounds(locations)}
                        containerStyle={{
                            height: '100%',
                        }}

                    >
                        {locations.filter(location => this._filterLocations(location)).map((location, index) => {
                            let mainClass = 'c-marker'

                            if (this.props.delivery.partnerLocationId === location.id) {
                                mainClass = mainClass + ' is-selected';
                            }


                            return (
                                <Marker coordinates={[location.long, location.lat]} anchor="bottom"
                                        key={'marker-' + index}>
                                    <div onClick={() => this._onClickMarker(location.id)}
                                         className={mainClass}>{index + 1}</div>
                                </Marker>
                            )

                        })}


                    </Map>
                </div>
            )

        } else {

            return null

        }

    }

    _onClickMarker(partnerLocationId) {
        this.props.dispatch(AppAction.setPartnerLocation(partnerLocationId))
    }

    _filterLocations(location) {
        return !!this.props.openedLocations.find((locationId) => locationId === location.id) && !location.excluded
    }


}

export default connect(mapStateToProps)(DeliveryMap);