import AvailabilityAction from "../../stores/api/availability/AvailabilityAction";
import moment from "moment";
import AppAction from "../../stores/app/AppAction";

const requestBicycles = (props) => {
    const dispatchData = {
        webshopId: props.webshop.id,
        startDate: moment(props.period.startDate).format('YYYY-MM-DD'),
        endDate: moment(props.period.endDate).format('YYYY-MM-DD'),
        language: props.language,
        timeUnitId: props.timeUnitId
    }

    if (props.rentalId) {
        dispatchData.rentalId = props.rentalId
    }

    if (props.startTime && props.endTime) {
        dispatchData.startDate += ' ' + props.startTime
        dispatchData.endDate += ' ' + props.endTime
    }

    props.dispatch(AvailabilityAction.perCategory(dispatchData)).then(() => {

        props.dispatch(AppAction.formatBicycles()).then(() => {
            props.dispatch(AppAction.setBicycleAvailability())

            props.dispatch(AppAction.validateShoppingCart()).then(() => {

                props.dispatch(AppAction.setAndValidationComplete(true)).then(() => {
                    validation(props)
                })

            })

        })
    })
}

const requestAccessoires = (props) => {

    const dispatchData = {
        webshopId: props.webshop.id,
        startDate: moment(props.period.startDate).format('YYYY-MM-DD') + ' ' + props.startTime,
        endDate: moment(props.period.endDate).format('YYYY-MM-DD') + ' ' + props.endTime,
        language: props.language,
        timeUnitId: props.timeUnitId,
        type: 'accessoire',
    }

    if (props.rentalId) {
        dispatchData.rentalId = props.rentalId
    }

    props.dispatch(AvailabilityAction.perCategory(dispatchData)).then(() => {

        props.dispatch(AppAction.formatAccessoires()).then(() => {

            props.dispatch(AppAction.setBicycleAvailability()).then(() => {
                validation(props)
            })

        })
    })

}

const validation = (props) => {
    props.dispatch(AppAction.validateShoppingCart()).then(() => {
        props.dispatch(AppAction.setAndValidationComplete(true))
    })
}

const SetAndValidateItems = (props, type = 'all') => {

    props.dispatch(AppAction.setAndValidationComplete(false))

    if (type === 'bicycle') {
        requestBicycles(props)
    }

    if (type === 'accessoire') {
        requestAccessoires(props)
    }

    if (type === 'all') {
        requestBicycles(props)
        requestAccessoires(props)
    }

}

export default SetAndValidateItems
