import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state.app,
});

const changeAccommodation = (props) => {

    props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation)).then(() => {
        props.dispatch(AppAction.setSummary({
            period: true,
            bicycles: true,
            accessoires: true,
            accommodation: false,
            delivery: false,
        }))
    })
}


const SummaryAccommodation = (props) => {
    const deliveryPickupLocation = props.delivery.deliveryPickupLocation

    if (deliveryPickupLocation && deliveryPickupLocation.value !== 'none') {

        return (
            <div className="c-summary c-summary--text">
                <i className="c-summary__check icon icon-check-circle"/>
                <div className="c-summary__subject__header">
                    <h5>{Translate('Vakantieverblijf', props.language)}</h5>
                    <button onClick={() => changeAccommodation(props)}
                            className="c-summary__edit">
                        <span>{Translate('Wijzigen', props.language)}</span>
                        <i className="icon icon-edit"/>
                    </button>
                </div>
                {deliveryPickupLocation.value !== 'custom' &&
                    <div className="c-summary--period__text">
                        <span>{deliveryPickupLocation.label} </span>
                    </div>
                }
                {deliveryPickupLocation.value === 'custom' &&
                    <div className="c-summary--period__text">
                        <span>{props.delivery.custom.street} {props.delivery.custom.number} {props.delivery.custom.zip_code} {props.delivery.custom.city.label} </span>
                    </div>
                }
            </div>
        )
    } else {
        return null
    }

}

export default connect(mapStateToProps)(SummaryAccommodation);