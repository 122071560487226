import moment from "moment";
import FormatCartToRetailRequest from "../formatters/FormatCartToRetailRequest";
import RentalAction from '../../stores/api/rental/RentalAction'
import AppAction from "../../stores/app/AppAction";

const CreateOrUpdateRental = (props) => {

    const deliveryData = props.delivery

    let pickup = deliveryData.type === 'delivery';
    let delivery = deliveryData.type === 'delivery'

    let discountPercentage = 0;

    if (props.periodDiscount) {
        discountPercentage = props.periodDiscount
    } else {

        if (props.webshop.default_discount && props.webshop.discount_percentage) {
            discountPercentage = props.webshop.discount_percentage
        } else {
            if (props.delivery.deliveryPickupLocation && props.delivery.deliveryPickupLocation.address && props.delivery.deliveryPickupLocation.address.discount_percentage) {
                discountPercentage = props.delivery.deliveryPickupLocation.address.discount_percentage
            }
        }
    }


    const customer = props.form.data.customer

    customer.document_type = props.form.data.document_type
    customer.document_number = props.form.data.document_number

    const data = {
        webshop_id: props.webshop.id,
        partner_id: props.webshop.partner_id,
        language: props.language,
        time_unit_id: props.timeUnitId,
        time_rent_start: props.startTime,
        time_unit_amount: props.timeAmount,
        partner_location_id: props.delivery.partnerLocationId,
        pick_up: pickup,
        delivery: delivery,
        deliveryAddress: deliveryData.deliveryPickupLocation.address,
        pickupAddress: deliveryData.deliveryPickupLocation.address,
        date_rent_start: moment(props.period.startDate).format('YYYY-MM-DD'),
        date_rent_end: moment(props.period.endDate).format('YYYY-MM-DD'),
        categories: FormatCartToRetailRequest(props.cart),
        type: props.form.data.type,
        insurance_id: props.insurance.selected,
        customer: customer,
        address: props.form.data.address,
        remarks: props.form.data.remarks,
        discount_percentage: discountPercentage
    }


    // Set delivery sub locations
    if (deliveryData.deliveryPickupLocation.value === 'custom') {

        data.deliveryAddress = {
            street: deliveryData.custom.street,
            number: deliveryData.custom.number,
            zip_code: deliveryData.custom.zip_code,
        }

        if (deliveryData.custom.city['__isNew__']) {
            data.deliveryAddress.city = deliveryData.custom.city.value
        } else {
            data.deliveryAddress.city_id = deliveryData.custom.city.value
        }

    } else if (deliveryData.deliveryPickupSubLocation) {
        data.delivery_address_item_id = deliveryData.deliveryPickupSubLocation.id
        data.delivery_address_item_number = deliveryData.subLocationNumber.value
    }

    if (deliveryData.deliveryPickupLocation.value === 'custom') {

        data.pickupAddress = {
            street: deliveryData.custom.street,
            number: deliveryData.custom.number,
            zip_code: deliveryData.custom.zip_code,
        }

        if (deliveryData.custom.city['__isNew__']) {
            data.pickupAddress.city = deliveryData.custom.city.value
        } else {
            data.pickupAddress.city_id = deliveryData.custom.city.value
        }

    } else if (deliveryData.deliveryPickupSubLocation) {
        data.pickup_address_item_id = deliveryData.deliveryPickupSubLocation.id
        data.pickup_address_item_number = deliveryData.subLocationNumber.value
    }


    if (data.type === 'private') {
        data.private = props.form.data.privatePerson

        if (props.webshop.recurring_enabled) {
            data.secondary_private = props.form.data.secondaryPrivatePerson
        }

    } else {
        data.company = props.form.data.company
        data.contactPerson = props.form.data.contactPerson
    }


    if (props.webshop.type.code === 'registration') {
        data.phase = 'registration'
        data.type = 'private'
        data.private = props.form.data.privatePerson
    }

    if (props.rentalId) {
        data.id = props.rentalId
        props.dispatch(RentalAction.update(data))
    } else {
        props.dispatch(RentalAction.create(data)).then(() => {
            props.dispatch(AppAction.setRentalId())
        })
    }

}

export default CreateOrUpdateRental
