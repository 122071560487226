import AppAction from "./AppAction";
import moment from 'moment';
import MapLocationAvailability from "../../utilities/formatters/MapLocationAvailability";


export default class AppReducer {

    static initialState = {
        language: 'nl',
        slug: '',
        wizard: 1,
        step: 1,
        firstStep: 1,
        webshop: false,
        locations: false,
        deliveryLocations: false,
        subDeliveryLocations: false,
        excludedLocations: {},
        bicycles: {},
        formatBicyclesComplete: false,
        availabilityComplete: false,
        accessoires: {},
        formatAccessoiresComplete: false,
        setAndValidationComplete: true,
        rentalId: false,
        openedLocations: [],
        validation: {
            bicycles: true,
            accessoires: true,
            valid: true,
        },
        validationComplete: false,
        summary: {
            period: false,
            bicycles: false,
            accessoires: false,
            accommodation: false,
            delivery: false
        },
        period: false,
        periodDiscount: 0,
        startTime: '15:15',
        endTime: '15:15',
        time: moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'),
        timeUnitId: null,
        timeUnit: null,
        timeAmount: 0,
        delivery: {
            deliveryPickupLocation: false,
            deliveryPickupSubLocation: false,
            subLocationNumber: false,
            partnerLocationId: false,
            type: false,
            custom: {
                street: null,
                number: null,
                zip_code: null,
                city: null,
                isCityId: false,
            }
        },
        form: {
            completed: false,
            data: {
                newCustomer: null,
                type: '',
                customer: {
                    remarks: null
                },
                contactPersons: null,
                address: {
                    street: null,
                    house_number: null,
                    house_number_addition: null,
                    city: null,
                    country_id: 1,
                    zip_code: null
                },
                company: {
                    id: null,
                    name: null,
                    coc_number: null,
                    vat_number: null,
                    primary_phone: null,
                    primary_email: null,
                    website: null
                },
                contactPerson: {
                    id: null,
                    first_name: null,
                    insertion: null,
                    last_name: null,
                    phone: null,
                    email: null,
                    initials: null,
                },
                privatePerson: {
                    id: null,
                    initials: null,
                    first_name: null,
                    last_name: null,
                    gender_id: 1,
                    insertion: null,
                    telephone: null,
                    birth_date: null,
                    email: null
                },
                secondaryPrivatePerson: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    birth_date: null,
                    document_number: null,
                    document_type: 'passport',
                    zipcode: null,
                    house_number: null
                },
                document_number: null,
                document_type: 'passport',
                remarks: null
            }
        },
        payment: {
            data: false
        },
        popup: {
            bicycle: {
                show: false,
                id: false
            },
            accessoire: {
                show: false,
                id: false
            },
            location: {
                show: false,
                id: false
            },
            accessoireCondition: {
                show: false,
                id: false
            },
            insurance: {
                show: false,
                title: false,
                text: false,
                additional_terms: false
            }
        },
        registerSubmitted: false,
        toggleReset: false,
        resetMessage: ''
    };

    static reducer(state = AppReducer.initialState, action) {


        switch (action.type) {

            case AppAction.SWITCH_WIZARD_STEP:
                return {
                    ...state,
                };
            case AppAction.SWITCH_WIZARD_STEP + '_FINISHED':
                return {
                    ...state,
                    wizard: action.payload,
                };

            case AppAction.SWITCH_STEP:

                return {
                    ...state,
                };

            case AppAction.SWITCH_STEP + '_FINISHED':

                return {
                    ...state,
                    step: action.payload,
                };

            case AppAction.SET_SLUG:
                return {
                    ...state,
                };
            case AppAction.SET_SLUG + '_FINISHED':
                return {
                    ...state,
                    slug: action.payload,
                };

            case AppAction.SET_PERIOD:
                return {
                    ...state,
                };
            case AppAction.SET_PERIOD + '_FINISHED':
                return {
                    ...state,
                    period: action.payload,
                    excludedLocations: {}
                };
            case AppAction.SET_TIME + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        time: action.payload,
                    };
                } else {
                    return {
                        ...state,
                        time: false,
                    };
                }
            case AppAction.SET_START_TIME + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        startTime: action.payload,
                    };
                } else {
                    return {
                        ...state,
                        startTime: false,
                    };
                }
            case AppAction.SET_END_TIME + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        endTime: action.payload,
                    };
                } else {
                    return {
                        ...state,
                        endTime: false,
                    };
                }
            case AppAction.SET_TIME_UNIT_ID + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        timeUnitId: action.payload,
                    }
                }
            case AppAction.SET_TIME_UNIT + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        timeUnit: action.payload,
                    }
                }
            case AppAction.SET_TIME_AMOUNT + '_FINISHED':
                if (action.payload) {
                    return {
                        ...state,
                        timeAmount: action.payload
                    }
                }
            case AppAction.SET_RENTAL_ID:
                return {
                    ...state,
                };
            case AppAction.SET_RENTAL_ID + '_FINISHED':
                return {
                    ...state,
                    rentalId: action.payload,
                };

            case AppAction.SET_SUMMARY:
                return {
                    ...state,
                };
            case AppAction.SET_SUMMARY + '_FINISHED':
                return {
                    ...state,
                    summary: action.payload,
                };

            case AppAction.FORMAT_BICYCLES:

                return {
                    ...state,
                    bicycles: {},
                    formatBicyclesComplete: false,
                    resetMessage: ''
                };
            case AppAction.FORMAT_BICYCLES + '_FINISHED':
                return {
                    ...state,
                    bicycles: action.payload,
                    formatBicyclesComplete: true

                };
            case AppAction.FORMAT_DELIVERY_LOCATIONS:
                return {
                    ...state,
                };
            case AppAction.FORMAT_DELIVERY_LOCATIONS + '_FINISHED':
                return {
                    ...state,
                    deliveryLocations: action.payload
                };

            case AppAction.FORMAT_SUB_DELIVERY_LOCATIONS:
                return {
                    ...state,
                };
            case AppAction.FORMAT_SUB_DELIVERY_LOCATIONS + '_FINISHED':
                return {
                    ...state,
                    subDeliveryLocations: action.payload
                };

            case AppAction.FORMAT_ACCESSOIRES:

                return {
                    ...state,
                    accessoires: {},
                    formatAccessoiresComplete: false

                };
            case AppAction.FORMAT_ACCESSOIRES + '_FINISHED':

                return {
                    ...state,
                    accessoires: action.payload,
                    formatAccessoiresComplete: true
                };

            case AppAction.FORMAT_WEBSHOP:
                return {
                    ...state,
                };
            case AppAction.FORMAT_WEBSHOP + '_FINISHED':
                return {
                    ...state,
                    webshop: action.payload.webshop,
                    step: action.payload.firstStep,
                    firstStep: action.payload.firstStep
                };

            case AppAction.FORMAT_LOCATIONS:
                return {
                    ...state,
                };
            case AppAction.FORMAT_LOCATIONS + '_FINISHED':
                return {
                    ...state,
                    locations: action.payload,
                };

            case AppAction.MUTATE_BICYCLE_SELECTION_NUMBER:
                return {
                    ...state,
                };

            case AppAction.MUTATE_BICYCLE_SELECTION_NUMBER + '_FINISHED':

                return {
                    ...state,
                    bicycles: {
                        ...state.bicycles,
                        [action.payload.id]: {
                            ...state.bicycles[action.payload.id],
                            selected: action.payload.selected
                        }
                    },
                    excludedLocations: {
                        ...state.excludedLocations,
                        [action.payload.id]: action.payload.excludedLocations
                    },
                    delivery: {
                        ...state.delivery,
                        deliveryPickupLocation: false,
                        deliveryPickupSubLocation: false,
                        subLocationNumber: false,
                        partnerLocationId: false,
                        type: false,
                    },

                };

            case AppAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER:
                return {
                    ...state,
                };

            case AppAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER + '_FINISHED':

                return {
                    ...state,
                    accessoires: {
                        ...state.accessoires,
                        [action.payload.id]: {
                            ...state.accessoires[action.payload.id],
                            selected: action.payload.selected
                        }
                    },
                    excludedLocations: {
                        ...state.excludedLocations,
                        [action.payload.id]: action.payload.excludedLocations
                    },
                    delivery: {
                        ...state.delivery,
                        deliveryPickupLocation: false,
                        deliveryPickupSubLocation: false,
                        subLocationNumber: false,
                        partnerLocationId: false,
                        type: false,
                    },
                };

            case AppAction.SET_LOCATION_AVAILABILITY:
                return {
                    ...state,
                };

            case AppAction.SET_LOCATION_AVAILABILITY + '_FINISHED':
                return {
                    ...state,
                    locations: MapLocationAvailability(state, action)
                }


            case AppAction.SET_BICYCLE_AVAILABILITY:
                return {
                    ...state,
                    availabilityComplete: false
                };

            case AppAction.SET_BICYCLE_AVAILABILITY + '_FINISHED':
                return {
                    ...state,
                    bicycles: action.payload,
                    availabilityComplete: true
                }

            case AppAction.SET_ACCESSOIRE_AVAILABILITY:
                return {
                    ...state,
                };

            case AppAction.SET_ACCESSOIRE_AVAILABILITY + '_FINISHED':
                return {
                    ...state,
                    accessoires: action.payload
                }

            case AppAction.SET_FORM_DATA:
                return {
                    ...state,
                    form: {
                        completed: false,
                        data: false
                    }
                };

            case AppAction.SET_FORM_DATA + '_FINISHED':
                return {
                    ...state,
                    form: {
                        completed: true,
                        data: action.payload
                    }
                }

            case AppAction.SET_DELIVERY_LOCATION:
                return {
                    ...state,
                };

            case AppAction.SET_DELIVERY_LOCATION + '_FINISHED':
                return {
                    ...state,
                    subDeliveryLocations: false,
                    delivery: {
                        ...state.delivery,
                        deliveryPickupLocation: action.payload,
                        deliveryPickupSubLocation: false,
                        subLocationNumber: false,
                        type: false,
                        partnerLocationId: false
                    }

                }

            case AppAction.SET_SUB_DELIVERY_LOCATION:
                return {
                    ...state,
                };

            case AppAction.SET_SUB_DELIVERY_LOCATION + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        subLocationNumber: false,
                        deliveryPickupSubLocation: action.payload
                    }

                }

            case AppAction.SET_SUB_LOCATION_NUMBER:
                return {
                    ...state,
                };

            case AppAction.SET_SUB_LOCATION_NUMBER + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        subLocationNumber: action.payload
                    }

                }

            case AppAction.SET_DELIVERY_TYPE:
                return {
                    ...state,
                };

            case AppAction.SET_DELIVERY_TYPE + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        type: action.payload,
                        partnerLocationId: false
                    }
                }

            case AppAction.SET_PARTNER_LOCATION:
                return {
                    ...state,
                };

            case AppAction.SET_PARTNER_LOCATION + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        partnerLocationId: action.payload
                    }
                }

            case AppAction.CHANGE_BICYCLE_POPUP:
                return {
                    ...state,
                };

            case AppAction.CHANGE_BICYCLE_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: {
                        ...state.popup,
                        bicycle: action.payload
                    }
                }

            case AppAction.CHANGE_INSURANCE_POPUP:
                return {
                    ...state,
                }

            case AppAction.CHANGE_INSURANCE_POPUP + "_FINISHED":
                return {
                    ...state,
                    popup: {
                        ...state.popup,
                        insurance: action.payload
                    }
                }

            case AppAction.CHANGE_ACCESSOIRE_POPUP:
                return {
                    ...state,
                };

            case AppAction.CHANGE_ACCESSOIRE_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: {
                        ...state.popup,
                        accessoire: action.payload
                    }
                }

            case AppAction.CHANGE_ACCESSOIRE_CONDITION_POPUP:
                return {
                    ...state,
                };

            case AppAction.CHANGE_ACCESSOIRE_CONDITION_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: {
                        ...state.popup,
                        accessoireCondition: action.payload
                    }
                }

            case AppAction.CHANGE_LOCATION_POPUP:
                return {
                    ...state,
                };

            case AppAction.CHANGE_LOCATION_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: {
                        ...state.popup,
                        location: action.payload
                    }
                }

            case AppAction.VALIDATE_SHOPPING_CART:
                return {
                    ...state,
                    validationComplete: false,
                };

            case AppAction.VALIDATE_SHOPPING_CART + '_FINISHED':
                return {
                    ...state,
                    validation: action.payload,
                    validationComplete: true
                }

            case AppAction.SET_AND_VALIDATION_COMPLETE:
                return {
                    ...state,
                    setAndValidationComplete: false,
                };

            case AppAction.SET_AND_VALIDATION_COMPLETE + '_FINISHED':
                return {
                    ...state,
                    setAndValidationComplete: action.payload,
                }

            case AppAction.SET_DEFAULT_LANGUAGE:
                return {
                    ...state,
                };

            case AppAction.SET_DEFAULT_LANGUAGE + '_FINISHED':
                return {
                    ...state,
                    language: action.payload,
                }

            case AppAction.CHANGE_LANGUAGE:
                return {
                    ...state,
                };

            case AppAction.CHANGE_LANGUAGE + '_FINISHED':
                return {
                    ...state,
                    language: action.payload,
                }

            case AppAction.SET_DEFAULT_PERIOD:
                return {
                    ...state,
                };

            case AppAction.SET_DEFAULT_PERIOD + '_FINISHED':
                return {
                    ...state,
                    period: action.payload,

                }

            case AppAction.SET_CUSTOM_LOCATION_VALUE:
                return {
                    ...state,
                };

            case AppAction.SET_OPENED_LOCATIONS + '_FINISHED':
                return {
                    ...state,
                    openedLocations: action.payload
                };

            case AppAction.CHECK_PERIOD_DISCOUNT + '_FINISHED':
                return {
                    ...state,
                    periodDiscount: action.payload
                };

            case AppAction.SET_CUSTOM_LOCATION_VALUE + '_FINISHED':

                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        custom: {
                            ...state.delivery.custom,
                            ...action.payload
                        }
                    }
                }

            case AppAction.SUBMIT_REGISTER:
                return {
                    ...state,
                    registerSubmitted: true
                }


            case AppAction.RESET:
                return {
                    ...AppReducer.initialState,

                }


            case AppAction.RESET + '_FINISHED':
                return {
                    ...AppReducer.initialState,
                    toggleReset: !state.toggleReset,
                    resetMessage: action.payload?.message
                }


            default:
                return state;
        }
    }
}
