import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import ChangeBicycleNumber from "../../../utilities/dispatchers/ChangeBicycleNumber";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import DisplayItemSubLabel from "../../../utilities/displayers/DisplayItemSubLabel";

const mapStateToProps = (state) => ({
    ...state.app,
});


class BicycleItemMany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {

        this.setState({
            loaded: false
        })

    }


    render() {

        const data = this.props.data
        let className = 'c-products__single'

        if (data.inactive) {
            className += ' c-products__single--inactive'
        }

        return (

            <div className={className} key={data.id + 'bicycle'}>
                <div className={'c-products__single__info'}>
                    <div className={'c-products__single__info__primary'}>
                        <div className="c-products__single__info__primary__name">
                            <h4>{DisplayItemName(data)}</h4>
                            <p>{DisplayItemSubLabel(data)}</p>
                        </div>

                        <div className={'c-products__single__info__primary__wrapper'}>

                            <div className="c-products__single__amount">
                            <button className="minus" style={this.props.webshop.styles.dark} onClick={() => {
                                    ChangeBicycleNumber(data, -1, this.props.dispatch)
                                }}>
                                    <i className="icon icon-minus"/>
                                </button>

                                <span>{data.selected}</span>

                                <button className="plus" style={this.props.webshop.styles.dark} onClick={() => {
                                    ChangeBicycleNumber(data, 1, this.props.dispatch)
                                }}>

                                    <i className="icon icon-plus"/>

                                </button>
                            </div>

                            <span className="c-products__single__price">{DisplayPrice(data.price)}
                                <small>{Translate('p/s', this.props.language)}</small>
                            </span>

                        </div>
                    </div>
                    <div className="c-products__single__info__secondary">
                        {!!data.images &&
                        <div className="c-products__single__media c-bicycle-carousel">
                            <Carousel ref={(el) => (this.Carousel = el)} showArrows={false}
                                      showThumbs={false} showStatus={false} infiniteLoop={true}
                                      animationHandler={"fade"} swipeable={false} >
                                {data.images.map((image) => (
                                    <img src={image}/>
                                ))}
                            </Carousel>
                        </div>
                        }
                        <button className="c-products__single__modal-trigger"
                                onClick={() => this._clickMoreInfo(data.id)}>
                            <i className="icon icon-info"/>
                            <span>{Translate('informatie', this.props.language)}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }


    _clickLocation(locationId) {
        this.props.dispatch(AppAction.changeLocationPopup({
            show: true,
            id: locationId
        }))
    }

    _clickMoreInfo(bicycleId) {
        this.props.dispatch(AppAction.changeBicyclePopup({
            show: true,
            id: bicycleId
        }))
    }
}

export default connect(mapStateToProps)(BicycleItemMany);
