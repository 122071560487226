import data from '../../static/data/stringTranslations.json'
import dataBB from '../../static/data/stringTranslationsBlackBikes.json'
import data14 from '../../static/data/stringTranslations14.json'
import data15 from '../../static/data/stringTranslations15.json'
import data18 from '../../static/data/stringTranslations18.json'

let store
export const injectTranslateStore = _store => {
    store = _store
}


const Translate = (key, language) => {

    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }


    const webshopData = store.getState()?.info?.infoData?.detail?.webshops

    let string = key

    if (webshopData?.partner?.client_id === 8) {
        dataBB.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    } else if (webshopData?.partner?.client_id === 15) {
        data15.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    } else if (webshopData?.partner?.client_id === 14) {
        data14.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    } else if (webshopData?.partner?.client_id === 18) {
        data18.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    } else {
        data.filter(translateString => translateString["key"] === key).forEach(translateString => {
            string = translateString[language]
        })
    }

    if (webshopData?.item_label) {
        string = string.replace('*item_single*', webshopData?.item_label)
        string = string.replace('*item_single_cap*', capitalize(webshopData?.item_label))
    }

    if (webshopData?.item_label_plural) {
        string = string.replace('*item_plural*', webshopData?.item_label_plural)
        string = string.replace('*item_plural_cap*', capitalize(webshopData?.item_label_plural))
    }

    return string

}

export default Translate