import ActionUtility from '../../../utilities/etc/ActionUtility'
import AuthEffect from "./AuthEffect";

export default class AuthAction {
    static REQUEST_CONNECT = 'AuthAction.REQUEST_CONNECT';
    static REQUEST_REFRESH = 'AuthAction.REQUEST_REFRESH';

    static connect() {
        return async (dispatch, getState) => {


            const values = {}
            await ActionUtility.createThunkEffect(dispatch, AuthAction.REQUEST_CONNECT, AuthEffect.requestConnect, values);
        };
    }

    static refresh() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthAction.REQUEST_REFRESH, AuthEffect.requestRefresh, getState().refreshToken);
        };
    }
}