import React from 'react'
import Summary from "./_components/Summary";
import Products from "./_components/Products";
import Accept from "./_components/Accept";
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import SetAndValidateItems from "../../utilities/dispatchers/SetAndValidateItems";
import CreateOrUpdateRental from "../../utilities/dispatchers/CreateUpdateRental";
import Translate from "../../utilities/etc/Translate";
import Insurances from "./_components/Insurances";
import PaymentAction from "../../stores/api/payment/PaymentAction";

const mapStateToProps = (state) => ({
    ...state.app,
    cart: state.shoppingCart,
    insurance: state.insurance,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation,
    payment: state.payment,
    rental: state.rental
});

class PaymentOverview extends React.Component {


    componentDidMount() {

        SetAndValidateItems(this.props)
        CreateOrUpdateRental(this.props)

        this.props.dispatch(AppAction.switchStep(3))

        this.props.dispatch(AppAction.setSummary({
            period: false,
            bicycles: false,
            accessoires: false,
            accommodation: false,
            delivery: false
        }))


        this.props.scroll()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.payment.freeConfirmed) {
            this.props.history.push('/afhandeling/' + this.props.rental.rentalId)
        }
        if (prevProps.rental.rentalId !== this.props.rental.rentalId) {
            this.props.dispatch(PaymentAction.getPaymentMethods(this.props.rental.rentalId))
        }
    }


    render() {

        if (this.props.form.completed && this.props.rentalId) {
            return (
                <div className="o-container o-container--primary">

                    <div key={'payment-view'}>
                        <header className="c-subject__header">

                            {!this.props.webshop.recurring_enabled &&
                            <h2>{Translate('Bestellingsoverzicht', this.props.language)}</h2>
                            }

                            {!!this.props.webshop.recurring_enabled &&
                            <h2>{Translate('Huuroverzicht', this.props.language)}</h2>
                            }

                        </header>

                        <div className="c-order-overview">
                            <div className="c-order-overview__half">
                                <Summary/>
                            </div>
                            <div className="c-order-overview__half">
                                {!this.props.registerSubmitted && <Insurances/>}
                                <Products/>
                                <Accept/>
                            </div>
                        </div>
                    </div>

                </div>
            )
        } else {
            return null
        }
    }
}

export default connect(mapStateToProps)(PaymentOverview);
