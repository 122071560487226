import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import moment from 'moment'
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation
});


const Summary = (props) => {

    const periodRange = props.period
    const formData = props.form.data
    const delivery = props.delivery
    const deliveryPickupLocation = delivery.deliveryPickupLocation
    const partnerLocation = props.locations[delivery.partnerLocationId]
    const hideDropOffDeliveryOptions = props.webshop.hide_drop_off_delivery_options ?? false;
    const recurringEnabled = !!props.webshop.recurring_enabled
    const partnerClientId = props.info.infoData.detail.webshops?.partner?.client_id;


    return (
        <>
            <div className="c-order-overview__box">
                <header>
                    <h3> {Translate('Verhuurperiode', props.language)}</h3>
                    {!recurringEnabled &&
                        <div className="btn btn--pill">
                            <span>{periodRange.days + ' ' + Translate('dag(en)', props.language)}</span></div>
                    }
                </header>
                <div className="c-order-overview__box__content">
                    <div>
                        {!recurringEnabled &&
                            <span>
                            {moment(periodRange.startDate).format('dddd D MMMM')}
                                {!!props.timeUnit.time_required &&
                                    <>
                                        <span
                                            style={{marginLeft: '.5rem', marginRight: '.5rem'}}>{props.startTime}</span>
                                    </>
                                }
                                {periodRange.endDate && periodRange.endDate !== periodRange.startDate &&
                                    <> {Translate('t/m', props.language)} {moment(periodRange.endDate).format('dddd D MMMM')}
                                        {!!props.timeUnit.time_required &&
                                            <>
                                            <span style={{
                                                marginLeft: '.5rem',
                                                marginRight: '.5rem'
                                            }}>{props.endTime}</span>
                                            </>
                                        }
                                    </>
                                }
                        </span>
                        }
                        {!!recurringEnabled &&
                            <span>
                                {Translate('Vanaf', props.language)}
                                <span
                                    style={{marginLeft: '.5rem'}}>{moment(periodRange.startDate).format('dddd D MMMM')}</span>
                            </span>
                        }
                    </div>
                    {!props.registerSubmitted &&
                        <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Period))}
                                className="btn c-order-overview__edit">
                            {Translate('Wijzigen', props.language)}
                        </button>
                    }
                </div>
            </div>

            <>
                {!recurringEnabled &&
                    <div className="c-order-overview__box">
                        <header>
                            <h3>{Translate('Uw vakantieverblijf', props.language)}</h3>
                        </header>
                        <div className="c-order-overview__box__content">
                            <div>
                                {deliveryPickupLocation.value !== 'custom' &&
                                    <span>{deliveryPickupLocation.label},
                                        {delivery.deliveryPickupSubLocation.label &&
                                            <>
                                                {delivery.deliveryPickupSubLocation.label.replace(/[0-9]/g, '').replace('-', '')}
                                            </>
                                        }
                                        {delivery.subLocationNumber.label}
                                </span>
                                }
                                {deliveryPickupLocation.value === 'custom' &&
                                    <span>{props.delivery.custom.street} {props.delivery.custom.number} {props.delivery.custom.zip_code} {props.delivery.custom.city.label}</span>
                                }
                            </div>
                            {!props.registerSubmitted &&
                                <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))}
                                        className="btn c-order-overview__edit">{Translate('Wijzigen', props.language)}</button>}
                        </div>
                    </div>
                }

                {!hideDropOffDeliveryOptions && partnerLocation && props.webshop.type.code !== 'registration' &&
                    <div className="c-order-overview__box">
                        <header>
                            {!recurringEnabled &&
                            <>
                                {partnerClientId !== 8 &&
                                    <h3>{Translate('Leveringsoptie', props.language)}</h3>
                                }
                                {partnerClientId === 8 &&
                                    <h3>{Translate('Ophaallocatie', props.language)}</h3>
                                }
                            </>
                            }

                            {!!recurringEnabled &&
                                <h3>{Translate('Bezorging', props.language)}</h3>
                            }
                        </header>
                        <div className="c-order-overview__box__content">

                            {delivery.type === 'pickup' &&
                                <div>
                                    <span>{Translate('De *item_plural* kunnen opgehaald worden bij', props.language)} {partnerLocation.name}</span>
                                </div>
                            }

                            {delivery.type === 'delivery' && !recurringEnabled &&
                                <div>
                                    <span>{Translate('Bezorging op vakantieadres (tussen 08:00 - 10:00)', props.language)} - {partnerLocation.name}</span>
                                </div>
                            }

                            {delivery.type === 'delivery' && !!recurringEnabled &&
                                <div>
                                    <span>{Translate('Bezorging op adres', props.language)}</span>
                                </div>
                            }

                            {!props.registerSubmitted && !recurringEnabled &&
                                <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))}
                                        className="btn c-order-overview__edit">{Translate('Wijzigen', props.language)}</button>}
                        </div>
                    </div>
                }
            </>

            <div className="c-order-overview__box">
                <header>
                    {!recurringEnabled &&
                        <h3>{Translate('Uw gegevens', props.language)}</h3>
                    }
                    {!!recurringEnabled &&
                        <h3>{Translate('Gegevens berijder', props.language)}</h3>
                    }
                </header>
                {formData &&
                    <div className="c-order-overview__box__content">
                        {(formData.type === 'private' || props.webshop.type.code === 'registration') &&
                            <div>
                                <span>{formData.privatePerson.initials} {formData.privatePerson.first_name} {formData.privatePerson.insertion} {formData.privatePerson.last_name}</span>

                                <span>{formData.privatePerson.email}</span>
                                <span>{formData.privatePerson.telephone}</span>

                                <span>{formData.address.street} {formData.address.house_number}{formData.address.house_number_addition}</span>
                                <span>{formData.address.zip_code} {formData.address.city}</span>

                                <span>{formData.customer.remarks}</span>
                            </div>
                        }
                        {formData.type === 'company' &&
                            <div>
                                <span>{formData.company.name}</span>

                                <span>{formData.company.email}</span>

                                <span>{formData.address.street} {formData.address.house_number}{formData.address.house_number_addition}</span>
                                <span>{formData.address.zip_code} {formData.address.city}</span>


                                <span>{formData.contactPerson.first_name} {formData.contactPerson.insertion} {formData.contactPerson.last_name}</span>
                                <span>{formData.contactPerson.phone}</span>

                                <span>{formData.customer.remarks}</span>
                            </div>
                        }

                        {!props.registerSubmitted &&
                            <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Form))}
                                    className="btn c-order-overview__edit">{Translate('Wijzigen', props.language)}</button>}

                    </div>
                }
            </div>
        </>
    )

}

export default connect(mapStateToProps)(Summary);
