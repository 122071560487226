const DisplayItemAvailability = (amount) => {

    if (parseInt(amount) > 50) {
        return '50+'
    } else if (parseInt(amount) < 0) {
        return 0
    } else {
        return amount
    }

}

export default DisplayItemAvailability