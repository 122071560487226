import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

export const periodList = async (webshopId) => {
    return await axios.get(process.env.REACT_APP_API_URL + '/webshop/time-unit/list', {
        headers: {
            'Authorization': `Bearer ` + localStorage.getItem('accessToken')
        },
        params: {
            webshopId: webshopId,
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

export const periodCalculate = async (timeUnitId, amount, startDate, startTime, webshopId) => {
    return await axios.get(process.env.REACT_APP_API_URL + '/webshop/time-unit/calculate', {
        headers: {
            'Authorization': `Bearer ` + localStorage.getItem('accessToken')
        },
        params: {
            timeUnitId: timeUnitId,
            webshopId: webshopId,
            amount: amount,
            startDate: startDate,
            startTime: startTime
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

export const list = createAsyncThunk(
    'period/list',
    async (webshopObject, {getState}) => {
        const response = await periodList(webshopObject.id)
        const webShopMinDays = getState().app.webshop.min_rent_days

        if (response.data.items.data.length > 0) {
            response.data.items.data.map((unit) => {
                if (unit.type.code === 'days' && unit.min < webShopMinDays) {
                    unit.min = webShopMinDays
                    unit.min_amount = webShopMinDays
                }
            })
        }


        if (response.data && response.data.items.data) {
            return response.data.items.data
        }
    }
)

export const calculate = createAsyncThunk(
    'period/calculate',
    async (calculateObject) => {
        const response = await periodCalculate(calculateObject.timeUnitId, calculateObject.amount, calculateObject.startDate, calculateObject.startTime, calculateObject.webshopId)
        if (response.data && response.data.status.message) {
            return response.data.status.message
        }
    }
)

const initialState = {
    timeUnits: [],
    chosenUnit: null,
    timeAmount: '00:01',
    stepAmount: 1,
    numberAmount: 1,
    periodRange: {
        startDate: new Date(),
        endDate: new Date(),
        days: 1,
        key: "selection"
    },
    startTime: '00:00',
    calculation: null
}

export const periodSlice = createSlice({
    name: 'period',
    initialState,
    reducers: {
        addTimeUnit: (state, action) => {
            state.chosenUnit = action.payload
        },
        setDefaultPeriod: (state, action) => {
            state.periodRange = action.payload
        },
        editTimeAmount: (state, action) => {
            state.timeAmount = action.payload
        },
        editStepAmount: (state, action) => {
            state.stepAmount = action.payload
        },
        editNumberAmount: (state, action) => {
            state.numberAmount = action.payload
        },
        editPeriodRange: (state, action) => {
            state.periodRange = action.payload
        },
        editStartTime: (state, action) => {
            state.startTime = action.payload
        },
        editEndTime: (state, action) => {
            state.endTime = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(list.fulfilled, (state, action  ) => {
            if (action.payload) {
                state.timeUnits = action.payload
            }
        })
        builder.addCase(calculate.fulfilled, (state, action) => {
            if (action.payload) {
                state.calculation = action.payload
            }
        })
    }
})


export const {
    addTimeUnit,
    editTimeAmount,
    editPeriodRange,
    editStartTime,
    editNumberAmount,
    setDefaultPeriod,
    editStepAmount
} = periodSlice.actions
export const periodReducer = periodSlice.reducer
