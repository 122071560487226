import ActionUtility from '../../../utilities/etc/ActionUtility'
import RentalEffect from "./RentalEffect";

export default class RentalAction {
    static REQUEST_lIST = 'RentalAction.REQUEST_LIST';
    static REQUEST_UPDATE = 'RentalAction.REQUEST_UPDATE';
    static REQUEST_CREATE = 'RentalAction.REQUEST_CREATE';
    static REQUEST_DETAIL = 'RentalAction.REQUEST_DETAIL';
    static REQUEST_DELETE = 'RentalAction.REQUEST_DELETE';


    static list(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, RentalAction.REQUEST_lIST, RentalEffect.requestList, values);
        };
    }

    static update(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, RentalAction.REQUEST_UPDATE, RentalEffect.requestUpdate, {values, dispatch});
        };
    }

    static create(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, RentalAction.REQUEST_CREATE, RentalEffect.requestCreate, {values, dispatch});
        };
    }

    static detail(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, RentalAction.REQUEST_DETAIL, RentalEffect.requestDetail, values);
        };
    }

    static delete(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken

            await ActionUtility.createThunkEffect(dispatch, RentalAction.REQUEST_DELETE, RentalEffect.requestDelete, values);
        };
    }

}