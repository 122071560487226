import React from 'react'
import DeliveryMap from "./_components/DeliveryMap";
import DeliverySelector from "./_components/DeliverySelector";
import {connect} from "react-redux";
import AppAction from "../../../../stores/app/AppAction";
import CheckDeliverableStatus from "../../../../utilities/etc/CheckDeliverableStatus";
import Translate from "../../../../utilities/etc/Translate";
import AvailabilityAction from "../../../../stores/api/availability/AvailabilityAction";
import RouteEnum from "../../../../constants/RouteEnum";


const mapStateToProps = (state) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
    apiLocations: state.locations,
    availability: state.availability
});

class DeliveryOptions extends React.Component {


    componentDidUpdate(prevProps) {

        if (this.props.delivery?.deliveryPickupLocation?.address?.id && prevProps.delivery?.deliveryPickupLocation?.address?.id !== this.props.delivery?.deliveryPickupLocation?.address?.id) {

            this.props.dispatch(AvailabilityAction.validateDeliveryByAddress())
        }

        if (this._showComponent()) {
            if (!this.props.delivery.type) {
                const recurringEnabled = !!this.props.webshop?.recurring_enabled
                const deliverable = CheckDeliverableStatus(this.props)

                if ((!deliverable || this.props.webshop.delivery_disabled) && !recurringEnabled) {
                    this.props.dispatch(AppAction.setDeliveryType('pickup'))
                }
            }
        }
    }


    render() {

        const deliverable = CheckDeliverableStatus(this.props)


        let deliveryClass = 'c-delivery-options__single'
        if (!deliverable) {
            deliveryClass += ' is-disabled'
        }


        if (this._showComponent()) {

            return (
                <>
                    <div className="c-subject__header__sub">
                        <h4>{Translate('Selecteer één van de leveringsopties', this.props.language)}</h4>
                    </div>

                    <div className="c-delivery-options">

                        {!this.props.webshop.delivery_disabled &&

                            <div className={deliveryClass}>
                                <div
                                    className="c-delivery-options__single__header  c-delivery-options__single--standard"
                                    onClick={() => this._setDeliveryType('delivery')}>
                                    <div className="c-delivery-options__check">
                                        {this.props.delivery.type === 'delivery' &&
                                            <figure/>
                                        }
                                    </div>
                                    <div className="c-delivery-options__text">

                                        {!deliverable &&
                                            <small
                                                className={'not-deliverable'}>{Translate('Helaas is bezorgen op dit vakantieadres niet mogelijk', this.props.language)}</small>
                                        }
                                        <h5>{Translate('Bezorgen en ophalen op vakantieverblijf', this.props.language)}</h5>
                                        <p>
                                            {Translate('Bezorgen op Van der Linde Vakantieparken. Uw bestelling wordt gratis bij uw vakantieverblijf bezorgd en retour opgehaald. Bezorging tussen 08:00 en 10:00', this.props.language)}
                                        </p>
                                    </div>

                                </div>
                                {this.props.delivery.type === 'delivery' &&

                                    <div className="c-delivery-options__store__select-store">

                                        <DeliverySelector
                                            deliveryComponent={true}
                                        />

                                        <DeliveryMap
                                            deliveryComponent={true}
                                        />

                                    </div>

                                }
                            </div>

                        }

                        <div className="c-delivery-options__single c-delivery-options__single--store">
                            <div className="c-delivery-options__single__header"
                                 onClick={() => this._setDeliveryType('pickup')}>
                                <div className="c-delivery-options__check">
                                    {this.props.delivery.type === 'pickup' &&
                                        <figure/>
                                    }
                                </div>
                                <div className="c-delivery-options__text">
                                    <h5>{Translate('Afhalen en retourneren', this.props.language)}</h5>
                                </div>
                            </div>
                            {this.props.delivery.type === 'pickup' &&
                                <div className="c-delivery-options__store__select-store">
                                    <DeliverySelector
                                        deliveryComponent={false}
                                    />
                                    <DeliveryMap
                                        deliveryComponent={false}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                </>
            )
        } else {
            return null
        }

    }

    _setDeliveryType(type) {
        if (this.props.delivery.type !== type) {
            this.props.dispatch(AppAction.setDeliveryType(type))
        }
    }

    _showComponent() {
        const recurringEnabled = !!this.props.webshop?.recurring_enabled
        const locationCompleted = this.props.delivery.deliveryPickupLocation && this.props.delivery.deliveryPickupLocation.value !== 'custom' && !this.props.subDeliveryLocations
        const subLocationCompleted = this.props.delivery.deliveryPickupLocation && this.props.delivery.deliveryPickupLocation.value !== 'custom' && this.props.subDeliveryLocations && this.props.delivery.subLocationNumber && this.props.delivery.deliveryPickupSubLocation
        const customLocationCompleted = this.props.delivery.deliveryPickupLocation.value === 'custom' && !Object.keys(this.props.delivery.custom)
            .some(x => {
                const data = this.props.delivery.custom[x]
                return (data === null || data === '')
            })
        return locationCompleted || subLocationCompleted || customLocationCompleted || recurringEnabled;
    }
}

export default connect(mapStateToProps)(DeliveryOptions);
