const formatterEUR = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
})

const formatterDKK = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK'
})


const DisplayPrice = (number, discount = false) => {

    let denmarkSlugCheck = ['landal-fyrklit', 'landal-gronhojstrand', 'landal-seawest', 'landal-sohojlandet', 'landal-ebeltoft', 'landal-middelfart', 'landal-ronbjerg']
    let fixedNumber = Number.parseFloat(number).toFixed(2);

    if (discount) {
        discount = Number(discount) / 100;
        fixedNumber =  Number(fixedNumber - (fixedNumber * discount)).toFixed(2);
    }

    if (window.location.href.indexOf("/3/") > -1 && denmarkSlugCheck.some(slug => window.location.href.includes(slug))) {
        return formatterDKK.format(parseFloat(fixedNumber))
    }else{
        return formatterEUR.format(parseFloat(fixedNumber))
    }

}

export default DisplayPrice
