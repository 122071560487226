import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state.app,
});

const changeAccommodation = (props) => {
    props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation)).then(() => {

        props.dispatch(AppAction.setSummary({
            period: true,
            bicycles: true,
            accessoires: true,
            accommodation: true,
            delivery: false,
        }))
    })
}

const SummaryDelivery = (props) => {

    const delivery = props.delivery
    const partnerLocation = props.locations[delivery.partnerLocationId]
    const recurringEnabled = props.webshop.recurring_enabled ?? 0;

    if (partnerLocation) {
        return (
            <div className="c-summary c-summary--text">
                <i className="c-summary__check icon icon-check-circle"/>
                <div className="c-summary__subject__header">

                    {!recurringEnabled &&
                        <>
                            <h5>{Translate('Vestiging', props.language)}</h5>
                            <button onClick={() => changeAccommodation(props)} className="c-summary__edit">
                                <span>{Translate('Wijzigen', props.language)}</span>
                                <i className="icon icon-edit"/>
                            </button>
                        </>
                    }

                    {!!recurringEnabled &&
                        <h5>{Translate('Bezorging', props.language)}</h5>
                    }

                </div>
                <div className="c-summary--period__text">

                    {delivery.type === 'pickup' &&
                        <span>{Translate('De *item_plural* kunnen opgehaald worden bij', props.language)} {partnerLocation.name}</span>
                    }

                    {delivery.type === 'delivery' &&
                        <span>{!recurringEnabled
                            ? Translate('Bezorging op vakantieadres (tussen 08:00 - 10:00)', props.language)
                            : Translate('Bezorging op adres', props.language)}
                        </span>
                    }
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default connect(mapStateToProps)(SummaryDelivery);
