import React from 'react'
import {connect} from "react-redux";


const mapStateToProps = (state) => ({
    ...state.app,
});


class ApplicationWrapper extends React.Component {

    render() {

        const classes = 's-webshop-order language-' + this.props.language

        return (
            <main className={classes}>

                {this.props.children}


                { /** ((this.props.step === RouteEnum.Bicycles || this.props.step === RouteEnum.Accessoires) && this.props.webshop.id !== 9) &&
                 <div className="c-footer-link">
                 <a href="https://shop.tilia.app/*item_plural*optexel">{Translate('Geen beschikbaarheid? Ga naar shop.tilia.app/*item_plural*optexel', this.props.language)}</a>
                 </div>
                 */}

            </main>
        )
    }
}

export default connect(mapStateToProps)(ApplicationWrapper);
