import ActionUtility from '../../../utilities/etc/ActionUtility'
import PaymentEffect from "./PaymentEffect";

export default class PaymentAction {
    static REQUEST_HANDLE = 'PaymentAction.REQUEST_HANDLE';
    static REQUEST_TRANSACTION_STATUS = 'PaymentAction.REQUEST_TRANSACTION_STATUS';
    static REQUEST_PAYMENT_METHODS = 'PaymentAction.REQUEST_PAYMENT_METHODS';
    static SET_PAYMENT_METHODS = 'PaymentAction.SET_PAYMENT_METHODS';
    static SET_PAYMENT_CUSTOMER_DATA = 'PaymentAction.SET_PAYMENT_CUSTOMER_DATA';

    static handle(values) {
        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            values.type = getState().payment.paymentMethods.provider ?? ''
            values.payment_method = getState().payment.paymentMethods.useMethod ?? ''
            values.payment_customer_data = getState().payment.paymentCustomerData ?? {}
            values.deferred_payment = !!getState().app.webshop.deferred_payment_enabled
            values.recurring_enabled = !!getState().app.webshop.recurring_enabled
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_HANDLE, PaymentEffect.requestHandle, values);
        };
    }

    static transactionStatus(values) {

        return async (dispatch, getState) => {
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_TRANSACTION_STATUS, PaymentEffect.requestTransactionStatus, values);
        };
    }

    static getPaymentMethods(rentalUuid) {
        return async (dispatch, getState) => {
            const values = {}
            values.accessToken = getState().auth.accessToken
            values.uuid = rentalUuid
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.REQUEST_PAYMENT_METHODS, PaymentEffect.requestPaymentMethods, values);
        };
    }

    static setPaymentMethod(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.SET_PAYMENT_METHODS, PaymentEffect.setPaymentMethod, values);
        };
    }
    static setPaymentCustomerData(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, PaymentAction.SET_PAYMENT_CUSTOMER_DATA, PaymentEffect.setPaymentCustomerData, values);
        };
    }

}