import ActionUtility from '../../../utilities/etc/ActionUtility'
import CategoryEffect from "./CategoryEffect";

export default class CategoryAction {
    static REQUEST_lIST = 'CategoryAction.REQUEST_LIST';

    static list(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, CategoryAction.REQUEST_lIST, CategoryEffect.requestList, values);
        };
    }

}