import ActionUtility from '../../../utilities/etc/ActionUtility'
import GeneralEffect from "./GeneralEffect";

export default class CategoryAction {
    static REQUEST_LANGUAGE_LIST = 'GeneralAction.REQUEST_LANGUAGE_LIST';
    static REQUEST_CITY_LIST = 'GeneralAction.REQUEST_CITY_LIST';

    static languageList() {
        return async (dispatch, getState) => {
            const values = {}
            values.language = getState().app.language
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, CategoryAction.REQUEST_LANGUAGE_LIST, GeneralEffect.requestCountryList, values);
        };
    }

    static cityList() {
        return async (dispatch, getState) => {
            const values = {}
            values.language = getState().app.language
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, CategoryAction.REQUEST_CITY_LIST, GeneralEffect.requestCityList, values);
        };
    }

}