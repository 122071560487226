import ApiEnum from '../../../constants/ApiEnum'

export default class AuthEffect {

    static async requestConnect() {
        return await fetch(ApiEnum.Api + '/auth/connect', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                key: ApiEnum.key,
                secret: ApiEnum.secret
            })
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200 || response.status.code === 400) {

                localStorage.setItem('accessToken', response.items.accessToken.access_token)

                localStorage.setItem('refreshToken', response.items.accessToken.refresh_token)

                return {
                    response: response.items,
                }

            } else {

                return {
                    error: true,
                    message: response.status.message
                }

            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })
    }


    static async requestRefresh(values) {

        return await fetch(ApiEnum.Api + '/auth/refresh', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: values.refreshToken
            })
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {


            if (response.status === 200) {
                return {
                    response: response.items,
                }
            } else {

                return {
                    error: true,
                    message: response.status.message
                }
            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })
    }
}

