import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import moment from 'moment'
import Translate from "../../utilities/etc/Translate";


const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.app,
    rental: state.rental,
    categories: state.category,
    info: state.info,
    cities: state.generalApi?.cityData?.list
});

class ThanksRecurring extends React.Component {

    componentDidMount() {
        this.props.dispatch(AppAction.switchAppStep(7))
    }

    render() {


        let rentalData = this.props.rental.rentalData.detail

        const privatePerson = rentalData?.privatePerson
        const id = rentalData?.rental?.id ?? '';

        const city = this.props.cities?.find((city) => {
            return city.id === rentalData.delivery_address?.city_id
        })

        let salutation = Translate('meneer/mevrouw', this.props.language)
        if (privatePerson) {

            switch (privatePerson.gender_id) {
                case(1):
                    salutation = Translate('Meneer', this.props.language)
                    break;
                case(2):
                    salutation = Translate('Mevrouw', this.props.language)
                    break;
                case(3):
                    salutation = Translate('Persoon', this.props.language)
                    break;
                default:
                    salutation = Translate('meneer/mevrouw', this.props.language)
                    break;
            }
        }

        salutation = salutation.toLowerCase() + ' ' + (privatePerson.last_name ?? '')

        return (

            <div className={'o-container o-container--primary'} style={{padding: '4rem 0'}}>

                <p>

                    <h1>{Translate('Bedankt, uw reservering is succesvol afgerond', this.props.language)}</h1>

                    <p>{Translate('greetings-recurring', this.props.language)} {salutation}</p>

                    <p>{Translate('thanks-intro-recurring', this.props.language)}</p>

                    <p><b>{Translate('expectations-recurring', this.props.language)}</b></p>

                    <p>{Translate('content-recurring', this.props.language)}</p>

                    <h3 style={{marginTop: '3rem'}}>{Translate('Jouw bestelling', this.props.language)}: </h3>

                    <p>{Translate('Bestelnummer', this.props.language)}: {id}</p>

                    <p>{Translate('Vanaf', this.props.language)}: {moment(rentalData.rental?.date_rent_start).format('dddd D MMMM HH:mm')}</p>

                    <p>{Translate('Bezorging op adres', this.props.language)}: {rentalData.delivery_address?.name ? (rentalData.delivery_address.name + ', ') : ''} {rentalData.delivery_address?.street} {rentalData.delivery_address?.number} {city?.label}</p>

                    <br/>
                    <br/>

                    <p>{Translate('Wij wensen je alvast veel veilige kilometers toe.', this.props.language)}</p>

                </p>

            </div>
        )

    }
}

export default connect(mapStateToProps)(ThanksRecurring);
