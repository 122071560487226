import ShoppingCartAction from "./ShoppingCartAction";

export default class ShoppingCartReducer {

    static initialState = {
        totalPrice: 0,
        bicyclePrice: 0,
        bicycles: [],
        accessoirePrice: 0,
        accessoires: []
    };

    static reducer(state = ShoppingCartReducer.initialState, action) {

        switch (action.type) {

            case ShoppingCartAction.UPDATE:

                return {
                    ...state,
                };

            case ShoppingCartAction.UPDATE + '_FINISHED':


                return {
                    ...state,
                    ...action.payload
                }

            case ShoppingCartAction.EMPTY:
                return {
                    ...state,
                };

            case ShoppingCartAction.EMPTY + '_FINISHED':
                if(action.payload === 'accessoires'){
                    return {
                        ...state,
                        totalPrice: 0,
                        accessoires: []
                    }
                }else{
                    return {
                        totalPrice: 0,
                        bicyclePrice: 0,
                        bicycles: [],
                        accessoires: []
                    }
                }


            default:
                return state;
        }
    }
}
