import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import Translate from "../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.app,
    ...state.rental
});

class BankTransfer extends React.Component {

    componentDidMount() {
        this.props.dispatch(AppAction.switchAppStep(7))
    }

    render() {
        return (

            <div style={{padding: '4rem'}}>

                <h4>{Translate('Bedankt voor uw reservering', this.props.language)}</h4>
                <p>
                    {Translate('Overboeking', this.props.language)}
                </p>

            </div>
        )
    }

}

export default connect(mapStateToProps)(BankTransfer);