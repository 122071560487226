import React from 'react'
import {connect} from "react-redux";
import PaymentAction from "../../../stores/api/payment/PaymentAction";
import Translate from "../../../utilities/etc/Translate";
import AppAction from "../../../stores/app/AppAction";

const mapStateToProps = (state) => ({
    ...state.app,
    insurance: state.insurance,
    rental: state.rental.rentalData.update,
    paymentMethod: state.payment.paymentMethods.useMethod,
    paymentProvider: state.payment.paymentMethods.provider,
    paymentCustomerData: state.payment.paymentCustomerData,
});

class Accept extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agreedToTerms: false,
            disabled: true,
            regex: new RegExp('^[A-Z]{2}[0-9]{2} [A-Z]{4} (?: ?[0-9]{4}){2} [0-9]{2}?$')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.paymentMethod !== prevProps.paymentMethod || this.state.agreedToTerms !== prevState.agreedToTerms) &&
            ((this.props.paymentMethod && (this.props.paymentProvider === 'ccv' || this.props.paymentProvider === 'pay')) ||
                (this.props.paymentProvider === 'pay' && this.props.paymentMethod?.toLowerCase() === 'incasso' &&
                    this.props.paymentCustomerData.accountNumber !== '' && this.props.paymentCustomerData.accountNumber !== '') ||
                this.state.agreedToTerms)) {
            this.setState({
                disabled: false,
            })
        }
        if ((this.props.paymentMethod !== prevProps.paymentMethod || this.state.agreedToTerms !== prevState.agreedToTerms) &&
            ((!this.props.paymentMethod && (this.props.paymentProvider === 'ccv' || this.props.paymentProvider === 'pay')) ||
                (this.props.paymentProvider === 'pay' && this.props.paymentMethod?.toLowerCase() === 'incasso'
                    && (this.props.paymentCustomerData.accountNumber === '' || this.props.paymentCustomerData.accountNumber === '')) ||
                !this.state.agreedToTerms)) {
            this.setState({
                disabled: true,
            })
        }
    }

    render() {

        if (this.props.rental && this.props.webshop.type.code !== 'registration' && !this.props.insurance.loading) {
            return (
                <>
                    <div className="c-order-overview__terms">
                        <label htmlFor="">
                            <input type="checkbox" onChange={(e) => this._clickAccept(e)}/>
                            <span
                                style={{marginLeft: '1rem'}}>{Translate('Ik ga akkoord met de ', this.props.language)}
                                <a href={this.props.webshop.terms_url} rel="noopener noreferrer"
                                   target="_blank">{Translate('verhuurvoorwaarden', this.props.language)}</a>
                                {this.props.webshop.client_id === 18 && (Translate(' en bevestig hierbij dat ik een overdekte stalling heb', this.props.language))}
                            </span>
                        </label>
                    </div>

                    {(this.props.paymentProvider === 'ccv' || this.props.paymentProvider === 'pay') && !this.props.paymentMethod &&
                        <div className="c-form__message c-form__message--error">
                            <i className="icon icon-info"/>
                            <span>{Translate('Selecteer a.u.b. een betaalmethode', this.props.language)}</span>
                        </div>
                    }

                    {this.props.paymentProvider === 'pay' && this.props.paymentMethod?.toLowerCase() === 'incasso' &&
                        (this.props.paymentCustomerData.name === '' || this.props.paymentCustomerData.accountNumber === '') &&
                        <div className="c-form__message c-form__message--error">
                            <i className="icon icon-info"/>
                            <span>{Translate('Vul de ontbrekende bank gegevens in', this.props.language)}</span>
                        </div>
                    }

                    {this.props.paymentProvider === 'pay' && this.props.paymentMethod?.toLowerCase() === 'incasso' &&
                        (this.props.paymentCustomerData.accountNumber !== '' && this.props.paymentCustomerData.accountNumber.match(this.state.regex) === null) &&
                        <div className="c-form__message c-form__message--error">
                            <i className="icon icon-info"/>
                            <span>{Translate('Vul a.u.b. een geldig bankrekeningnummer in', this.props.language)}</span>
                        </div>
                    }

                    {!this.state.agreedToTerms &&
                        <div className="c-form__message c-form__message--error">
                            <i className="icon icon-info"/>
                            <span>{Translate('Ga a.u.b. akkoord met onze voorwaarden', this.props.language)}</span>
                        </div>
                    }

                    {(!this.props.webshop.insurance_required || (this.props.webshop.insurance_required && !this.props.insurance.loading && !!this.props.insurance.selected)) &&
                    !this.props.webshop.deferred_payment_enabled ?
                        <div className="c-order-overview__payment-button" onClick={() => this._clickPay()}>
                            <button className="btn btn--rounded" disabled={this.state.disabled || (this.props.paymentProvider === 'pay' && this.props.paymentMethod?.toLowerCase() === 'incasso' && (this.props.paymentCustomerData.accountNumber !== '' && this.props.paymentCustomerData.accountNumber.match(this.state.regex) === null))}
                                    style={this.props.webshop.styles.dark}>
                                {this.props.rental.totalPrice !== 0 &&
                                    <span>{Translate('Verder met betalen', this.props.language)}</span>
                                }
                                {this.props.rental.totalPrice === 0 &&
                                    <span>{Translate('Bevestigen', this.props.language)}</span>
                                }
                            </button>
                        </div>
                        : this.props.webshop.deferred_payment_enabled ?

                            <div className="c-order-overview__payment-button" onClick={() => this._clickPay()}>
                                <button className="btn btn--rounded" disabled={this.state.disabled}
                                        style={this.props.webshop.styles.dark}>
                                    <span>{Translate('Reservering bevestigen', this.props.language)}</span>
                                </button>
                            </div>

                            : <></>

                    }

                </>
            )
        } else {
            if (!this.props.registerSubmitted && this.props.webshop.type.code === 'registration') {
                return (

                    <>

                        <div className="c-order-overview__terms">
                            <label htmlFor="">
                                <input type="checkbox" onChange={(e) => this._clickAccept(e)}/>
                                <span
                                    style={{marginLeft: '1rem'}}>{Translate('Ik ga akkoord met de ', this.props.language)}
                                    <a href={this.props.webshop.terms_url} rel="noopener noreferrer"
                                       target="_blank">{Translate('voorwaarden', this.props.language)}</a></span>
                            </label>
                        </div>

                        {this.state.disabled &&
                            <div className="c-form__message c-form__message--error">
                                <i className="icon icon-info"/>
                                <span>{Translate('Ga a.u.b. akkoord met onze voorwaarden', this.props.language)}</span>
                            </div>
                        }

                        <button onClick={() => this.props.dispatch(AppAction.submitRegister())}
                                className="btn btn--rounded"
                                disabled={this.state.disabled}
                                style={this.props.webshop.styles.dark}>
                            <span>{Translate('Klik hier om te bevestigen en QR code op te halen ', this.props.language)}</span>
                        </button>

                    </>
                )
            } else {
                if (this.props.rental && this.props.rental.qr_url) {

                    return (
                        <div>
                            <div className="c-order-overview__qr"/>
                            <img src={this.props.rental.qr_url} alt={'alt'}/>
                            <div style={{marginTop: '2rem'}}>
                                <button onClick={() => this.props.dispatch(AppAction.reset())}
                                        className="btn btn--rounded" style={this.props.webshop.styles.dark}>
                                    <span>{Translate('Afronden', this.props.language)}</span>
                                </button>
                            </div>
                        </div>
                    )

                } else return null
            }
        }
    }

    _clickPay() {
        if (!this.state.disabled && (!this.props.webshop.insurance_required || (this.props.webshop.insurance_required && !this.props.insurance.loading && !!this.props.insurance.selected))) {

            this.setState({
                disabled: true
            })

            this.props.dispatch(PaymentAction.handle({
                uuid: this.props.rentalId,
                partnerId: this.props.webshop.partner_id,
                type: this.props.paymentProvider,
            })).then(() => {
                setTimeout(() => {
                    this.setState({
                        disabled: false
                    })
                }, 1000)
            })

        }
    }

    _clickAccept(e) {
        this.setState({
            agreedToTerms: e.target.checked,
        });
    }
}

export default connect(mapStateToProps)(Accept);
