const ApiEnum = {
    key: '3c3ab799-89c1-48a0-90cf-9fb17548db01',
    secret: '1442671c-1874-4dda-bf80-943e7be19888',
    Api: process.env.REACT_APP_API_URL,
    ApiClientAddress: process.env.REACT_APP_API_URL + '/client/address',
    ApiWebshopAddress: process.env.REACT_APP_API_URL + '/webshop/address',
    ApiWebshopAvailability: process.env.REACT_APP_API_URL + '/webshop/availability',
    ApiWebshopCategory: process.env.REACT_APP_API_URL + '/webshop/category',
    ApiWebshopInfo: process.env.REACT_APP_API_URL + '/webshop/info',
    ApiWebshopPreferences: process.env.REACT_APP_API_URL + '/webshop/preferences',
    ApiWebshopLocation: process.env.REACT_APP_API_URL + '/webshop/location',
    ApiWebshopPartner: process.env.REACT_APP_API_URL + '/webshop/partner',
    ApiWebshopPayment: process.env.REACT_APP_API_URL + '/webshop/payment',
    ApiWebshopPaymentMethods: process.env.REACT_APP_API_URL + '/webshop/payment/payment-methods',
    ApiWebshopInsurance: process.env.REACT_APP_API_URL + '/webshop/insurance/list',
    ApiWebshopRental: process.env.REACT_APP_API_URL + '/webshop/rental',
    ApiGeneral: process.env.REACT_APP_API_URL + '/general',
}

export default ApiEnum
