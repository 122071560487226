import React, {useEffect, useState} from 'react'

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useDispatch} from "react-redux";
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import AppAction from "../../stores/app/AppAction";
import {useAppSelector} from "../../stores/hooks";
import RouteEnum from "../../constants/RouteEnum";
import Translate from "../../utilities/etc/Translate";

import {
    addTimeUnit,
    editTimeAmount,
    editPeriodRange,
    editStartTime,
    editStepAmount,
    editNumberAmount,
    list,
    calculate, setDefaultPeriod
} from "./PeriodSlice";
import {getAvailableOpeningTimes} from "../../utilities/etc/getAvailableOpeningTimes";
import {FormatDayNumberToName} from "../../utilities/formatters/FormatDayNumberToName";
import ShoppingCartAction from "../../stores/shoppingCart/ShoppingCartAction";
import GetMinDate from "../../utilities/etc/GetMinDate";
import NewPeriodDisplay from "./_parts/NewPeriodDisplay";
import {OldPeriodDisplay} from "./_parts/OldPeriodDisplay";
import RecurringPeriodDisplay from "./_parts/RecurringPeriodDisplay";


export const Period = () => {

    const dispatch = useDispatch()
    const app = useAppSelector(state => state.app)
    const {infoData} = useAppSelector(state => state.info)
    const shopIsTypeRecurring = !!infoData.detail.webshops?.webshop?.recurring_enabled ?? false



    const {
        timeUnits,
        chosenUnit,
        timeAmount,
        stepAmount,
        periodRange,
        startTime,
        calculation
    } = useAppSelector(state => state.period);


    const [amountButtonDisabled, setAmountButtonDisabled] = useState(false)
    const [showNextButton, setShowNextButton] = useState(true)

    const [lastHour, setLastHour] = useState()

    const [disabledHours, setDisabledHours] = useState()
    const [disabledQuarters, setDisabledQuarters] = useState()

    const [quarterSteps, setQuarterStep] = useState(900)
    const [minDateLoaded, setMinDateLoaded] = useState(false)

    const [timeInput, setTimeInput] = useState(document.getElementById('time-input'))
    const [numberInput, setNumberInput] = useState(document.getElementById('number-input'))
    const minDate = GetMinDate(app.webshop)


    const changePeriod = (values) => {

        if (chosenUnit) {
            setQuarterStep(900 * chosenUnit.amount)
            dispatch(editStepAmount(chosenUnit.min_amount))
            dispatch(editNumberAmount(chosenUnit.min_amount * chosenUnit.amount))
            dispatch(editTimeAmount(secondsToTime(900 * chosenUnit.min_amount)))
        }

        if (chosenUnit && chosenUnit.id && chosenUnit.amount && values.startDate && startTime) {
            const calculateObject = {
                timeUnitId: chosenUnit.id,
                webshopId: app.webshop.id,
                amount: chosenUnit.min_amount,
                startDate: moment(values.startDate).format('YYYY-MM-DD'),
                startTime: startTime
            }
            dispatch(calculate(calculateObject)).then((response) => {
                if (chosenUnit && response.payload && response.payload.start_date && response.payload.end_date) {
                    let period = {
                        startDate: new Date(response.payload.start_date.replace(/-/g, "/") + ' ' + response.payload.start_time),
                        endDate: new Date(response.payload.end_date.replace(/-/g, "/") + ' ' + response.payload.end_time),
                        days: 1,
                        key: "selection"
                    }
                    dispatch(editPeriodRange(period))
                }
            })
        }
    }

    const datesAreOnSameDay = (first, second) => {
        return first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate();
    }

    const secondsToTime = (input) => {
        const secNum = parseInt(input, 10); // don't forget the second param
        let hours = Math.floor(secNum / 3600);
        let minutes = Math.floor((secNum - (hours * 3600)) / 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return hours + ':' + minutes;
    }

    const calculateFunction = (unit, range, time, amount, time_amount) => {
        if (unit && unit.id && unit.amount && range && range.startDate && time) {
            const calculateObject = {
                timeUnitId: unit.id,
                webshopId: app.webshop.id,
                amount: amount,
                startDate: moment(range.startDate).format('YYYY-MM-DD'),
                startTime: time
            }
            dispatch(calculate(calculateObject)).then((response) => {

                if (unit && response.payload && response.payload.start_date && response.payload.end_date) {

                    let period = {
                        startDate: new Date(response.payload.start_date.replace(/-/g, "/") + ' ' + response.payload.start_time),
                        endDate: new Date(response.payload.end_date.replace(/-/g, "/") + ' ' + response.payload.end_time),
                        days: 1,
                        key: "selection"
                    }

                    dispatch(editPeriodRange(period))

                    if (unit?.type.code === 'hours') {

                        const timeMoment = moment('01-01-2020 ' + time, 'DD-MM-YYYY HH:mm').add(unit.amount, 'hours')

                        const latestTime = lastHour - (unit.amount * amount) + ':00'
                        const latestMoment = moment('01-01-2020 ' + latestTime, 'DD-MM-YYYY HH:mm')

                        if (timeMoment.isAfter(latestMoment)) {
                            setAmountButtonDisabled(true)
                        } else {
                            setAmountButtonDisabled(false)
                        }

                    } else if (unit?.type.code === 'quarters') {

                        const timeMoment = moment('01-01-2020 ' + time, 'DD-MM-YYYY HH:mm').add(15 * unit.amount, 'minutes')

                        const latestTime = parseInt(lastHour - (unit.amount * (amount / 4))) + ':' + (60 * Math.ceil(moment.duration(time_amount, 'minutes').asHours()) - (15 * (1 * amount)))
                        const latestMoment = moment('01-01-2020 ' + latestTime, 'DD-MM-YYYY HH:mm')

                        if (timeMoment.isAfter(latestMoment)) {
                            setAmountButtonDisabled(true)
                        } else {
                            setAmountButtonDisabled(false)
                        }

                    } else {

                        setAmountButtonDisabled(false)

                    }
                }
            })
        }
    }

    useEffect(() => {

        if (minDate && !minDateLoaded) {
            dispatch(setDefaultPeriod({
                startDate: moment(minDate).toDate(),
                endDate: moment(minDate).toDate(),
                days: 1,
                key: "selection"
            }))

            setMinDateLoaded(true)
        }

    }, [minDate, minDateLoaded])


    useEffect(() => {

        const locations = Object.values(app.locations)
        let earliestHour = 99
        let latestHour = 0
        let latestMinute = 0

        let disabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
        let disabledQuarters = [0, 15, 30, 45]

        locations.forEach((location) => {

            const selectedStartDateMoment = moment(periodRange.startDate)
            const selectedDayName = FormatDayNumberToName(selectedStartDateMoment.isoWeekday())
            const availableOpeningTimes = getAvailableOpeningTimes(location.opening_times, periodRange.startDate)

            availableOpeningTimes.forEach((openingTime) => {
                const openingDay = openingTime.opening_days[selectedDayName]
                const openFromTime = parseInt(openingDay.open_from)
                const openToTime = parseInt(openingDay.open_to)

                if (openFromTime && openFromTime < earliestHour) {
                    earliestHour = openFromTime
                }

                if (openToTime && openToTime > latestHour) {
                    setLastHour(openToTime)
                    latestHour = openToTime - 1
                    latestMinute = moment('01-01-2020 ' + openingDay.open_to, 'DD-MM-YYYY HH:mm').format('mm')
                }
            })
        })

        disabledHours = disabledHours.filter((hour) => {
            if (chosenUnit?.type.code === 'hours') {
                if (datesAreOnSameDay(new Date(), periodRange.startDate)) {
                    return hour < moment().hours() || hour < earliestHour || hour > (latestHour - (chosenUnit.amount * stepAmount) + 1)
                } else {
                    return hour < earliestHour || hour > (latestHour - (chosenUnit.amount * stepAmount) + 1)
                }
            } else if (chosenUnit?.type.code === 'quarters') {
                if (datesAreOnSameDay(new Date(), periodRange.startDate)) {
                    return hour < moment().hours() || hour < earliestHour || hour > parseInt(latestHour - (chosenUnit.amount * (stepAmount / 4)) + 1)
                } else {
                    return hour < earliestHour || hour > parseInt(latestHour - (chosenUnit.amount * (stepAmount / 4)) + 1)
                }
            } else {
                if (datesAreOnSameDay(new Date(), periodRange.startDate)) {
                    return hour < moment().hours() || hour < earliestHour || hour > latestHour
                } else {
                    return hour < earliestHour || hour > latestHour
                }
            }
        })

        if (disabledHours.length < 25 && chosenUnit?.time_required) {
            disabledQuarters = disabledQuarters.filter((quarter) => {
                if ((chosenUnit?.type.code === 'hours' || chosenUnit?.type.code === 'quarters') && datesAreOnSameDay(new Date(), periodRange.startDate) && parseInt(startTime) <= moment().hours()) {
                    return quarter <= moment().minutes()
                }
                if (chosenUnit?.type.code === 'hours') {
                    if (quarter !== 0 && parseInt(startTime) === parseInt(latestHour - (chosenUnit.amount * stepAmount) + 1)) {
                        return latestMinute < quarter
                    }
                    return false
                } else if (chosenUnit?.type.code === 'quarters') {
                    if (quarter !== 0 && parseInt(startTime) === parseInt(latestHour - (chosenUnit.amount * (stepAmount / 4)) + 1)) {
                        return (60 * Math.ceil(moment.duration(timeAmount, 'minutes').asHours()) - (15 * (chosenUnit.amount * stepAmount))) < quarter
                    }
                    return false
                }
            })
        }

        const startHour = startTime.split(':')[0]
        const startMinute = startTime.split(':')[1]

        if (chosenUnit && chosenUnit?.time_required) {
            if (disabledHours.includes(parseInt(startHour))) {
                setShowNextButton(false)
                setAmountButtonDisabled(true)
                disabledQuarters = [0, 15, 30, 45]
            } else {
                setShowNextButton(true)
            }

            if (disabledQuarters.includes(parseInt(startMinute))) {
                setAmountButtonDisabled(true)
            }

            setDisabledQuarters(disabledQuarters)
            setDisabledHours(disabledHours)
        }


    }, [app.openedLocations, startTime, stepAmount])

    useEffect(() => {
            const webshopObject = {
                id: app.webshop.id,
            }
            dispatch(list(webshopObject)).then((response) => {
                if (response.payload && response.payload.length === 1) {
                    dispatch(addTimeUnit(response.payload[0]))
                }
            })
        }, []
    );

    useEffect(() => {
        calculateFunction(chosenUnit, periodRange, startTime, stepAmount, timeAmount)
    }, [startTime])

    useEffect(() => {

        if ((lastHour || !chosenUnit?.time_required) && chosenUnit) {

            const startMoment = moment('01-01-2020 ' + moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm'), 'DD-MM-YYYY HH:mm')

            if (chosenUnit?.type.code === 'hours') {

                const latestTime = lastHour - (chosenUnit.amount * chosenUnit.min_amount) + ':00'
                const latestMoment = moment('01-01-2020 ' + latestTime, 'DD-MM-YYYY HH:mm')

                if (startMoment.isSameOrAfter(latestMoment)) {
                    dispatch(editStartTime(latestMoment.format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, latestMoment.format('HH:mm'), chosenUnit.min_amount, timeAmount)
                } else {
                    dispatch(editStartTime(moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm'), chosenUnit.min_amount, timeAmount)
                }
            } else if (chosenUnit?.type.code === 'quarters') {

                const latestTime = parseInt(lastHour - (chosenUnit.amount * (chosenUnit.min_amount / 4))) + ':' + (60 * Math.ceil(moment.duration(timeAmount, 'minutes').asHours()) - (15 * (chosenUnit.min_amount * stepAmount)))
                const latestMoment = moment('01-01-2020 ' + latestTime, 'DD-MM-YYYY HH:mm')

                if (startMoment.isSameOrAfter(latestMoment)) {
                    dispatch(editStartTime(latestMoment.format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, latestMoment.format('HH:mm'), chosenUnit.min_amount, timeAmount)
                } else {
                    dispatch(editStartTime(moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm'), chosenUnit.min_amount, timeAmount)
                }
            } else {

                const latestTime = lastHour + ':00'
                const latestMoment = moment('01-01-2020 ' + latestTime, 'DD-MM-YYYY HH:mm')

                if (startMoment.isSameOrAfter(latestMoment)) {
                    dispatch(editStartTime(latestMoment.format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, latestMoment.format('HH:mm'), chosenUnit.min_amount, timeAmount)
                } else {
                    dispatch(editStartTime(moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm')))
                    calculateFunction(chosenUnit, periodRange, moment('01-01-2020 ' + moment().add(15 - (moment().minute() % 15), 'minutes').add(1, 'hours').format('HH:mm'), 'DD-MM-YYYY HH:mm').format('HH:mm'), chosenUnit.min_amount, timeAmount)
                }
            }
        }

    }, [lastHour, chosenUnit])


    useEffect(() => {
        setTimeInput(document.getElementById('time-input'))
        setNumberInput(document.getElementById('number-input'))

        if (chosenUnit) {
            setQuarterStep(900 * chosenUnit.amount)
            dispatch(editStepAmount(chosenUnit.min_amount))
            dispatch(editNumberAmount(chosenUnit.min_amount * chosenUnit.amount))
            dispatch(editTimeAmount(secondsToTime(900 * chosenUnit.min_amount)))
        }
    }, [chosenUnit])


    useEffect(() => {
        if (calculation) {
            dispatch(AppAction.setPeriod(calculation)).then(() => {
                dispatch(AppAction.setStartTime(moment('01-01-2020 ' + calculation.start_time, 'DD-MM-YYYY HH:mm').format('HH:mm')))
                dispatch(AppAction.setEndTime(moment('01-01-2020 ' + calculation.end_time, 'DD-MM-YYYY HH:mm').format('HH:mm')))
                dispatch(AppAction.setTimeAmount(stepAmount))
                dispatch(AppAction.setTimeUnitId(chosenUnit.id))
                dispatch(AppAction.setTimeUnit(chosenUnit))
                dispatch(ShoppingCartAction.empty())
                setTimeout(() => {
                    dispatch(AppAction.setOpenedLocations())
                }, 20)
            })
        }
    }, [calculation])


    const timeStepUp = () => {
        if (stepAmount < chosenUnit.max_amount) {
            timeInput.stepUp()
            dispatch(editStepAmount(stepAmount + 1))
            dispatch(editTimeAmount(timeInput.value))
            calculateFunction(chosenUnit, periodRange, startTime, stepAmount + 1, timeInput.value)
        }
    }

    const timeStepDown = () => {
        if (stepAmount > 1) {
            timeInput.stepDown()
            dispatch(editStepAmount(stepAmount - 1))
            dispatch(editTimeAmount(timeInput.value))
            calculateFunction(chosenUnit, periodRange, startTime, stepAmount - 1, timeInput.value)
        }
    }

    const numberStepUp = () => {
        if (stepAmount < chosenUnit.max_amount) {
            numberInput.stepUp()
            dispatch(editStepAmount(stepAmount + 1))
            dispatch(editNumberAmount(numberInput.value))
            calculateFunction(chosenUnit, periodRange, startTime, stepAmount + 1, timeAmount)
        }
    }

    const numberStepDown = () => {
        if (stepAmount > 1) {
            numberInput.stepDown()
            dispatch(editStepAmount(stepAmount - 1))
            dispatch(editNumberAmount(numberInput.value))
            calculateFunction(chosenUnit, periodRange, startTime, stepAmount - 1, timeAmount)
        }
    }

    const clickBicycleSelect = () => {
        dispatch(AppAction.switchAppStep(RouteEnum.Bicycles))
    }

    const clickAccommodation = () => {
        dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))
    }

    useEffect(() => {
        if (timeUnits && !!timeUnits.length) {

            timeUnits.forEach((timeUnit) => {

                if (!!timeUnit.is_default) {
                    dispatch(addTimeUnit(timeUnit));
                }

            })

        }

    }, [timeUnits])

    if (timeUnits && timeUnits.length > 0) {

        return (
            <div className="o-container o-container--primary">

                {timeUnits.length !== 1 &&
                    <div className="c-period-picker">
                        <h5>{Translate('Ik zou graag willen huren voor:', app.language)}</h5>

                        <div className="c-period-picker__selection">

                            {timeUnits.map((unit) => {

                                    let display = false

                                    if (unit.range_active === 0) {
                                        display = true
                                    }

                                    unit.ranges.map((range) => {
                                        const today = moment().startOf("day")
                                        const startDate = moment(range.start_date).startOf("day")
                                        const endDate = moment(range.end_date).startOf("day")
                                        const momentStartTime = moment(range.start_time, 'HH:mm')

                                        if (startDate.isSameOrBefore(today) && endDate.isAfter(today)) {
                                            display = true
                                            if (startDate.isSame(today)) {

                                                const currentTime = moment()
                                                const startTime = moment().set({
                                                    hour: momentStartTime.get('hour'),
                                                    minute: momentStartTime.get('minute')
                                                })

                                                if (startTime.isSameOrAfter(currentTime)) {
                                                    display = false
                                                }
                                            }
                                        }

                                    })


                                    if (display) {


                                        return (
                                            <div className="c-period-picker__selection__item">
                                                <button onClick={() => dispatch(addTimeUnit(unit))}
                                                        style={app.webshop.styles.light}
                                                        className="btn btn--rounded btn--primary">
                                                    <span>{Translate('Huren per', app.language)} {unit.amount} {Translate(unit.type.name, app.language)} </span>
                                                </button>
                                                <div className="btn btn--pill" style={app.webshop.styles.dark}>
                                                    <span>{Translate('Tot', app.language)} {unit.max} {Translate(unit.type.name, app.language)}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            )}
                        </div>
                    </div>
                }


                {!!chosenUnit &&
                    <>
                        {shopIsTypeRecurring ?

                            <RecurringPeriodDisplay
                                numberStepUp={() => numberStepUp()}
                                numberStepDown={() => numberStepDown()}
                                clickBicycleSelect={() => clickBicycleSelect()}
                                clickAccommodation={() => clickAccommodation()}
                                minDate={minDate}
                                quarterSteps={quarterSteps}
                                amountButtonDisabled={amountButtonDisabled}
                                disabledHours={disabledHours}
                                disabledQuarters={disabledQuarters}
                                showNextButton={showNextButton}
                                changePeriod={(values) => changePeriod(values)}
                            />

                            : (chosenUnit.type.code === 'days' && chosenUnit.amount === 1 && !chosenUnit.time_required) ?

                                <OldPeriodDisplay
                                    clickBicycleSelect={() => clickBicycleSelect()}
                                    clickAccommodation={() => clickAccommodation()}
                                    minDate={minDate}
                                />
                                :
                                <NewPeriodDisplay
                                    timeStepUp={() => timeStepUp()}
                                    timeStepDown={() => timeStepDown()}
                                    numberStepUp={() => numberStepUp()}
                                    numberStepDown={() => numberStepDown()}
                                    clickBicycleSelect={() => clickBicycleSelect()}
                                    clickAccommodation={() => clickAccommodation()}
                                    minDate={minDate}
                                    quarterSteps={quarterSteps}
                                    amountButtonDisabled={amountButtonDisabled}
                                    disabledHours={disabledHours}
                                    disabledQuarters={disabledQuarters}
                                    showNextButton={showNextButton}
                                    changePeriod={(values) => changePeriod(values)}

                                />
                        }

                    </>
                }

            </div>
        )
    } else {
        return (
            <div className="o-container o-container--primary">
                <h5 style={{margin: '4rem 0'}}>{Translate('Er zijn geen verhuur periode beschikbaar.', app.language)}</h5>
            </div>
        )
    }

}


export default Period;
