import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
});

class InsurancePopup extends React.Component {


    render() {

        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">
                    <button className="c-modal__close" onClick={() => this._onClickClosePopup()}>
                        <i className="icon icon-cross"/>
                    </button>
                    <div className="c-modal__header">
                        <h2><i
                            className="icon icon-info"/> {Translate('Informatie over de *item_single*verzekering', this.props.language)}
                        </h2>
                    </div>
                    <div className="c-modal__body">
                        <div>
                            {!!this.props.popup.insurance.insuranceTitle &&
                            <h4>{this.props.popup.insurance.insuranceTitle}</h4>
                            }
                            {!!this.props.popup.insurance.insuranceText &&
                            <p className="c-modal__body__text" dangerouslySetInnerHTML={{ __html: this.props.popup.insurance.insuranceText }}></p>
                            }
                            {!!this.props.popup.insurance.additional_terms &&
                            <p className="c-modal__body__link"><a href={this.props.popup.insurance.additional_terms} target="_blank">Klik hier voor aanvullende voorwaarden</a></p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _onClickClosePopup() {
        this.props.dispatch(AppAction.changeInsurancePopup({
            show: false,
            title: false,
            text: false,
            additional_terms: false
        }))
    }

}

export default connect(mapStateToProps)(InsurancePopup);
