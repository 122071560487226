import React from 'react'
import {connect} from "react-redux";
import ReactMapboxGl, {Marker} from 'react-mapbox-gl';
import AppAction from "../../../stores/app/AppAction";
import CalculateMapBounds from "../../../utilities/etc/CalculateMapBounds";
import Translate from "../../../utilities/etc/Translate";


const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiaWRvdHdlYmVuZ2luZWVycyIsImEiOiJja2Zucnp2Z2Yxbm93MzBxaDNrNDh1cGZ0In0.sUN7_6eNtALA2jGJ6PgpBQ'
});

const mapStateToProps = (state) => ({
    ...state.app
});

class LocationsPopup extends React.Component {

    render() {

        let locations = Object.values(this.props.locations)

        const activeId = this.props.popup.location.id

        return (

            <div className="c-modal">
                <div className="c-modal__bg" />
                <div className="c-modal__content c-modal__content--locations">
                    <button className="c-modal__close" onClick={() => this._onClickClosePopup()}><i
                        className="icon icon-cross" /></button>
                    <div className="c-modal__map">
                        <Map
                            // eslint-disable-next-line
                            style="mapbox://styles/idotwebengineers/ckfnsaisf3jtt19l20ndd0bmh"
                            fitBounds={CalculateMapBounds(locations)}
                            containerStyle={{
                                height: '100%',
                            }}
                        >
                            {locations.map((location, index) => {
                                let mainClass = 'c-marker'

                                if (location.id === activeId) {
                                    mainClass = mainClass + ' is-selected';
                                }

                                return (
                                    <Marker coordinates={[location.long, location.lat]} anchor="bottom"
                                            key={'marker-' + index}>
                                        <div className={mainClass}>{index + 1}</div>
                                    </Marker>
                                )
                            })}


                        </Map>
                    </div>
                    <div className="c-modal__locations">
                        <h2>{Translate('Onze vestigingen', this.props.language)}</h2>
                        <p>
                            {Translate('Er zijn', this.props.language)} {locations.length}   {Translate('vestigingen', this.props.language)}
                        </p>
                        <div className="c-modal__locations__container">
                            {locations.map((location, index) => {

                                let className = 'c-modal__locations__single'

                                if (activeId === location.id) {
                                    className = className + ' c-modal__locations__single--selected';
                                }

                                return (
                                    <div className={className}>
                                        <div className="c-modal__locations__single__number">
                                            <span>{index + 1}</span>
                                        </div>
                                        <div className="c-modal__locations__single__info">
                                            <span><strong>{location.name}</strong></span>
                                            <span>{location.address}</span>
                                            <span>{location.primary_phone}</span>
                                            <span>{location.primary_email}</span>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    _onClickClosePopup() {
        this.props.dispatch(AppAction.changeLocationPopup({
            show: false,
            id: false
        }))
    }

}

export default connect(mapStateToProps)(LocationsPopup);