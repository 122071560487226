import moment from 'moment'

const CalculateDaysBetween = (startDate, endDate) => {

    if (moment(endDate).diff(moment(startDate), "days") === 0 || (moment(startDate).format('HH:mm') === '00:00' && moment(endDate).format('HH:mm') === '23:59')){
        return moment(endDate).diff(moment(startDate), "days") + 1
    }else{
        return moment(endDate).diff(moment(startDate), "days")
    }

}

export default CalculateDaysBetween
