const CalculateMapBounds = (locations) => {
    let bounds = []
    const spacing = 0.1

    locations.forEach((location) => {
        if (location.long && location.lat) {
            bounds.push({lat: parseFloat(location.long), lng: parseFloat(location.lat)})
        }
    })

    let lat = bounds.map(function (p) {
        return p.lat
    });
    let lng = bounds.map(function (p) {
        return p.lng
    });

    let min_coords = [
        Math.min.apply(null, lat) - spacing,
        Math.min.apply(null, lng) - spacing
    ]

    let max_coords = [
        Math.max.apply(null, lat) + spacing,
        Math.max.apply(null, lng) + spacing
    ]

    return [min_coords, max_coords];
}

export default CalculateMapBounds
