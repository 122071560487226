const MapLocationAvailability = (state, action) => {

    const returnObject = {}

     Object.keys(state.locations).forEach((locationid) => {

        const location = state.locations[locationid];

        returnObject[locationid] = {}

        if (action.payload.includes(parseInt(locationid))) {

            returnObject[locationid] = {
                ...location,
                excluded: true
            }

        } else {
            returnObject[locationid] = {
                ...location,
                excluded: false
            }

        }

    })


    return returnObject

}

export default MapLocationAvailability