import React from 'react'
import {connect} from "react-redux";
import InsuranceAction from "../../../stores/api/insurance/InsuranceAction";
import CreateOrUpdateRental from "../../../utilities/dispatchers/CreateUpdateRental";
import Translate from "../../../utilities/etc/Translate";
import AppAction from "../../../stores/app/AppAction";
import Loading from "../../_components/Loading";
import * as Sentry from "@sentry/react";

const mapStateToProps = (state) => ({
    ...state,
    ...state.app,
    cart: state.shoppingCart,
    insuranceItems: state.insurance.data,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation
});

class Insurances extends React.Component {


    componentDidMount() {


        this.props.dispatch(InsuranceAction.list()).then(() => {

            if (this.props.insurance.insurance_required && this.props.insuranceItems.length > 0) {

                if (this.props.insurance.default_insurance_id) {

                    if (this.props.webshop.id === 91) {
                        const transaction = Sentry.startTransaction({name: "Default insurance loaded on Blackbikes shop"});
                        transaction.finish();
                    }

                    this.props.dispatch(InsuranceAction.setInsurance(this.props.insurance.default_insurance_id)).then(() => {
                        CreateOrUpdateRental(this.props)
                    })
                } else {

                    if (this.props.webshop.id === 91) {
                        const transaction = Sentry.startTransaction({name: "First insurance set on Blackbikes shop"});
                        transaction.finish();
                    }

                    this.props.dispatch(InsuranceAction.setInsurance(this.props.insuranceItems[0].id)).then(() => {
                        CreateOrUpdateRental(this.props)
                    })
                }

            } else {
                if (this.props.webshop.id === 91) {
                    const transaction = Sentry.startTransaction({name: "No insurance loaded on Blackbikes shop"});
                    transaction.finish();
                }
            }

        })
    }


    render() {

        const insuranceIcon = '/images/insurance-icon.svg'

        if (!this.props.insurance.loading && !this.props.webshop.hide_insurance) {

            if (!!this.props.insuranceItems && (this.props.insuranceItems.length >= 1 || !this.props.insurance.default_insurance_id)) {
                return (
                    <div className="c-order-overview__insurance">
                        <header>
                            <div>
                                <div>
                                    <img src={insuranceIcon} alt="insurance"/>
                                    <h3>{Translate('*item_single_cap*verzekering', this.props.language)}</h3>
                                </div>
                            </div>
                            <p>
                                {!this.props.info.infoData.detail.webshops?.partner?.client_id === 8 &&
                                Translate('Met onze fietsverzekering zorgen wij voor een zorgeloze rit. Onze verzekering beschermt je tegen diefstal en schade, zodat je met een gerust hart kunt genieten van je fietservaring.', this.props.language)}
                                {this.props.info.infoData.detail.webshops?.partner?.client_id !== 8 &&
                                Translate('Wij bieden je de mogelijkheid om *item_plural* tijdens de huurperiode te verzekeren. Zo ben je verzekerd bij ongevallen, diefstal en schade.', this.props.language)
                                }
                            </p>
                        </header>
                        <div className="c-order-overview__insurance__wrap">
                            {this.props.insuranceItems?.map((item) => (
                                <div className="c-order-overview__insurance__single">
                                    <div className="c-order-overview__insurance__single__text"
                                         onClick={(e) => this._onClickInsurance(item.id)}>
                                        <div className="c-delivery-options__check">
                                            {item.id === this.props.insurance.selected && <figure/>}
                                        </div>
                                        <span>{Translate('Ik wil', this.props.language)} {item.name} {Translate('verzekering afsluiten voor', this.props.language)} {item.price} </span>
                                    </div>
                                    <i onClick={() => this._onClickOpenPopup(item.name, item.webshop_text, item.additional_terms)}
                                       className="icon icon-info insurance-icon-info"/>
                                </div>
                            ))}
                            {!this.props.insurance.insurance_required &&
                            <div className="c-order-overview__insurance__single"
                                 onClick={(e) => this._onClickInsurance(0)}>
                                <div className="c-order-overview__insurance__single__text">
                                    <div className="c-delivery-options__check">
                                        {this.props.insurance.selected === 0 && <figure/>}
                                    </div>
                                    <span>{Translate('Ik wil geen *item_single*verzekering afsluiten', this.props.language)}</span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                )
            } else return null

        } else {
            if (!this.props.webshop.hide_insurance) {
                return (
                    <div className="c-order-overview__insurance">
                        <Loading width={"40"} height={"40"} removeText={true}/>
                    </div>
                )
            }

            return null

        }

    }

    _onClickInsurance = (value) => {
        this.props.dispatch(InsuranceAction.setInsurance(value)).then(() => {
            CreateOrUpdateRental(this.props)
        })
    }


    _onClickOpenPopup = (title, text, terms) => {
        this.props.dispatch(AppAction.changeInsurancePopup({
            show: true,
            insuranceTitle: title,
            insuranceText: text,
            additional_terms: terms
        }))
    }

}

export default connect(mapStateToProps)(Insurances);
