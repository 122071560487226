import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import Translate from "../../utilities/etc/Translate";

const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.rental,
    ...state.app
});

class Failed extends React.Component {

    componentDidMount() {
        this.props.dispatch(AppAction.switchAppStep(7))
    }

    render() {
        return (

            <div style={{padding: '4rem'}}>

                <p>

                    {Translate('Helaas, uw betaling is mislukt', this.props.language)}

                </p>

            </div>
        )
    }

}

export default connect(mapStateToProps)(Failed);

