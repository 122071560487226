import DisplayItemName from "../displayers/DisplayItemName";

const StickyString = (bicycles) => {

    let string = ''

    if (bicycles) {
        bicycles.forEach((bicycle) => {
            string += bicycle.selected + 'x ' + DisplayItemName(bicycle) + ', '
        })

    }

    string = string.replace(/,\s*$/, "");

    return string

}

export default StickyString