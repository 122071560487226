const MapValuesAsGetParameters = (values) => {

    let getParamString = '?'

    Object.keys(values).forEach((key) => {
        const value = values[key];
        getParamString += key + '=' + value + '&'
    })

    return getParamString.slice(0, -1)
}

export default MapValuesAsGetParameters;