import React from 'react'
import AccessoireItem from "./_components/AccessoireItem";
import {connect} from "react-redux";
import RouteEnum from "../../constants/RouteEnum";
import AppAction from "../../stores/app/AppAction";
import SetAndValidateItems from "../../utilities/dispatchers/SetAndValidateItems";
import {CSSTransition} from "react-transition-group";
import StickyBar from "./_components/StickyBar";
import Translate from "../../utilities/etc/Translate";
import SkipAccessoireSelection from "../../utilities/etc/SkipAccessoireSelection";
import Loading from "../_components/Loading";

const mapStateToProps = (state) => ({
    ...state.app,
    cart: state.shoppingCart,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation
});

class Accessoires extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {

        this.props.scroll()

        if (this.props.cart.bicycles.length > 0) {

            this.props.dispatch(AppAction.switchStep(1))

            this.props.dispatch(AppAction.setSummary({
                period: true,
                bicycles: true,
                accessoires: false,
                accommodation: false,
                delivery: false
            }))

            SetAndValidateItems(this.props, 'accessoire')

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // Check if the accessories are loaded
        if (this._showAccessoires() && this.state.loaded === false) {

            this.props.dispatch(AppAction.setAccessoireAvailability())

            this.setState({
                loaded: true
            })
        }

        // Check if there are any accessoires. If not, redirect to acoommodation

        if (this.state.loaded && SkipAccessoireSelection(this.props) && this.props.step === RouteEnum.Accessoires) {

            this.props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))
        }

    }


    render() {

        if (this._showAccessoires()) {

            return (
                <div className="o-container o-container--primary">
                    <CSSTransition
                        in={this.state.loaded}
                        timeout={500}
                        classNames="fade"
                    >

                        <div key={'accessoire-view'}>
                            <header className="c-subject__header c-subject__header--split">
                                <h2>{Translate('Selecteer uw accessoires', this.props.language)}</h2>
                            </header>

                            <div className="c-products">
                                {Object.values(this.props.accessoires)
                                    .filter(item => {
                                        return !item.inactive
                                    })
                                    .sort((a, b) => a.order > b.order ? 1 : -1)
                                    .map((accessoireData) => {
                                        return (
                                            <AccessoireItem
                                                key={accessoireData.id + '-accessoire-key'}
                                                id={accessoireData.id}
                                                data={accessoireData}
                                            />
                                        )
                                    })}
                                {Object.values(this.props.accessoires)
                                    .filter(item => {
                                        return item.inactive
                                    })
                                    .sort((a, b) => a.order > b.order ? 1 : -1)
                                    .map((accessoireData) => {
                                        return (
                                            <AccessoireItem
                                                key={accessoireData.id + '-accessoire-key'}
                                                id={accessoireData.id}
                                                data={accessoireData}
                                            />
                                        )
                                    })}
                            </div>

                            <StickyBar/>

                        </div>

                    </CSSTransition>
                </div>
            )


        } else {
            return (
                <div className={'c-loader'} key={'accessoire-loader'}>
                    <Loading />
                </div>
            )
        }
    }

    _showAccessoires = () => {
        return this.props.setAndValidationComplete && this.props.accessoires

    }

    _clickNext() {
        this.props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))
    }
}

export default connect(mapStateToProps)(Accessoires);
