import ActionUtility from '../../../utilities/etc/ActionUtility'
import InsuranceEffect from "./InsuranceEffect";
import moment from "moment";
import FormatCartToRetailRequest from "../../../utilities/formatters/FormatCartToRetailRequest";
import FormatCartToInsuranceRequest from "../../../utilities/formatters/FormatCartToInsuranceRequest";

export default class InsuranceAction {

    static REQUEST_LIST = 'InsurancesAction.REQUEST_LIST';
    static SET_INSURANCE = 'InsurancesAction.SET_INSURANCE';

    static list() {
        return async (dispatch, getState) => {

            const values = {
                accessToken: getState().auth.accessToken,
                webshop_id: getState().app.webshop.id,
                timeUnitId: getState().app.timeUnitId,
                startDate: moment(getState().app.period.startDate).format('YYYY-MM-DD') + ' ' + getState().app.startTime,
                endDate: moment(getState().app.period.endDate).format('YYYY-MM-DD') + ' ' + getState().app.endTime,
                categories: FormatCartToInsuranceRequest(getState().shoppingCart),
            }

            await ActionUtility.createThunkEffect(dispatch, InsuranceAction.REQUEST_LIST, InsuranceEffect.list, values);
        };
    }

    static setInsurance(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, InsuranceAction.SET_INSURANCE, InsuranceEffect.setInsurance, value);
        };
    }

}
