import GeneralAction from './GeneralAction'

export default class CategoryReducer {

    static initialState = {
        languageErrors: {
            list: false
        },
        languageMessages: {
            list: false
        },
        languagePending: {
            list: false
        },
        languageData: {
            list: false
        },

        cityErrors: {
            list: false
        },
        cityMessages: {
            list: false
        },
        cityPending: {
            list: false
        },
        cityData: {
            list: false
        }
    };

    static reducer(state = CategoryReducer.initialState, action) {
        switch (action.type) {

            case GeneralAction.REQUEST_LANGUAGE_LIST:
                return {
                    ...state,
                    categoryErrors: {
                        ...state.languageErrors,
                        list: false
                    },
                    languageMessages: {
                        ...state.languageMessages,
                        list: false
                    },
                    languagePending: {
                        ...state.languagePending,
                        list: true
                    },
                    languageData: {
                        ...state.languageData,
                        list: false
                    },
                };
            case GeneralAction.REQUEST_LANGUAGE_LIST + '_FINISHED':
                return {
                    ...state,
                    languageErrors: {
                        ...state.languageErrors,
                        list: action.payload.error
                    },
                    categoryMessages: {
                        ...state.languageMessages,
                        list: action.payload.message
                    },
                    languagePending: {
                        ...state.languagePending,
                        list: false
                    },
                    languageData: {
                        ...state.languageData,
                        list: action.payload.response
                    },
                };

            case GeneralAction.REQUEST_CITY_LIST:
                return {
                    ...state,
                    cityErrors: {
                        ...state.languageErrors,
                        list: false
                    },
                    cityMessages: {
                        ...state.languageMessages,
                        list: false
                    },
                    cityPending: {
                        ...state.languagePending,
                        list: true
                    },
                    cityData: {
                        ...state.languageData,
                        list: false
                    },
                };

            case GeneralAction.REQUEST_CITY_LIST + '_FINISHED':
                return {
                    ...state,
                    cityErrors: {
                        ...state.languageErrors,
                        list: action.payload.error
                    },
                    cityMessages: {
                        ...state.languageMessages,
                        list: action.payload.message
                    },
                    cityPending: {
                        ...state.languagePending,
                        list: false
                    },
                    cityData: {
                        ...state.languageData,
                        list: action.payload.response
                    },
                };

            default:
                return state;
        }
    }
}