import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import AppAction from "../../../stores/app/AppAction";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";
import TotalPrice from "./TotalPrice";

const mapStateToProps = (state) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
    rental: state.rental.rentalData.update
});

const changeBicycles = (props) => {
    props.dispatch(AppAction.switchAppStep(RouteEnum.Bicycles)).then(() => {
        props.dispatch(AppAction.setSummary({
            period: true,
            bicycles: false,
            accessoires: false,
            accommodation: false,
            delivery: false,
        }))
    })
}

const changeAccessoires = (props) => {
    props.dispatch(AppAction.switchAppStep(RouteEnum.Accessoires)).then(() => {


        props.dispatch(AppAction.setSummary({
            period: true,
            bicycles: true,
            accessoires: false,
            accommodation: false,
            delivery: false,
        }))
    })
}

const SummaryTotals = (props) => {

    const bicycles = props.shoppingCart.bicycles
    const accessoires = props.shoppingCart.accessoires


    if (bicycles.length > 0) {

        return (
            <div className="c-summary c-summary--totals">
                {props.summary.bicycles &&
                    <>
                        <i className="c-summary__check icon icon-check-circle"/>
                        <div className="c-summary__subject">
                            <div className="c-summary__subject__header">
                                <h5>{Translate('Uw geselecteerde *item_plural*', props.language)}</h5>
                                <button onClick={() => changeBicycles(props)}
                                        className={'c-summary__edit'}>
                                    <span>{Translate('Wijzigen', props.language)}</span><i className="icon icon-edit"/>
                                </button>
                            </div>
                            {Object.keys(bicycles).map((bicycleKey) => {
                                const bicycleCart = bicycles[bicycleKey]
                                const bicycleData = props.bicycles[bicycleCart.id]

                                if (bicycleData) {
                                    return (
                                        <div className="c-summary__line" key={'summary-bicycle-' + bicycleKey}>
                                            <span className="c-summary__amount">{bicycleCart.selected}x</span>
                                            <span className="c-summary__product">{DisplayItemName(bicycleData)}</span>
                                            <span
                                                className="c-summary__cost">{DisplayPrice(bicycleCart.selected * bicycleData.price)}</span>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                }

                <>
                    {props.summary.accessoires && accessoires.length > 0 &&
                        <div className="c-summary__subject">
                            <div className="c-summary__subject__header">
                                <h5>{Translate('Uw geselecteerde accessoires', props.language)}</h5>
                                <button onClick={() => changeAccessoires(props)}
                                        className={'c-summary__edit'}
                                        to={props.slug + RouteEnum.Accessoires}>
                                    <span>{Translate('Wijzigen', props.language)}</span><i
                                    className="icon icon-edit"/>
                                </button>
                            </div>
                            {Object.keys(accessoires).map((accesoireKey) => {
                                const accessoireCart = accessoires[accesoireKey]
                                const accessoireData = props.accessoires[accessoireCart.id]

                                if (accessoireData) {
                                    return (
                                        <div className="c-summary__line" key={'summary-accesoire-' + accesoireKey}>
                                            <span className="c-summary__amount">{accessoireCart.selected}x</span>
                                            <span
                                                className="c-summary__product">{DisplayItemName(accessoireData)}</span>
                                            <span
                                                className="c-summary__cost">{DisplayPrice(accessoireCart.selected * accessoireData.price)}</span>
                                        </div>
                                    )
                                } else {
                                    return null
                                }

                            })}
                        </div>
                    }

                    <TotalPrice {...props}/>

                </>

            </div>

        )
    } else {
        return null
    }

}

export default connect(mapStateToProps)(SummaryTotals);