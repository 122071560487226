import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import ApiEnum from "../../constants/ApiEnum";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});
const refreshAuthLogic = failedRequest =>
    instance
        .post(ApiEnum.Api + '/auth/refresh', {
            refresh_token: localStorage.getItem("refreshToken")
        })
        .then(tokenRefreshResponse => {

            localStorage.setItem("accessToken", tokenRefreshResponse.data.items.accessToken.access_token);
            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.items.accessToken.access_token;
            return Promise.resolve();
        })
        .catch(error => {
            console.log("refresh fail");
            localStorage.setItem("accessToken", null);
            localStorage.setItem("refreshToken", null);
            //pushToLogin();
            return Promise.reject(error);
        });
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
