import React from 'react'
import BicycleItem from "./_components/BicycleItem";
import StickyBar from "./_components/StickyBar";
import {connect} from "react-redux";
import AppAction from "../../stores/app/AppAction";
import SetAndValidateItems from "../../utilities/dispatchers/SetAndValidateItems";
import Loader from 'react-loader-spinner'

import {CSSTransition} from 'react-transition-group';
import Translate from "../../utilities/etc/Translate";
import Loading from "../_components/Loading";


const mapStateToProps = (state) => ({
    ...state.app,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation
});

class Bicycles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {

        this.props.scroll()

        this.props.dispatch(AppAction.setLocationAvailability())

        if (this.props.period.endDate) {

            this.props.dispatch(AppAction.switchStep(1)).then(() => {
                this.props.dispatch(AppAction.setSummary({
                    period: true,
                    bicycles: false,
                    accessoires: false,
                    accommodation: false,
                    delivery: false,
                })).then(() => {
                    SetAndValidateItems(this.props, 'bicycle')
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this._showBicycles() && Object.keys(this.props.bicycles).length !== 0 && this.state.loaded === false) {

            this.props.dispatch(AppAction.setLocationAvailability())

            this.setState({
                loaded: true
            })

        }
    }

    render() {

        if (this._showBicycles()) {

            const hasBicycles = Object.keys(this.props.bicycles).length !== 0

            return (
                <div className="o-container o-container--primary">

                    <CSSTransition
                        in={this.state.loaded}
                        timeout={500}
                        classNames="fade"
                    >
                        <div key={'bicycle-view'}>

                            {hasBicycles &&
                            <>
                                <header className="c-subject__header c-subject__header--split">
                                    <h2>{Translate('Selecteer uw gewenste *item_plural*', this.props.language)}</h2>
                                    <div className="c-subject__header__split">
                                    </div>
                                </header>


                                <div className="c-products">

                                    {Object.values(this.props.bicycles)
                                        .filter(item => {
                                            return !item.inactive
                                        })
                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                        .map((bicycleData) => {
                                            return (
                                                <BicycleItem
                                                    key={bicycleData.id + '-bicycle-key'}
                                                    id={bicycleData.id}
                                                    data={bicycleData}
                                                />
                                            )
                                        })}

                                    {Object.values(this.props.bicycles)
                                        .filter(item => {
                                            return item.inactive
                                        })
                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                        .map((bicycleData) => {
                                            return (
                                                <BicycleItem
                                                    key={bicycleData.id + '-bicycle-key'}
                                                    id={bicycleData.id}
                                                    data={bicycleData}
                                                />
                                            )
                                        })}
                                </div>

                                {!Object.values(this.props.bicycles).length &&
                                <p>{Translate('Helaas is er in de door u opgegeven periode geen beschikbaarheid', this.props.language)}</p>
                                }

                                <StickyBar/>

                            </>}

                            {!hasBicycles &&
                            <p style={{margin: '4rem 0'}}>{Translate('Er zijn geen *item_plural* beschikbaar in de geselecteerde periode. Waarschijnlijk is de periode te lang. Pas deze aan om een selectie te maken.', this.props.language)}</p>
                            }

                        </div>
                    </CSSTransition>
                </div>
            )
        } else {

            return (
                <div className={'c-loader'} key={'bicycle-loader'}>
                    <Loading/>
                </div>
            )
        }
    }

    _showBicycles = () => {
        return this.props.setAndValidationComplete && this.props.bicycles !== {}
    }

}


export default connect(mapStateToProps)(Bicycles);
