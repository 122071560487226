import React from 'react'
import RouteEnum from "../../../constants/RouteEnum";
import {connect} from "react-redux";
import StickyString from "../../../utilities/etc/StickyString";
import AppAction from "../../../stores/app/AppAction";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";

const mapStateToProps = (state, ownProps) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
});


class StickyBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidUpdate() {

        if (this.props.shoppingCart.accessoires.length > 0 && this.state.loaded === false) {
            this.setState({
                loaded: true
            })
        }

        if (this.props.shoppingCart.accessoires.length === 0 && this.state.loaded === true) {
            this.setState({
                loaded: false
            })
        }
    }

    render() {

        const props = this.props
        const cart = props.shoppingCart
        const accessoireString = StickyString(cart.accessoires)


        const _clickNext = () => {

            props.dispatch(AppAction.switchAppStep(RouteEnum.Accommodation))

        }

        return (


            <footer className="c-sticky-footer" key={'sticky-footer'}>
                <div className="c-sticky-footer__container">


                    <div className="c-sticky-footer__selection">
                        {!!cart.accessoires.length &&
                        <>
                            <span><strong>{Translate('Uw selectie', this.props.language)}</strong></span>
                            <span>{accessoireString}</span>

                        </>
                        }
                    </div>


                    <div className="c-sticky-footer__totals">
                        {!!cart.accessoires.length &&
                        <span><strong>{Translate('Totaal', this.props.language)} {DisplayPrice(cart.accessoirePrice)}</strong></span>}
                        <button style={props.webshop.styles.dark} onClick={() => _clickNext()}
                                className="btn btn--rounded"><span>{Translate('Doorgaan', this.props.language)}</span>
                        </button>
                    </div>

                </div>
            </footer>
        )
    }

}

export default connect(mapStateToProps)(StickyBar);