export const getAvailableOpeningTimes = (openingTimes, selectedStartDate) => {

    return openingTimes.filter((openingTime) => {

        if (openingTime.date_from && openingTime.date_to && selectedStartDate) {

            const startDate = new Date(openingTime.date_from.replace(/-/g, "/"))
            const endDate = new Date(openingTime.date_to.replace(/-/g, "/"))

            return selectedStartDate.setHours(0, 0, 0, 0) >= startDate.setHours(0, 0, 0, 0) && selectedStartDate.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0)

        } else return false
    })

}
