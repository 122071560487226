import ActionUtility from '../../../utilities/etc/ActionUtility'
import PartnerEffect from "./PartnerEffect";

export default class PartnerAction {
    static REQUEST_DETAIL = 'PartnerAction.REQUEST_DETAIL';

    static detail(values) {
        return async (dispatch, getState) => {
            values.dispatch = dispatch
            values.accessToken = getState().auth.accessToken
            await ActionUtility.createThunkEffect(dispatch, PartnerAction.REQUEST_DETAIL, PartnerEffect.requestDetail, values);
        };
    }

}
