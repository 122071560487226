import CalculateDaysBetween from "../../utilities/etc/CalculateDaysBetween";
import ValidateSlug from "../../utilities/etc/ValidateSlug";
import AccessoireConditions from "../../utilities/etc/AccessoireConditions";
import GetMinDate from "../../utilities/etc/GetMinDate";
import {addDays} from 'date-fns';
import CalculateDaysBetweenOld from "../../utilities/etc/CalculateDaysBetweenOld";


export default class AppEffect {

    static async setSlug(values) {

        values = ValidateSlug(values)

        return values;
    }

    static switchWizardStep(step) {
        return step;
    }

    static async setPeriod(values) {
        values.display = true

        if (values.startDate && values.endDate) {
            values.days = CalculateDaysBetween(values.startDate, values.endDate)
        }

        return values;
    }

    static async setPeriodOld(values) {


        return values;
    }


    static async setTime(values) {
        return values;
    }

    static async setStartTime(values) {
        return values;
    }

    static async setEndTime(values) {
        return values;
    }

    static async setTimeUnitId(values) {
        return values;
    }

    static async setTimeUnit(values) {
        return values
    }

    static async setTimeAmount(values) {
        return values;
    }

    static setSummary(step) {
        return step;
    }

    static setRentalId(rental) {
        return rental.rentalId
    }

    static formatItems(data) {
        const state = data.values

        const availabilityLocations = state.availability.availabilityData.perCategory.availability
        const shoppingCart = state.shoppingCart

        let items = {}

        Object.keys(availabilityLocations).forEach((locationId) => {
            const itemsPerLocation = availabilityLocations[locationId]



            Object.keys(itemsPerLocation).forEach((itemId) => {


                let accessoireConditions = false

                if (data.type === 'accessoires' && state.category && state.category.categoryData && state.category.categoryData.list.items) {
                    accessoireConditions = AccessoireConditions(itemId, state.category.categoryData.list.items.categories, shoppingCart)
                }



                // Check if is bicycle or the accessoire is allowed to be formatted due to conditions
                if (data.type === 'bicycles' || (data.type === 'accessoires' && accessoireConditions.formatAccessoire)) {

                    const locationItem = itemsPerLocation[itemId];

                    // Create a new key if it doesn't exist yet and add the default bicycle info not specified on a location
                    if (!(itemId in items)) {

                        items[itemId] = {}
                        items[itemId] = {...locationItem}
                        items[itemId].selected = 0
                        items[itemId].locations = {}

                        if (accessoireConditions) {
                            items[itemId].maxConditionSelection = accessoireConditions.maxConditionSelection
                        }

                        // Checking if the item is already added to the shopping cart
                        shoppingCart.bicycles.forEach((bicyle) => {
                            if (bicyle.id === parseInt(itemId)) {
                                items[itemId].selected = bicyle.selected
                            }
                        })

                        shoppingCart.accessoires.forEach((accessoire) => {
                            if (accessoire.id === parseInt(itemId)) {
                                items[itemId].selected = accessoire.selected
                            }
                        })
                    }

                    items[itemId].locations[locationId] = {}
                    items[itemId].locations[locationId].available = locationItem.available

                }

            })
        })

        return items

    }


    static formatWebshop(values) {

        let formattedWebshop = values.info.infoData.detail.webshops.webshop
        formattedWebshop.image = values.info.infoData.detail.webshops.webshop_image
        formattedWebshop.usps = values.info.infoData.detail.webshops.usps
        formattedWebshop.type = values.info.infoData.detail.webshops.type
        formattedWebshop.unbookable_items = values.info.infoData.detail.webshops.unbookable_items

        formattedWebshop.styles = {
            light: {
                color: '#000',
                background: values.info.infoData.detail.webshops.webshop.primary_color_light
            },
            dark: {
                color: '#fff',
                background: values.info.infoData.detail.webshops.webshop.primary_color_dark
            }
        }


        return {
            webshop: values.info.infoData.detail.webshops.webshop,
            firstStep: values.info.infoData.detail.webshops.webshop.landingspage_active ? 0 : 1 // Show landingspage step
        }
    }

    static formatLocations(values) {
        return values.info.infoData.detail.webshops.locations
    }

    static formatSubDeliveryLocations(subDeliveryLocations) {

        const returnItems = []

        Object.keys(subDeliveryLocations).forEach(itemKey => {
                const formattedSubItem = subDeliveryLocations[itemKey]
                formattedSubItem.numbers = []

                if (!formattedSubItem.name) {
                    formattedSubItem.name = ''
                }

                formattedSubItem.value = itemKey

                if (formattedSubItem.type === 'range') {


                    if (formattedSubItem.type === 'range') {
                        const start = parseInt(formattedSubItem.range_start)
                        const end = parseInt(formattedSubItem.range_end)
                        let increaseNumber

                        if (formattedSubItem.range_side === 'ascending') {
                            increaseNumber = 1
                        } else {
                            increaseNumber = 2
                        }

                        for (let i = start; i < end; i += increaseNumber) {
                            const number = {}
                            number.value = i
                            number.label = i
                            formattedSubItem.numbers.push(number)
                        }
                    }

                } else {
                    if (formattedSubItem.number) {
                        const number = {};
                        number.value = parseInt(formattedSubItem.number)
                        number.label = formattedSubItem.number
                        formattedSubItem.numbers.push(number)
                    }
                }

                const numberArray = Object.values(formattedSubItem.numbers)

                if (numberArray.length > 1) {
                    formattedSubItem.label = formattedSubItem.name + ' ' + formattedSubItem.street + ' ' + numberArray[0].label + '-' + numberArray[numberArray.length - 1].label
                } else if (numberArray.length) {
                    formattedSubItem.label = formattedSubItem.name + ' ' + formattedSubItem.street + ' ' + numberArray[0].label
                }

                returnItems.push(formattedSubItem)
            }
        )

        return returnItems
    }

    static formatDeliveryLocations(state) {
        return state.location.locationData.list.deliveryAddresses
    }


    static mutateSelectionNumber(values) {

        values.excludedLocations = []
        const selectedItemLocationIds = Object.keys(values.itemLocations);

        // Check if the selected bicycle is in both locations
        Object.values(values.locations).forEach((location) => {

            if (values.selected && values.selected !== 0 && !selectedItemLocationIds.includes(location.id.toString())) {
                values.excludedLocations.push(parseInt(location.id))
            }

        })

        let maxNumber = 0


        selectedItemLocationIds.forEach((locationId) => {
            const location = values.itemLocations[locationId]

            // Check if available is bigger than maxNumber
            if (location.available > maxNumber && !values.locations[locationId].excluded) {
                maxNumber = location.available
            }

        })

        // Don't allow smaller values than 0
        if (values.selected < 0) {
            values.selected = 0
        }

        // Dont allow the number to be bigger than the max availability
        if (values.selected > maxNumber) {
            values.selected = maxNumber
        }

        // Looping trough it again to check if it needs to be pushed
        Object.keys(values.itemLocations).forEach((locationId) => {
            const location = values.itemLocations[locationId]

            if (location.available < values.selected) {
                values.excludedLocations.push(parseInt(locationId))
            }

        })

        return values
    }

    static setLocationAvailability(excludedLocationsPerBicycle) {
        let excludedIds = []
        Object.keys(excludedLocationsPerBicycle).forEach((bicycleId) => {
            const excludedLocations = excludedLocationsPerBicycle[bicycleId]

            excludedLocations.forEach((locationid) => {
                if (!excludedIds.includes(locationid)) {
                    excludedIds.push(locationid)
                }
            })

        })

        return excludedIds

    }


    static setItemAvailability(values) {

        const items = {...values.items}


        Object.keys(values.items).forEach((itemId) => {
            const item = values.items[itemId]

            const itemLocations = item.locations
            let available = false

            Object.keys(itemLocations).forEach((itemLocationId) => {
                const location = values.locations[itemLocationId]
                const itemLocation = itemLocations[itemLocationId]

                if (!location.excluded && itemLocation.available > 0) {
                    available = true
                }
            })

            items[itemId] = {
                ...item,
                inactive: !available
            }

        })

        return items

    }

    static setFormData(values) {
        return values;
    }

    static setOpenedLocations(values) {
        return values;
    }

    static setDeliveryLocation(values) {
        return values;
    }

    static setSubDeliveryLocation(values) {
        return values;
    }

    static setSubLocationNumber(number) {
        return number;
    }

    static setDeliveryType(type) {
        return type;
    }

    static setPartnerLocation(partnerLocationId) {
        return partnerLocationId;
    }

    static changeItemPopup(values) {
        return values;
    }

    static changeLocationPopup(values) {
        return values;
    }

    static switchAppStep(step) {
        return step
    }

    static validateShoppingCart(props) {

        const cart = props.shoppingCart

        const validation = {
            bicycles: true,
            accessoires: true,
            valid: true,
        }


        if (props.app.delivery.partnerLocationId) {

            if (cart.bicycles.length) {

                cart.bicycles.forEach((cartBicycle) => {

                    const bicycle = props.app.bicycles[cartBicycle.id].locations[props.app.delivery.partnerLocationId].available

                    if (bicycle.available <= cartBicycle.selected) {
                        validation.bicycles = false
                        validation.valid = false
                    }

                })

            }

            if (cart.accessoires.length) {

                cart.accessoires.forEach((cartAccessoire) => {

                    const accessoire = props.app.accessoires[cartAccessoire.id].locations[props.app.delivery.partnerLocationId].available

                    if (accessoire.available < cartAccessoire.selected) {

                        validation.accessoires = false
                        validation.valid = false

                    }

                })
            }

        }

        return validation;
    }

    static setAndValidationComplete(complete) {
        return complete
    }

    static setDefaultLanguage() {
        const browserLanguage = navigator.language
        // Getting the first value for catching language codes like en-EN or nl-NL
        const languageCode = browserLanguage.split('-')[0];

        switch (languageCode) {
            case('nl'):
            case('en'):
            case('de'):
            case('fr'):
            case('es'):
            case('it'):
            case('pt'):
            //case('da'):
                return languageCode
            default:
                return 'en'
        }
    }

    static changeLanguage(language) {
        return language
    }

    static setCustomLocationValue(values) {

        return values

    }

    static setDefaultPeriod(state) {


        const startDate = GetMinDate(state.info.infoData.detail.webshops.webshop)

        if (state.info.infoData.detail.webshops.partner.client_id === 8) {
            return {
                startDate: null,
                endDate: new Date(""),
                days: 7,
                key: 'selection'
            }
        } else {
            if (state.app.webshop.id === 132) {
                return {
                    startDate: startDate,
                    endDate: addDays(startDate, 0),
                    days: 1,
                    key: 'selection'
                }
            } else {
                return {
                    startDate: startDate,
                    endDate: addDays(startDate, 6),
                    days: 7,
                    key: 'selection'
                }
            }

        }

    }

    static submitRegister() {
        return true
    }

    static reset(values) {
        return values;
    }

    static checkPeriodDiscount(discount) {
        return discount;
    }

}


