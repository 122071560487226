import InsuranceAction from './InsuranceAction'

export default class InsuranceReducer {

    static initialState = {
        data: false,
        selected: 0,
        loading: false
    };

    static reducer(state = InsuranceReducer.initialState, action) {
        switch (action.type) {

            case InsuranceAction.REQUEST_LIST:
                return {
                    ...state,
                    loading: true
                };

            case InsuranceAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    loading: false,
                    ...action.payload.response,
                };

            case InsuranceAction.SET_INSURANCE:
                return {
                    ...state
                };

            case InsuranceAction.SET_INSURANCE + '_FINISHED':

                return {
                    ...state,
                    selected: action.payload,
                    ...action.payload.response,
                };

            default:
                return state;
        }
    }
}
