import ActionUtility from '../../utilities/etc/ActionUtility'
import AppEffect from "./AppEffect";
import {filterLocations} from "../../utilities/etc/filterLocations";
import moment from "moment";
import {FormatDayNumberToName} from "../../utilities/formatters/FormatDayNumberToName";
import CalculateDaysBetweenOld from "../../utilities/etc/CalculateDaysBetweenOld";

export default class AppAction {
    static SET_SLUG = 'AppAction.SET_SLUG';
    static SWITCH_WIZARD_STEP = 'AppAction.SWITCH_WIZARD_STEP';
    static SWITCH_STEP = 'AppAction.SWITCH_STEP';
    static SET_PERIOD = 'AppAction.SET_PERIOD';
    static SET_TIME = 'AppAction.SET_TIME';

    static SET_START_TIME = 'AppAction.SET_START_TIME';
    static SET_END_TIME = 'AppAction.SET_END_TIME';

    static SET_TIME_UNIT_ID = 'AppAction.SET_TIME_UNIT_ID';
    static SET_TIME_UNIT = 'AppAction.Set_TIME_UNIT';

    static SET_TIME_AMOUNT = 'AppAction.SET_TIME_AMOUNT';

    static SET_SUMMARY = 'AppAction.SET_SUMMARY';
    static SET_DEFAULT_LANGUAGE = 'AppAction.SET_DEFAULT_LANGUAGE';

    static CHANGE_LANGUAGE = 'AppAction.CHANGE_LANGUAGE';


    static FORMAT_WEBSHOP = 'AppAction.FORMAT_WEBSHOP';
    static FORMAT_LOCATIONS = 'AppAction.FORMAT_LOCATIONS';

    static FORMAT_BICYCLES = 'AppAction.MODEL_BICYCLES';
    static FORMAT_ACCESSOIRES = 'AppAction.FORMAT_ACCESSOIRES';

    static FORMAT_DELIVERY_LOCATIONS = 'AppAction.FORMAT_DELIVERY_LOCATIONS';
    static FORMAT_SUB_DELIVERY_LOCATIONS = 'AppAction.FORMAT_SUB_DELIVERY_LOCATIONS';

    static SET_RENTAL_ID = 'AppAction.SET_RENTAL_ID';

    static MUTATE_BICYCLE_SELECTION_NUMBER = 'AppAction.MUTATE_BICYCLE_SELECTION_NUMBER'
    static MUTATE_ACCESSOIRE_SELECTION_NUMBER = 'AppAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER'

    static SET_OPENED_LOCATIONS = 'AppAction.SET_OPENED_LOCATIONS'

    static SET_LOCATION_AVAILABILITY = 'AppAction.SET_LOCATION_AVAILABILITY'
    static SET_BICYCLE_AVAILABILITY = 'AppAction.SET_BICYCLE_AVAILABILITY'
    static SET_ACCESSOIRE_AVAILABILITY = 'AppAction.SET_ACCESSOIRE_AVAILABILITY'

    // Delivery
    static SET_DELIVERY_LOCATION = 'AppAction.SET_DELIVERY_LOCATION'
    static SET_SUB_DELIVERY_LOCATION = 'AppAction.SET_SUB_DELIVERY_LOCATION'
    static SET_SUB_LOCATION_NUMBER = 'AppAction.SET_SUB_LOCATION_NUMBER'

    static SET_CUSTOM_LOCATION_VALUE = 'AppAction.SET_CUSTOM_LOCATION_VALUE'

    static SET_DELIVERY_TYPE = 'AppAction.SET_DELIVERY_TYPE'
    static SET_PARTNER_LOCATION = 'AppAction.SET_PARTNER_LOCATION'

    static SET_FORM_DATA = 'AppAction.SET_FORM_DATA'

    static CHANGE_LOCATION_POPUP = 'AppAction.CHANGE_LOCATION_POPUP'
    static CHANGE_BICYCLE_POPUP = 'AppAction.CHANGE_BICYCLE_POPUP'
    static CHANGE_ACCESSOIRE_POPUP = 'AppAction.CHANGE_ACCESSOIRE_POPUP'
    static CHANGE_ACCESSOIRE_CONDITION_POPUP = 'AppAction.CHANGE_ACCESSOIRE_CONDITION_POPUP'
    static CHANGE_INSURANCE_POPUP = 'AppAction.CHANGE_INSURANCE_POPUP'

    static VALIDATE_SHOPPING_CART = 'AppAction.VALIDATE_SHOPPING_CART'

    static SET_AND_VALIDATION_COMPLETE = 'AppAction.SET_AND_VALIDATION_COMPLETE'

    static SET_DEFAULT_PERIOD = 'AppAction.SET_DEFAULT_PERIOD'

    static SUBMIT_REGISTER = 'AppAction.SUBMIT_REGISTER'
    static CHECK_PERIOD_DISCOUNT = 'AppAction.CHECK_PERIOD_DISCOUNT'

    static RESET = 'AppAction.RESET'

    static switchStep(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SWITCH_WIZARD_STEP, AppEffect.switchWizardStep, values);
        };
    }

    static switchAppStep(step) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SWITCH_STEP, AppEffect.switchAppStep, step);
        };
    }

    static setSlug(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_SLUG, AppEffect.setSlug, values);
        };
    }

    static setPeriod(values) {

        return async (dispatch, getState) => {

            if (getState().app.webshop.id === 132) {
                if (getState().app.period.endDate !== values.endDate) {
                    values.startDate = values.endDate
                } else {
                    values.endDate = values.startDate
                }
            }

            const period = {
                days: 1,
                key: 'selection',
                startDate: new Date(values.start_date.replace(/-/g, "/") + ' ' + values.start_time),
                endDate: new Date(values.end_date.replace(/-/g, "/") + ' ' + values.end_time),
                rentalNotation: getState().info.infoData.detail.webshops.rental_notation
            }

            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_PERIOD, AppEffect.setPeriod, period);
        };
    }

    static setPeriodOld(values) {

        return async (dispatch, getState) => {


            if (getState().app.webshop.id === 132) {
                if (getState().app.period.endDate !== values.endDate) {
                    values.startDate = values.endDate
                } else {
                    values.endDate = values.startDate
                }
            }

            values.rentalNotation = getState().info.infoData.detail.webshops.rental_notation
            values.dispatch = dispatch

            if (values.startDate && values.endDate) {

                if (values.startDate && values.endDate) {
                    values.days = CalculateDaysBetweenOld(values.startDate, values.endDate)
                    dispatch(AppAction.setTimeAmount(values.days))
                }

            }


            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_PERIOD, AppEffect.setPeriodOld, values);
        };
    }

    static setTime(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_TIME, AppEffect.setTime, values);
        };
    }

    static setStartTime(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_START_TIME, AppEffect.setStartTime, values);
        };
    }

    static setEndTime(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_END_TIME, AppEffect.setEndTime, values);
        };
    }

    static setTimeUnitId(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_TIME_UNIT_ID, AppEffect.setTimeUnitId, values);
        };
    }

    static setTimeUnit(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_TIME_UNIT, AppEffect.setTimeUnit, values)
        }
    }

    static setTimeAmount(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_TIME_AMOUNT, AppEffect.setTimeAmount, values)
        }
    }

    static setSummary(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_SUMMARY, AppEffect.setSummary, values);
        };
    }

    static setDefaultLanguage() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_DEFAULT_LANGUAGE, AppEffect.setDefaultLanguage);
        };
    }

    static changeLanguage(language) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_LANGUAGE, AppEffect.changeLanguage, language);
        };
    }

    static formatWebshop() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_WEBSHOP, AppEffect.formatWebshop, getState());
        };
    }

    static setRentalId() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_RENTAL_ID, AppEffect.setRentalId, getState().rental);
        };
    }

    static formatLocations() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_LOCATIONS, AppEffect.formatLocations, getState());
        };
    }

    static formatBicycles() {

        return async (dispatch, getState) => {

            const data = {}
            data.values = getState()
            data.type = 'bicycles'

            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_BICYCLES, AppEffect.formatItems, data);
        };
    }

    static formatAccessoires() {
        return async (dispatch, getState) => {

            const data = {}
            data.values = getState()
            data.type = 'accessoires'

            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_ACCESSOIRES, AppEffect.formatItems, data);
        };
    }

    static formatDeliveryLocations() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_DELIVERY_LOCATIONS, AppEffect.formatDeliveryLocations, getState());
        };
    }

    static formatSubDeliveryLocations(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.FORMAT_SUB_DELIVERY_LOCATIONS, AppEffect.formatSubDeliveryLocations, values);
        };
    }

    static mutateBicycleSelectionNumber(values) {
        return async (dispatch, getState) => {
            values.locations = getState().app.locations
            await ActionUtility.createThunkEffect(dispatch, AppAction.MUTATE_BICYCLE_SELECTION_NUMBER, AppEffect.mutateSelectionNumber, values);
        };
    }

    static mutateAccessoireSelectionNumber(values) {
        return async (dispatch, getState) => {
            values.locations = getState().app.locations
            await ActionUtility.createThunkEffect(dispatch, AppAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER, AppEffect.mutateSelectionNumber, values);
        };
    }

    static setOpenedLocations() {
        return (dispatch, getState) => {
            const openedLocationIds = []
            const state = getState();

            Object.values(state.app.locations).forEach((location) => {

                const {opening_times} = location
                const selectedStartDateMoment = moment(state.app.period.startDate)
                const selectedEndDateMoment = moment(state.app.period.endDate)

                const selectedDayName = FormatDayNumberToName(selectedStartDateMoment.isoWeekday())
                const selectedDayNameEnd = FormatDayNumberToName(selectedEndDateMoment.isoWeekday())

                opening_times.forEach((openingTime) => {
                    let openingCheck = true

                    const openingTimeStartDateMoment = moment(openingTime.date_from).startOf('day');
                    const openingTimeEndDateMoment = moment(openingTime.date_to).endOf('day');

                    const momentRentalStartTime = moment(state.app.startTime, 'HH:mm')
                    const momentRentalEndTime = moment(state.app.endTime, 'HH:mm')

                    const momentStartTime = moment(openingTime.opening_days[selectedDayName]?.open_from, 'HH:mm')
                    const momentEndTime = moment(openingTime.opening_days[selectedDayName]?.open_to, 'HH:mm')

                    const momentStartTimeEnd = moment(openingTime.opening_days[selectedDayNameEnd]?.open_from, 'HH:mm')
                    const momentEndTimeEnd = moment(openingTime.opening_days[selectedDayNameEnd]?.open_to, 'HH:mm')

                    const startTime = moment().set({
                        hour: momentStartTime.get('hour'),
                        minute: momentStartTime.get('minute')
                    })

                    const endTime = moment().set({
                        hour: momentEndTime.get('hour'),
                        minute: momentEndTime.get('minute')
                    })

                    const startTimeEnd = moment().set({
                        hour: momentStartTimeEnd.get('hour'),
                        minute: momentStartTimeEnd.get('minute')
                    })

                    const endTimeEnd = moment().set({
                        hour: momentEndTimeEnd.get('hour'),
                        minute: momentEndTimeEnd.get('minute')
                    })

                    if (!!state.app.timeUnit && !!state.app.timeUnit.time_required) {
                        openingCheck = momentRentalStartTime.isBetween(startTime, endTime, 'minutes', '[]') && momentRentalEndTime.isBetween(startTimeEnd, endTimeEnd, 'minutes', '[]')
                    }

                    if (selectedStartDateMoment.isBetween(openingTimeStartDateMoment, openingTimeEndDateMoment, 'days', '[]') && !!openingTime.opening_days[selectedDayName]?.is_open && openingCheck) {
                        openedLocationIds.push(location.id)
                    }
                })

            })

            ActionUtility.createThunkEffect(dispatch, AppAction.SET_OPENED_LOCATIONS, AppEffect.setOpenedLocations, openedLocationIds);

        }
    }

    static setLocationAvailability() {
        return async (dispatch, getState) => {
            let excludedLocations = getState().app.excludedLocations
            const state = getState();
            const excludedIds = []

            Object.values(state.app.locations).forEach((location) => {
                if (!state.app.openedLocations.find((openedLocationId) => openedLocationId === location.id)) {
                    excludedIds.push(location.id)
                }
            })

            // Removing filtered locations
            excludedLocations = {
                ...excludedLocations,
                823547872810853267123: excludedIds
            }

            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_LOCATION_AVAILABILITY, AppEffect.setLocationAvailability, excludedLocations);
        };
    }


    static setBicycleAvailability() {
        return async (dispatch, getState) => {
            const values = {}

            values.items = getState().app.bicycles
            values.locations = getState().app.locations

            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_BICYCLE_AVAILABILITY, AppEffect.setItemAvailability, values);
        };
    }

    static setAccessoireAvailability() {
        return async (dispatch, getState) => {
            const values = {}

            values.items = getState().app.accessoires
            values.locations = getState().app.locations

            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_ACCESSOIRE_AVAILABILITY, AppEffect.setItemAvailability, values);
        };
    }

    static setFormData(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_FORM_DATA, AppEffect.setFormData, values);
        };
    }

    static setDeliveryLocation(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_DELIVERY_LOCATION, AppEffect.setDeliveryLocation, values);
        };
    }

    static setSubDeliveryLocation(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_SUB_DELIVERY_LOCATION, AppEffect.setSubDeliveryLocation, values);
        };
    }

    static setCustomLocationValue(values) {

        return async (dispatch, getState) => {


            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_CUSTOM_LOCATION_VALUE, AppEffect.setCustomLocationValue, values);
        };
    }

    static setSubLocationNumber(number) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_SUB_LOCATION_NUMBER, AppEffect.setSubLocationNumber, number);
        };
    }

    static setDeliveryType(type) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_DELIVERY_TYPE, AppEffect.setDeliveryType, type);
        };
    }

    static setPartnerLocation(partnerLocationId) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_PARTNER_LOCATION, AppEffect.setPartnerLocation, partnerLocationId);
        };
    }

    static changeLocationPopup(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_LOCATION_POPUP, AppEffect.changeLocationPopup, values);
        };
    }

    static changeBicyclePopup(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_BICYCLE_POPUP, AppEffect.changeItemPopup, values);
        };
    }

    static changeAccessoirePopup(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_ACCESSOIRE_POPUP, AppEffect.changeItemPopup, values);
        };
    }

    static changeInsurancePopup(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_INSURANCE_POPUP, AppEffect.changeItemPopup, values);
        };
    }

    static changeAccessoireConditionPopup(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_ACCESSOIRE_CONDITION_POPUP, AppEffect.changeItemPopup, values);
        };
    }

    static validateShoppingCart() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.VALIDATE_SHOPPING_CART, AppEffect.validateShoppingCart, getState());
        };
    }


    static setAndValidationComplete(complete) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_AND_VALIDATION_COMPLETE, AppEffect.setAndValidationComplete, complete);
        };
    }

    static setDefaultPeriod() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_DEFAULT_PERIOD, AppEffect.setDefaultPeriod, getState());
        };
    }

    static submitRegister() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SUBMIT_REGISTER, AppEffect.submitRegister, getState());
        };
    }

    static reset(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.RESET, AppEffect.reset, values);
        };
    }

    static checkPeriodDiscount() {
        return async (dispatch, getState) => {

            const state = getState()
            const {app} = state

            const startDateMoment = moment(app.period.startDate)
            const periodEndDateMomentTexelDuinen = moment('12-23-2022')
            const periodEndDateMomentTexelVakanties = moment('12-22-2022')

            const texelDuinenCheck = app.webshop?.id === 26 && startDateMoment.isSameOrBefore(periodEndDateMomentTexelDuinen)
            const texelVakantiesCheck = app.webshop?.id === 37 && (startDateMoment.isSameOrBefore(periodEndDateMomentTexelVakanties) || (startDateMoment.isSameOrAfter(moment('01-09-2023')) && startDateMoment.isSameOrBefore(moment('02-21-2023'))))


            if (texelDuinenCheck || texelVakantiesCheck) {
                await ActionUtility.createThunkEffect(dispatch, AppAction.CHECK_PERIOD_DISCOUNT, AppEffect.checkPeriodDiscount, 10);
            } else {
                await ActionUtility.createThunkEffect(dispatch, AppAction.CHECK_PERIOD_DISCOUNT, AppEffect.checkPeriodDiscount, 0);
            }


        };
    }

}
