import ApiEnum from '../../../constants/ApiEnum'


import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";

export default class AvailabilityEffect {

    static async requestPerCategory(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.post(ApiEnum.ApiWebshopAvailability + '/per-category' + getParameters, values, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                items: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }

    static async requestPerDay(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.post(ApiEnum.ApiWebshopAvailability + '/per-day' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {
                return {
                    items: response.data.items
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    static async requestPerDay(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.post(ApiEnum.ApiWebshopAvailability + '/per-day' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {
                return {
                    items: response.data.items
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    static async validateDeliveryByAddress(values) {

        return await AppAxios.post(ApiEnum.ApiWebshopAvailability + '/validate-delivery-by-address?deliveryAddressId=' + values.deliveryAddressId + '&startDate=' + values.startDate + '&endDate=' + values.endDate, {
            categories: values.categories
        }, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        })
            .then((response) => {
                return {
                    items: !!response.data.items?.available
                }
            }).catch((error) => {
                console.log(error)
            })
    }


}

