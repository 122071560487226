const DisplayDecimal = (number) => {

    let float = Number.parseFloat(number);

    if(Number.isNaN(float)) {
        return number;
    }

    return String(float.toFixed(2)).replace('.', ",");
}

export default DisplayDecimal