import AppAction from "../../stores/app/AppAction";
import ShoppingCartAction from "../../stores/shoppingCart/ShoppingCartAction";

const ChangeAccessoireNumber = (data, direction, dispatch) => {

    const values = {
        selected: data.selected + direction,
        id: data.id,
        itemLocations: data.locations,
    }

    if (data.maxConditionSelection && (data.selected + direction > data.maxConditionSelection)) {
        dispatch(AppAction.changeAccessoireConditionPopup({
            show: true,
            id: data.id
        }))
    } else {

        dispatch(AppAction.mutateAccessoireSelectionNumber(values)).then(() => {

            dispatch(AppAction.setLocationAvailability()).then(() => {

                dispatch(AppAction.setAccessoireAvailability())

                dispatch(ShoppingCartAction.update())
            })
        })
    }
}

export default ChangeAccessoireNumber