import React from 'react'
import AppAction from "../../stores/app/AppAction";
import {connect} from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Redirect} from "react-router";
import RentalAction from "../../stores/api/rental/RentalAction";
import Loading from "../_components/Loading";
import Translate from "../../utilities/etc/Translate";
import PaymentAction from "../../stores/api/payment/PaymentAction";

const mapStateToProps = (state) => ({
    ...state.auth,
    ...state.app,
    ...state.rental,
    ...state.payment
});

const Pending = (props) => {

    return (
        <div className={'c-loader c-loader-full'} key={'bicycle-loader'}>

            <div className="content">
                {!!props.webshop.deferred_payment_enabled
                    ? <h3 style={{
                        textAlign: 'center',
                        minWidth: '100%'
                    }}>{Translate('Reservering in verwerking', props.language) + '...'}</h3>
                    : <h3 style={{
                        textAlign: 'center',
                        minWidth: '100%'
                    }}>{Translate('Betaling in verwerking', props.language) + '...'}</h3>

                }

                <Loading/>

            </div>

        </div>
    )

}

class Processing extends React.Component {

    componentDidMount() {

        let params = this.props.match.params

        this.props.dispatch(PaymentAction.getPaymentMethods(params.rentaluuid))
        this.props.dispatch(AppAction.switchAppStep(7))
        this.props.dispatch(RentalAction.detail({
            uuid: params.rentaluuid
        }))

        this.timeout();
    }

    timeout() {
        const intervalFunc = () => {

            let params = this.props.match.params
            const queryString = window.location.search;

            const urlParams = new URLSearchParams(queryString);

            this.props.dispatch(PaymentAction.transactionStatus({
                uuid: params.rentaluuid,
                externalStatusId: urlParams.get('orderStatusId'),
                paymentProviderCode: this.props.paymentMethods?.provider
            }))
        }

        setTimeout(intervalFunc, 1000); // Here
    }


    render() {
        const status = this.props.paymentData.transactionStatus
        let transaction = false

        if (status) {
            transaction = status.transactions[status.transactions.length - 1]
        }


        if (!transaction && !this.props.webshop.deferred_payment_enabled) {

            return <Pending {...this.props} />

        } else {

            switch (transaction.status) {
                case("completed"):
                    return <Redirect to={this.props.slug + '/bedankt'}/>
                case("pending"):
                    if (!!this.props.webshop.deferred_payment_enabled || !!this.props.webshop.recurring_enabled) {
                        return <Redirect to={this.props.slug + '/bedankt'}/>
                    }
                    return <Pending {...this.props} />
                case("bank_transfer"):
                    return <Redirect to={this.props.slug + '/overboeking'}/>
                case("cancelled"):
                    return <Redirect to={this.props.slug + '/geannuleerd'}/>
                case("failed"):
                    return <Redirect to={this.props.slug + '/mislukt'}/>
                default:
                    return <Pending {...this.props} />
            }
        }


    }

}

export default connect(mapStateToProps)(Processing);
