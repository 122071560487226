import React from 'react'
import BicycleItemMany from "./_components/BicycleItemMany";
import StickyBar from "./_components/StickyBar";
import {connect} from "react-redux";
import AppAction from "../../stores/app/AppAction";
import SetAndValidateItems from "../../utilities/dispatchers/SetAndValidateItems";
import Loader from 'react-loader-spinner'

import {CSSTransition} from 'react-transition-group';
import Translate from "../../utilities/etc/Translate";
import CalculateMapBounds from "../../utilities/etc/CalculateMapBounds";
import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import Loading from "../_components/Loading";

const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiaWRvdHdlYmVuZ2luZWVycyIsImEiOiJja2Zucnp2Z2Yxbm93MzBxaDNrNDh1cGZ0In0.sUN7_6eNtALA2jGJ6PgpBQ'
});

const mapStateToProps = (state) => ({
    ...state.app,
    ...state.generalApi,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation

});

class BicyclesMany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            sideBarToggle: false
        }
    }

    componentDidMount() {

        this.props.scroll()

        this.props.dispatch(AppAction.setLocationAvailability())

        if (this.props.period.endDate) {

            this.props.dispatch(AppAction.switchStep(1)).then(() => {
                this.props.dispatch(AppAction.setSummary({
                    period: true,
                    bicycles: false,
                    accessoires: false,
                    accommodation: false,
                    delivery: false,
                })).then(() => {
                    SetAndValidateItems(this.props, 'bicycle')
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this._showBicycles() && Object.keys(this.props.bicycles).length !== 0 && this.state.loaded === false) {

            this.props.dispatch(AppAction.setLocationAvailability())

            this.setState({
                loaded: true
            })

        }
    }


    render() {

        if (this._showBicycles()) {

            const locations = Object.values(this.props.locations)

            let sideBarClass = 'c-products-alternative__aside'

            if (this.state.sideBarToggle) {
                sideBarClass += ' isActive'
            }

            return (
                <div className="o-container o-container--tertiary">
                    <CSSTransition
                        in={this.state.loaded}
                        timeout={500}
                        classNames="fade"
                    >
                        <div className={'c-products c-products-alternative'}>

                            <button className={'c-products-alternative__avail-btn'}
                                    style={this.props.webshop.styles.dark} onClick={this._toggleSidebar}><span>Bekijk beschikbaarheid</span>
                            </button>

                            <div className={'c-products-alternative__wrap'}>
                                <div className={'c-products-alternative__products'}>


                                    {Object.values(this.props.bicycles)
                                        .filter(item => {
                                            return !item.inactive
                                        })
                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                        .map((bicycleData) => {
                                            return (
                                                <BicycleItemMany
                                                    key={bicycleData.id + '-bicycle-key'}
                                                    id={bicycleData.id}
                                                    data={bicycleData}
                                                />
                                            )
                                        })}

                                    {Object.values(this.props.bicycles)
                                        .filter(item => {
                                            return item.inactive
                                        })
                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                        .map((bicycleData) => {
                                            return (
                                                <BicycleItemMany
                                                    key={bicycleData.id + '-bicycle-key'}
                                                    id={bicycleData.id}
                                                    data={bicycleData}
                                                />
                                            )
                                        })}


                                </div>

                                {this.props.locations &&
                                <aside className={sideBarClass}>
                                    {!!locations &&
                                    <div className={'c-products-alternative__aside__map'}>

                                        <Map
                                            // eslint-disable-next-line
                                            style="mapbox://styles/idotwebengineers/ckfnsaisf3jtt19l20ndd0bmh"
                                            fitBounds={CalculateMapBounds(locations)}
                                            containerStyle={{
                                                height: '25rem',
                                                minHeight: '25rem',
                                                borderTopLeftRadius: '1.5rem',
                                                borderTopRightRadius: '1.5rem',
                                            }}
                                        >

                                            {Object.keys(this.props.locations).map((locationId, index) => {
                                                const location = this.props.locations[locationId]
                                                location.bicycleData = this.props.locations[locationId]
                                                if (!location.excluded && location.bicycleData.available !== 0) {

                                                    return (
                                                        <Marker coordinates={[location.long, location.lat]}
                                                                anchor="bottom"
                                                                key={'marker-' + index}>
                                                            <div
                                                                className={'c-products-alternative__aside__map__number'}>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                        </Marker>
                                                    )
                                                }
                                            })}


                                        </Map>


                                        <button className={'c-products-alternative__aside__close'}
                                                onClick={this._toggleSidebar}>
                                            <i className={'icon icon-cross'}/>
                                        </button>
                                    </div>
                                    }

                                    <div className={'c-products-alternative__aside__title'}>
                                        <span>{Translate('Beschikbaarheid', this.props.language)}</span>
                                    </div>
                                    <div className={'c-products-alternative__aside__list'}>

                                        {Object.keys(this.props.locations).map((locationId, index) => {
                                            const location = this.props.locations[locationId]
                                            location.bicycleData = this.props.locations[locationId]

                                            if (!location.excluded && location.bicycleData.available !== 0) {
                                                return (

                                                    <div className={'c-products-alternative__aside__list__single'}>
                                                        <div
                                                            className={'c-products-alternative__aside__list__single__wrap'}>
                                                            <div
                                                                className={'c-products-alternative__aside__map__number'}>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                            <small>{location.name}</small>
                                                        </div>
                                                        <address>
                                                            {location.address} {location.number}, {location.zipCode} {Object.values(this.props.cityData.list).filter((city) => city.id === location.city_id).map((city) => {
                                                            return (city.name)
                                                        })}
                                                        </address>
                                                    </div>

                                                )
                                            }

                                        })}

                                    </div>
                                </aside>
                                }
                            </div>
                            <StickyBar/>
                        </div>

                    </CSSTransition>
                </div>
            )
        } else {

            return (
                <div className={'c-loader'} key={'bicycle-loader'}>
                    <Loading />
                </div>
            )
        }
    }

    _showBicycles = () => {
        return this.props.setAndValidationComplete && this.props.bicycles !== {}
    }


    _toggleSidebar = () => {
        this.setState({
            sideBarToggle: !this.state.sideBarToggle
        })
    }


}


export default connect(mapStateToProps)(BicyclesMany);
