import PartnerAction from './PartnerAction'

export default class PartnerReducer {

    static initialState = {
        partnerErrors: {
            detail: false
        },
        partnerMessages: {
            detail: false
        },
        partnerPending: {
            detail: false
        },
        partnerData: {
            detail: false
        }
    };

    static reducer(state = PartnerReducer.initialState, action) {
        switch (action.type) {

            case PartnerAction.REQUEST_DETAIL:
                return {
                    ...state,
                    partnerErrors: {
                        ...state.partnerErrors,
                        detail: false
                    },
                    partnerMessages: {
                        ...state.partnerMessages,
                        detail: false
                    },
                    partnerPending: {
                        ...state.partnerPending,
                        detail: true
                    },
                    partnerData: {
                        ...state.partnerData,
                        detail: false
                    },
                };
            case PartnerAction.REQUEST_DETAIL + '_FINISHED':
                return {
                    ...state,
                    partnerErrors: {
                        ...state.partnerErrors,
                        detail: action.payload.error
                    },
                    partnerMessages: {
                        ...state.partnerMessages,
                        detail: action.payload.message
                    },
                    partnerPending: {
                        ...state.partnerPending,
                        detail: false
                    },
                    partnerData: {
                        ...state.partnerData,
                        detail: action.payload.response
                    },
                };

            default:
                return state;
        }
    }
}