import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../../../stores/app/AppAction";
import PartnerDeliveryLocationIsAvailable from "../../../../../utilities/etc/PartnerDeliveryLocationIsAvailable";
import Translate from "../../../../../utilities/etc/Translate";
import {getAvailableOpeningTimes} from "../../../../../utilities/etc/getAvailableOpeningTimes";


const mapStateToProps = (state, ownProps) => ({
    ...state.app
});

class DeliverySelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hideLocationSelector: false
        }
    }

    componentDidMount() {
        const locations = Object.values(this.props.locations)

        const filteredLocations = locations.filter(location => this._filterLocations(location))

        if (this.props.deliveryComponent) {
            this.setState({
                hideLocationSelector: true
            })
        }

        const deliveryPartnerId = PartnerDeliveryLocationIsAvailable(this.props.webshop.delivery_partner_location_id, filteredLocations);

        if (this.props.deliveryComponent && deliveryPartnerId) {

            this.props.dispatch(AppAction.setPartnerLocation(deliveryPartnerId))

        } else if (filteredLocations.length === 1) {

            this.props.dispatch(AppAction.setPartnerLocation(filteredLocations[0].id))
        }
        // MV 2023-06-02 Removed for https://unframedagency.atlassian.net/jira/servicedesk/projects/USD/queues/custom/100/USD-401 ,
        // not all residential locations have Lat/Long so to determine which is closest is not feasible for the short term
        //
        // else if (filteredLocations.length > 1) {
        //     this.props.dispatch(AppAction.setPartnerLocation(filteredLocations[0].id))
        // }

    }

    render() {

        let locations = Object.values(this.props.locations)

        if (this.props.webshop.type.code === 'registration') {
            locations = [Object.values(this.props.locations)[0]]
        }

        const filteredLocations = locations.filter(location => this._filterLocations(location))

        if (!this.state.hideLocationSelector) {

            return (

                <div className="c-delivery-options__store__options">

                    <small>{Translate('Selecteer a.u.b. een vestiging', this.props.language)}</small>

                    {filteredLocations.map((location, index) => {

                        let className = 'c-delivery-options__store__options__single'
                        const openTimeString = this._formatOpenStrings(location, this.props.period.startDate)

                        if (this.props.delivery.partnerLocationId === location.id) {
                            className = className + ' c-delivery-options__store__options__single--selected';
                        }


                        return (
                            <div key={'location-' + location.id} onClick={() => this._onClickLocation(location.id)}
                                 className={className}>
                                <div className="c-delivery-options__check">
                                    {this.props.delivery.partnerLocationId === location.id &&
                                    <figure/>
                                    }
                                </div>
                                <div className="c-delivery-options__store__options__single__number">
                                    <span>{index + 1}</span>
                                </div>
                                <div className="c-delivery-options__store__options__single__text">
                                    <span>{location.name}</span>
                                    <span>{location.address} {location.number}</span>
                                    <span><i>{openTimeString}</i></span>
                                </div>
                            </div>
                        )


                    })}

                </div>
            )

        } else {

            return null

        }
    }

    _onClickLocation(partnerLocationId) {
        this.props.dispatch(AppAction.setPartnerLocation(partnerLocationId))
    }

    _formatOpenStrings(location, startDate) {
        const {opening_times} = location

        const availableOpeningTimes = getAvailableOpeningTimes(opening_times, startDate)
        let dayOfWeek = new Date(this.props.period.startDate).getDay();

        if (dayOfWeek === 0) {
            dayOfWeek = 7
        }
        
        let openedTimeString = ''
        let openingTimeStartDate = null;

        if (availableOpeningTimes[0]?.opening_days){
            openingTimeStartDate = Object.values(availableOpeningTimes[0]?.opening_days)[dayOfWeek - 1]
        }

        if (openingTimeStartDate && openingTimeStartDate.is_open) {

            if (openingTimeStartDate.open_from && openingTimeStartDate.open_to) {

                openedTimeString = Translate('Geopend van', this.props.language) + ' ' + openingTimeStartDate.open_from + ' ' + Translate('t/m', this.props.language) + ' ' + openingTimeStartDate.open_to

                if (openingTimeStartDate.block_time && openingTimeStartDate.block_from && openingTimeStartDate.block_to) {
                    openedTimeString += ' & ' + openingTimeStartDate.block_from + ' ' + Translate('t/m', this.props.language) + ' ' + openingTimeStartDate.block_to
                }

            } else return false;

        } else {

            openedTimeString = Translate('Gesloten op geselecteerde startdatum', this.props.language)

        }


        return openedTimeString

    }

    _filterLocations(location) {
        return !!this.props.openedLocations.find((locationId) => locationId === location.id) && !location.excluded
    }

}


export default connect(mapStateToProps)(DeliverySelector);
