import AvailabilityAction from './AvailabilityAction'

export default class AvailabilityReducer {

    static initialState = {
        availabilityErrors: {
            perCategory: false,
            perDay: false
        },
        availabilityMessages: {
            perCategory: false,
            perDay: false
        },
        availabilityPending: {
            perCategory: false,
            perDay: false
        },
        availabilityData: {
            perCategory: false,
            perDay: false
        },
        deliveryByAddressValidation: {
            valid: false,
            validating: false
        }
    };

    static reducer(state = AvailabilityReducer.initialState, action) {
        switch (action.type) {

            /** REQUEST PER CATEGORY **/

            case AvailabilityAction.REQUEST_PER_CATEGORY:
                if (action.payload) {
                    return {
                        ...state,
                        availabilityErrors: {
                            ...state.availabilityErrors,
                            perCategory: false
                        },
                        availabilityMessages: {
                            ...state.availabilityMessages,
                            perCategory: false
                        },
                        availabilityPending: {
                            ...state.availabilityPending,
                            perCategory: true
                        },
                        availabilityData: {
                            ...state.availabilityData,
                            perCategory: false
                        },

                    }

                } else return state
            case AvailabilityAction.REQUEST_PER_CATEGORY + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        availabilityErrors: {
                            ...state.availabilityErrors,
                        },
                        availabilityPending: {
                            ...state.availabilityPending,
                            perCategory: false
                        },
                        availabilityData: {
                            ...state.availabilityData,
                            perCategory: action.payload.items
                        },
                    };
                } else return state

            /** REQUEST PER DAY **/

            case AvailabilityAction.REQUEST_PER_DAY:
                return {
                    ...state,
                    availabilityErrors: {
                        ...state.availabilityErrors,
                        perDay: false
                    },
                    availabilityMessages: {
                        ...state.availabilityMessages,
                        perDay: false
                    },
                    availabilityPending: {
                        ...state.availabilityPending,
                        perDay: true
                    },
                    availabilityData: {
                        ...state.availabilityData,
                        perDay: false
                    },
                };
            case AvailabilityAction.REQUEST_PER_DAY + '_FINISHED':
                return {
                    ...state,
                    availabilityErrors: {
                        ...state.availabilityErrors,
                    },
                    availabilityPending: {
                        ...state.availabilityPending,
                        perDay: false
                    },
                    availabilityData: {
                        ...state.availabilityData,
                        perDay: action.payload.items
                    },
                };

            case AvailabilityAction.VALIDATE_DELIVERY_BY_ADDRESS:
                return {
                    ...state,
                    deliveryByAddressValidation: {
                        validating: true,
                        valid: false
                    }
                };
            case AvailabilityAction.VALIDATE_DELIVERY_BY_ADDRESS + '_FINISHED':
                return {
                    ...state,
                    deliveryByAddressValidation: {
                        validating: false,
                        valid: action.payload.items
                    }

                };

            default:
                return state;
        }
    }
}
