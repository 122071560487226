const DisplayItemName = (item) => {

    if (item) {
        if (item.alternative_label) {
            return item.alternative_label
        } else {
            return item.name
        }
    } else return ''
}

export default DisplayItemName