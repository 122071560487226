import ApiEnum from '../../../constants/ApiEnum'

import MapValuesAsGetParameters from "../../../utilities/formatters/MapValuesAsGetParameters";
import AppAxios from "../../../utilities/etc/AppAxios";

export default class PartnerEffect {

    static async requestDetail(values) {

        const getParameters = MapValuesAsGetParameters(values);

        return await AppAxios.get(ApiEnum.ApiWebshopPartner + '/detail' + getParameters, {
            headers: {
                'Authorization': `Bearer ` + localStorage.getItem('accessToken')
            }
        }).then((response) => {

            return {
                response: response.data.items
            }

        }).catch((error) => {
            console.log(error)
        })

    }

}

