import ActionUtility from '../../utilities/etc/ActionUtility'
import ShoppingCartEffect from "./ShoppingCartEffect";

export default class ShoppingCartAction {
    static UPDATE = 'ShoppingCartAction.UPDATE';
    static EMPTY = 'ShoppingCartAction.EMPTY';

    static update() {
        return async (dispatch, getState) => {
            const values  = {}
            values.bicycles = getState().app.bicycles
            values.accessoires = getState().app.accessoires

            await ActionUtility.createThunkEffect(dispatch, ShoppingCartAction.UPDATE, ShoppingCartEffect.update, values);
        };
    }

    static empty(type = false){
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ShoppingCartAction.EMPTY, ShoppingCartEffect.empty, type);
        };
    }
}
