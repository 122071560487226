import React from 'react'
import {connect} from "react-redux";
import moment from 'moment'
import RouteEnum from "../../../constants/RouteEnum";
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";
import {useAppSelector} from "../../../stores/hooks";

const mapStateToProps = (state) => ({
    ...state.app,
    rentalNotation: state.info.infoData.detail.webshops?.rental_notation,
    recurringEnabled: !!state.info.infoData.detail.webshops?.webshop.recurring_enabled ?? false,
});

const changePeriod = (props) => {
    props.dispatch(AppAction.switchAppStep(RouteEnum.Period)).then(() => {
        props.dispatch(AppAction.setSummary({
            period: false,
            bicycles: false,
            accessoires: false,
            accommodation: false,
            delivery: false,
        }))
    })
}

const SummaryPeriod = (props) => {
    const periodRange = props.period
    const {infoData} = useAppSelector(state => state.info)
    const shopIsTypeRecurring = !!infoData.detail.webshops?.webshop?.recurring_enabled ?? false

    if (periodRange.startDate) {
        return (
            <div className="c-summary c-summary--period">
                <i className="c-summary__check icon icon-check-circle"/>

                {!shopIsTypeRecurring &&
                    <div className="c-summary--period__text">
                    <span>{moment(periodRange.startDate).format('dddd D MMMM')}
                        {!!props.timeUnit.time_required &&
                            <>
                                <span style={{marginLeft: '.5rem', marginRight: '.5rem'}}>{props.startTime}</span>
                            </>
                        }
                        {periodRange.endDate && periodRange.endDate !== periodRange.startDate &&
                            <> <span style={{marginRight: '.5rem'}}>{Translate('t/m', props.language)}</span>
                                {moment(periodRange.endDate).format('dddd D MMMM')}
                                {!!props.timeUnit.time_required &&
                                    <>
                                        <span style={{marginLeft: '.5rem', marginRight: '.5rem'}}>{props.endTime}</span>
                                    </>
                                }
                            </>
                        }

                    </span>
                        {periodRange.days &&
                            <div className="btn btn--pill" style={props.webshop.styles.dark}>
                                <span> {periodRange.days + ' ' + Translate('dag(en)', props.language)}</span>
                            </div>
                        }
                    </div>
                }

                {shopIsTypeRecurring &&
                    <div className="c-summary--period__text">
                        <span>{Translate('Vanaf', props.language)}</span>
                        <span style={{marginLeft: '.5rem'}}>{moment(periodRange.startDate).format('dddd D MMMM')}</span>
                    </div>
                }

                <button onClick={() => changePeriod(props)}
                        className="c-summary__edit"><span>{Translate('Wijzigen', props.language)}</span><i
                    className="icon icon-edit"/></button>
            </div>
        )

    } else {
        return null
    }

}

export default connect(mapStateToProps)(SummaryPeriod);
